import { WorkBook } from "xlsx";
import Product from "../../classes/product";

import parseNQMFPrime from "./nqmfPrime/parseNQMFPrime";
import parseNQMFDSCR from "./nqmfDSCR/parseNQMFDSCR";
import parseNQMF2ndLien from "./nqmfStandAlone2ndLien/parseNQMF2ndLien";
import parseNQMFDSCRMixedUse from "./nqmfDSCRMulti/NQMFDSCR_Mixed_Use/parseNQMFDSCR_MixedUse";
import parseNQMFDSCR_5_8Units from "./nqmfDSCRMulti/NQMFDSCR_5_10_Units/parseNQMFDSCR_5_10_Units";


export default function parseNQMF(workbook: WorkBook): Product[] {
    const primeSheet = workbook.Sheets["Flex Select Prime"];
    const DSCRSheet = workbook.Sheets["Flex Select Prime DSCR_MU"];
    const DSCR_5_8_UnitsSheet = workbook.Sheets["Flex Select Prime DSCR_MU"];
    const DSCRMixedUseSheet = workbook.Sheets["Flex Select Prime DSCR_MU"];
    const secondLienSheet = workbook.Sheets["Flex Select Prime 2nd Liens"];



    return [
        ...parseNQMFPrime(primeSheet),
        ...parseNQMFDSCR(DSCRSheet),
        ...parseNQMFDSCR_5_8Units(DSCRMixedUseSheet),
        ...parseNQMFDSCRMixedUse(DSCR_5_8_UnitsSheet),
        ...parseNQMF2ndLien(secondLienSheet),
    ]
}