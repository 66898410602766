import { Amortization, BankruptcyType, Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Documentation Type Allowed = Full Doc, 12M Full Doc, 12 or 24 Psnal or Business Bank Stmts, 12 or 24 1099 Income,  12M P&L
    {
        name: "Documentation Type Allowed = Full Doc, 12M Full Doc, 12 or 24 Psnal or Business Bank Stmts, 12 or 24 1099 Income,  12M P&L",
        rules: {
            "in": [
                { "var": "scenario.documentationType" },
                [
                    DocumentationType.full_doc, DocumentationType._12M_full_doc, DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l
                ]
            ]
        }

    },
    // Self-Employed Bor = True for all documentationType allowed EXCEPT WVOE, Full Doc, 12M Full Doc, DSCR, Asset Utilization
    {
        name: "Self-Employed Bor = True",
        rules: {
            "if": [
                {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l
                        ]
                    ],

                },
                {
                    "===":
                        [
                            { "var": "scenario.selfEmployed" },
                            true
                        ]
                },
                true
            ]

        }
    },
    /********* INTEREST ONLY */
    //Max LTV/CLTV for interest only
    {
        name: "Max LTV for interest only = 80%",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }
                    ],
                },
                true
            ]

        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $1,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 1500000]
        }
    },
    //Maximum cash-out 
    {
        name: "Maximum Cash-Out = $500,000",
        rules: {
            "<=": [{ "var": "scenario.cashOutAmount" }, 500000]
        }
    },
    //Max Debt To Income Ratio = 50% 
    {
        name: "Max Debt To Income Ratio = 50% ",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },
    //Mortgage History For being recent event (1x120x12 I should have 1x60x12 and so on or 1x90x12 and so on or 1x120x12 limit)
    {
        name: "Max Mortgage Lates for primary residence purchase and R/T = 1x120x12,0x150x12",
        rules: {
            'if': [
                {
                    "and": [
                        { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]] },
                        { "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence] }
                    ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 1] },
                        { "<=": [{ "var": "latePaymentsX150" }, 0] }
                    ]
                },
                true
            ]
        }
    },
    //N/A Recent Event Cash-Out 
    {
        name: "Only Purchase and R/T allowed for recent event primary residence",
        rules: {
            "if": [
                {
                    "or": [
                        { ">": [{ "var": "scenario.latePaymentsX60" }, 0] },
                        { ">": [{ "var": "scenario.latePaymentsX90" }, 0] },
                        { ">": [{ "var": "scenario.latePaymentsX120" }, 0] },
                        {
                            "and": [
                                { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                            ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                ]
                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]

                        }
                    ]
                },
                {
                    "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]]
                },
                true
            ]
        }
    },
    {
        name: "Only standard events for second homes and investment",
        rules: {
            'if': [
                {
                    "in": [{ "var": "scenario.occupancy" }, [Occupancy.second_home, Occupancy.investment_property]]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                        {
                            "or": [
                                { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                            ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                ]
                        },
                    ]
                },
                true
            ]
        }
    },
    // ****LTV/CLTV MAX FOR PROPERTY TYPE****
    //Max LTV/CLTV for Rural Property
    {
        name: "Max LTV/CLTV for rural property = 80%",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.rural"
                        },
                        true
                    ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                    ]
                },
                true
            ],
        }
    },
    //Max LTV/CLTV for Non-Warrantable Property
    {
        name: "Max LTV/CLTV for Non-Warrantable property = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.nonWarrantable"
                                },
                                true
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],
        }
    },
    //Max LTV/CLTV for 2-4 Units Property
    {
        name: "Max LTV/CLTV for 2-4 Units = 80%",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.propertyType"
                        },
                        [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],
        }
    },
    //************** INCOME ********//
    //Documentation Type (Asset Utilization non accepted in non prime)
    {
        name: "Doc Types Allowed = Full Doc, 12M Personal Bank Stmts, 24M Personal Bank Stmts, 12M 1099, 24M 1099, 12M P&L, 24M Business Bank Stmts, 12M Business Bank Stmts, 12M Full Doc",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.full_doc, DocumentationType._12M_full_doc, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_business_bank_stmts,]]
        }
    },
    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse]]
        }
    },
    // Min FICO for Second Home and Investment = 660 
    {
        name: "Min FICO for Second Homes & Investment  = 660",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.occupancy"
                        },
                        [
                            Occupancy.second_home,
                            Occupancy.investment_property
                        ]
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            660
                        ]
                },
                true
            ]

        }
    },
    // Min FICO for Primary Residence = 620
    {
        name: "Min Credit Score for Primary Residence = 620",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.occupancy"
                        },
                        Occupancy.primary_residence
                    ]
                },
                {
                    ">=": [
                        {
                            "var": "scenario.representativeCreditScore"
                        },
                        620
                    ]
                },
                true
            ]
        }
    },
    // FICO < 660 Purchase and R/T only 
    {
        name: "FICO < 660 Purchase and R/T only",
        rules: {
            "if": [
                {
                    "<": [
                        {
                            "var": "scenario.representativeCreditScore"
                        },
                        660
                    ]
                },
                {
                    "in": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]
                    ]
                },
                true
            ]
        }
    },
    // FICO < 660 Max DTI = 43% 
    {
        name: "FICO < 660 Purchase only",
        rules: {
            "if": [
                {
                    "<": [
                        {
                            "var": "scenario.representativeCreditScore"
                        },
                        660
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.debtToIncomeRatio"
                        },
                        43
                    ]
                },
                true
            ]
        }
    },
    //************CITIZENSHIP */
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien, ITIN
    //ITIN ACCEPTED IN EVEREST NON PRIME
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien, ITIN",
        rules: {
            "in": [{ "var": "scenario.citizenship" }, [Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien, Citizenship.itin]]
        }
    },
    // Occupancy Statuses Allowed for ITIN = Primary Residence, Second Home
    {
        name: "Occupancy Statuses Allowed for ITIN = Primary Residence, Second Home",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.citizenship"
                        },
                        Citizenship.itin
                    ]
                },
                {
                    "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home]]
                },
                true
            ]
        },
    },
    // Max LTV/CLTV for Citizenship ITIN = 80%
    {
        name: "Max LTV/CLTV for Citizenship ITIN = 80%",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.citizenship"
                        },
                        Citizenship.itin
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Standard eligibility only for ITIN 
    {
        name: "Standard eligibility only for ITIN",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.citizenship"
                        },
                        Citizenship.itin
                    ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                        {
                            "or": [
                                { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                            ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                ]
                        },
                    ]
                },
                true
            ]
        },
    },

    //******* MAX LTV FOR FICO AND LOAN AMOUNT AND LOAN PURPOSE UPPER TABLE (PRIMARY RESIDENCE)*/

    //PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC  && LOAN AMOUNT <=1,500,000

    //FOR STANDARD EVENT (0x60x12) Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //FOR STANDARD EVENT Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "and": [
                                    { ">=": [{ "var": "scenario.latePaymentsX30" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR STANDARD EVENT Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  620 <= FICO < 660 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    620
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ],
                                        },
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //PRIMARY RESIDENCE,PURCHASE && RATE TERM && LOAN AMOUNT <= 1,500,000

    //FOR RECENT EVENT Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "or":
                                    [
                                        { ">": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                        {
                                            "and": [
                                                { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                                { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                            ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                ]
                                        },

                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //RECENT EVENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "or":
                                    [
                                        { ">": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                        {
                                            "and": [
                                                { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                                { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                            ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                ]
                                        },

                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //RECENT EVENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  620 <= FICO < 660 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    620
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "or":
                                    [
                                        { ">": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                        {
                                            "and": [
                                                { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                                { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                            ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                ]
                                        },

                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //PRIMARY RESIDENCE,CASH-OUT && LOAN AMOUNT <= 1,500,000

    //FOR STANDARD EVENT Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && (Loan Purpose = Cash-Out) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x Scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },

                                        [LoanPurpose.refinance_cash_out],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //STANDARD EVENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 700 && Documentation Type = Full Doc && (Loan Purpose = Cash-Out) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },

                                        [LoanPurpose.refinance_cash_out],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },


    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT AND LOAN PURPOSE &&  (OCCUPANCY = SECOND HOME || INVESTMENT) UPPER TABLE */

    //SECOND HOME AND INVESTMENT,PURCHASE && RATE TERM && LOAN AMOUNT <= 1,500,000

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home or Investment
    {
        name: "Max LTV/CLTV x Scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 700 && Documentation Type = Full Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home or Investment
    {
        name: "Max LTV/CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //SECOND HOME OR INVESTMENT, && CASH-OUT && LOAN AMOUNT <=1,500,000

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home or Investment
    {
        name: "Max LTV/CLTV x Scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 700 && Documentation Type = Alt Doc && (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home or Investment
    {
        name: "Max LTV/CLTV x Scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0], },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    {
                                        "or": [
                                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                        ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {
                                        "or":
                                            [
                                                {
                                                    "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                },
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },
                                            ]
                                    },
                                ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //LIMITIS UPPER TABLE 


]

