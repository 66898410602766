import { Adjustment } from '../../../../classes/product';
import { RulesLogic } from 'json-logic-js'
import { DocumentationType } from "../../../../classes/scenario";

export default function adjustmentsCLTV(rows: object[], docTypes: DocumentationType[], cltvs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];


    rows.forEach((row: any) => {
        const docTypeRule: RulesLogic = {
            "in": [{ "var": "scenario.documentationType" }, docTypes]
        }
        cltvs.forEach((cltv, i, cltvs) => {
            let CLTV = cltv;
            const name = `CLTV ${CLTV} + Docs: ${docTypes.join(", ")}`

            const value = row[ranges[i]];
            if (value === "N/A" || value === "NA") return;
            const ltvRule: RulesLogic<{}> = i === cltvs.length - 1 ? { ">=": [{ "var": "scenario.CLTV" }, cltv] } : { "and": [{ ">": [{ "var": "scenario.CLTV" }, cltv] }, { "<=": [{ "var": "scenario.CLTV" }, cltvs[i + 1]] }] }
            const rules: RulesLogic<{}> = {
                "and": [
                    docTypeRule,
                    ltvRule,
                ]
            }
            adjustments.push({ name, rules, value })
        })
    })
    return adjustments
}

