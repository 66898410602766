import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsFicoxLTV from "../sharedNQMFAdjustments/firstLien/adjustmentsFicoxLTV"
import adjustmentsLock, { RowLock, adjustmentsLockExtension, adjustmentsPrepayWithoutCompunding, headerLock, headerPrepay } from "../sharedNQMFAdjustments/firstLien/adjustmentsLock";
import adjustmentsOthers, { RowOthers } from "../sharedNQMFAdjustments/firstLien/adjustmentsOther";

const ltvs = [0, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]

const ltvsHousingAndOthers = [0, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const rangesHousingAndOthers = ["0 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]


const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerAdditionalPriceAdj = ["priceAdjustments", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "G24:O60",
        header: headerAdditionalPriceAdj,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, ltvs, ranges)
}

// parse housing and bankruptcy and residual income and noVOR
const parseHousingAdjustmentsAndOthers = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerHousingAdjAndOthers = ["priceAdjustments", ...ranges.map(range => range)];

    const priceHousingTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "Q47:X57",
        header: headerHousingAdjAndOthers,
        defval: "",
    })
    return adjustmentsOthers(priceHousingTable, ltvsHousingAndOthers, rangesHousingAndOthers)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "Q16:X18",
        header: headerLock,
        defval: "",
    })

    const lockDaysExtensionTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "Q20:X22",
        header: headerLock,
        defval: "",
    })

    const prepayTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "C48:D54",
        header: headerPrepay,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsLockExtension(lockDaysExtensionTable),
        ...adjustmentsPrepayWithoutCompunding(prepayTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", ...ranges.map(range => range)];

    //full doc
    const arrayOfDocumentationType_fullDoc = [DocumentationType.full_doc, DocumentationType._12M_full_doc]
    const fullDoc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "G7:O13",
        header,
        defval: "",
    })

    //Alt Docs "Bank Statement, P&L w/ 2 MOS, 1099, Asset Utilization,  or P&L Only 
    const arrayAltDocs = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType.asset_utilization]
    const altDocs = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "G14:O20",
        header,
        defval: "",
    })

    return [
        ...adjustmentsFicoxLTV(fullDoc, arrayOfDocumentationType_fullDoc, ltvs, ranges),
        ...adjustmentsFicoxLTV(altDocs, arrayAltDocs, ltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
        ...parseHousingAdjustmentsAndOthers(sheet),
    ]
}