
import { Box, TableContainer, Table, Thead, Th, Tr, Tbody, Td } from '@chakra-ui/react'
import Product, { Result } from '../../classes/product';
import React from 'react';

interface Props {
    productNameGroup: string;
    disqualifiedProducts: { product: Product, result: Result }[];
}

const ResultBoxDisqualified = ({ disqualifiedProducts, productNameGroup }: Props) => {

    return (
        <Box bg='white' w='80em' p={4} color='white' borderWidth='1px' borderRadius='lg' margin={'1em'}>

            <div >

                <Box p={5} color='#907264' fontWeight={'bolder'} fontSize={'2xl'} >
                    Ineligible {productNameGroup}
                </Box>


                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Product</Th>
                                <Th >Reasons</Th>
                            </Tr>

                        </Thead>
                        <Tbody>
                            {disqualifiedProducts && disqualifiedProducts.map(({ product, result }) => (
                                <Tr key={product.name} color='black'>
                                    <Td>{product.name}</Td>
                                    <Td>
                                        <ul>
                                            {product && product.reasonsDisqualified(result.scenario)?.map((reason, i) => (
                                                <li key={i}>{reason}</li>
                                            ))}
                                        </ul>
                                    </Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </Box >
    );
};

export default ResultBoxDisqualified;

