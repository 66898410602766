import { Adjustment } from '../../../classes/product';
import { RulesLogic } from 'json-logic-js'
import { LockDays, Occupancy, Prepay } from "../../../classes/scenario";
import { compoundCLTVAdjustments } from '../utilsSummit/ruleUtils';

const locks = [LockDays._45, LockDays._60]
export const headerPrepay = ["term", "LLPA", "maxPx"];


export const adjustmentsLock = (rows: object[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];
    const name = "Lock Days"

    rows.forEach((row: any, index, rows) => {
        switch (row.lockDays) {


            case "45 days": {
                const _45lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._45]
                }
                adjustments.push({ name, rules: _45lockRule, value: row.adjustment })
                break;
            }

            case "60 days": {
                const _60lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._60]
                }
                adjustments.push({ name, rules: _60lockRule, value: row.adjustment })
                break;
            }
            default: return;
        }
    })
    return adjustments
}


const prepay = [Prepay._noPrepay, Prepay._1Year, Prepay._2Year, Prepay._3Year]


export const adjustmentsPrepay = (rows: object[], LTVs: number[], ranges: string[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];

    rows.forEach((row: any, index, rows) => {
        switch (row.term) {

            case "No Penalty": {
                const _noPrepayRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._noPrepay],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("No Prepay", row, LTVs, ranges, _noPrepayRule))
                break;
            }

            case "12 Months": {
                const _1YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._1Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("1 Year", row, LTVs, ranges, _1YearRule))

                break;
            }

            case "24 Months": {
                const _2YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._2Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("2 Year", row, LTVs, ranges, _2YearRule))
                break;
            }
            case "36 Months": {
                const _3YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._3Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("3 Year", row, LTVs, ranges, _3YearRule))
                break;
            }
            case "48 Months": {
                const _4YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._4Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("4 Year", row, LTVs, ranges, _4YearRule))
                break;
            }
            case "60 Months": {
                const _5YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._5Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("5 Year", row, LTVs, ranges, _5YearRule))
                break;
            }
            case ">=36 Months": {
                const _3YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._3Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("3 Year", row, LTVs, ranges, _3YearRule))
                break;
            }
            case "5 Year": {
                const _5YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._5Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push(...compoundCLTVAdjustments("5 Year", row, LTVs, ranges, _5YearRule))
                break;
            }
            default: return;
        }
    })
    return adjustments;
}



export const adjustmentsPrepayWithoutCompunding = (rows: object[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];

    rows.forEach((row: any, index, rows) => {
        switch (row.term) {

            case "No Penalty": {
                const _noPrepayRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._noPrepay],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "No Prepay", rules: _noPrepayRule, value: row.LLPA })
                break;
            }

            case "12 Months": {
                const _1YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._1Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "1 Year", rules: _1YearRule, value: row.LLPA })
                break;
            }

            case "24 Months": {
                const _2YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._2Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "2 Year", rules: _2YearRule, value: row.LLPA })
                break;
            }

            case "36 Months": {
                const _3YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._3Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "3 Year", rules: _3YearRule, value: row.LLPA })
                break;
            }
            case "48 Months": {
                const _4YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._4Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "4 Year", rules: _4YearRule, value: row.LLPA })
                break;
            }
            case "60 Months": {
                const _5YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._5Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "4 Year", rules: _5YearRule, value: row.LLPA })
                break;
            }
            default: return;
        }
    })
    return adjustments;
}
