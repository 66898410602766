import React from 'react';

import { Flex } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { scenarioAtom } from '../../services/scenarioService';
import { useFetchProducts } from '../../services/productService';
import { useMemo } from 'react';
import Product, { Result } from '../../classes/product';
import ResultBoxQualified from './ResultBoxQualified';
import ResultBoxDisqualified from './ResultBoxDisqualified';
import SimpleSidebar from '../sidebar/SideBar';
import {
  CircularProgress,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import { Link } from 'wouter';
import { IconContext } from 'react-icons';
import { TbArrowBack } from 'react-icons/tb';


const ResultsScreen = () => {
  const scenario = useAtomValue(scenarioAtom);
  const { products, loading, error } = useFetchProducts();

  let qualifiedProducts = useMemo<{ product: Product; result: Result }[]>(() => {
    return products?.filter((product) => {
      return product?.qualify(scenario);
    })
      .map((product) => {
        return { product, result: product.getResult(scenario) };
      })
      .sort((a, b) => a.result.cost?.coupon - b.result.cost?.coupon)
      ;
  }, [products, scenario]);


  let disqualifiedProducts = useMemo<
    { product: Product; result: Result }[]
  >(() => {
    return products
      .filter((product) => !product.qualify(scenario))
      .map((product) => ({ product, result: product.getResult(scenario) }));
  }, [products, scenario]);


  let filteredProducts = useMemo<{ product: Product; result: Result }[]>(() => {
    return qualifiedProducts.filter((product) => {
      if (scenario.minPayment && product.result.payment && scenario.minPayment > product.result.payment) {
        return false;
      }
      if (scenario.maxPayment && product.result.payment && scenario.maxPayment < product.result.payment) {
        return false;
      }
      if (scenario.minRate && scenario.minRate > product.result.cost.coupon) {
        return false;
      }
      if (scenario.maxRate && scenario.maxRate < product.result.cost.coupon) {
        return false;
      }
      return true;
    });
  }, [qualifiedProducts, scenario]);

  if (loading)
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress isIndeterminate />
      </Flex>
    );

  return (
    <Box margin="1em">
      <Breadcrumb>
        <BreadcrumbItem>
          <IconContext.Provider value={{ className: 'shared-class', size: '30', color: 'green' }}>
            <BreadcrumbLink as={Link} to="/" color={'green'}>
              <TbArrowBack />
            </BreadcrumbLink>
          </IconContext.Provider>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} to="results" color={'green'}>
            Results
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex>
        <SimpleSidebar children={''} />
        <Flex direction="column">
          {qualifiedProducts.length > 0 && (
            <ResultBoxQualified
              qualifiedProducts={filteredProducts}
              productNameGroup={'Products'}
              scenario={scenario}
            />
          )}
          {disqualifiedProducts.length > 0 && (
            <ResultBoxDisqualified
              disqualifiedProducts={disqualifiedProducts}
              productNameGroup={'Products'}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
export default ResultsScreen;
