import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsLock, { RowLock, adjustmentsPrepay, headerLock, headerPrepay } from "../sharedDeephaven2ndLienAdjustments/adjustmentsLock";
import adjustmentsFicoxCLTV from "../sharedDeephaven2ndLienAdjustments/adjustmentsFicoxCLTV";
import { RowOthers } from "../sharedDeephaven2ndLienAdjustments/adjustmentsOtherCLTV";
import adjustmentsOthers from "../sharedDeephaven2ndLienAdjustments/adjustmentsOtherCLTV";

const cltvs = [0, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]

const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];


    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "Q24:Z38",
        header,
        defval: "",
    })
    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "T41:U42",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


export const parseFICOxCLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", "", ...ranges.map(range => range)];


    const arrayFullDocs = [DocumentationType.full_doc, DocumentationType._12M_full_doc]
    const fullDocs = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "Q13:Z18",
        header,
        defval: "",
    })

    const arrayBankDocs = [DocumentationType._12M_personal_bank_stmts, DocumentationType._12M_business_bank_stmts]
    const bankDocs = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "Q13:Z19",
        header,
        defval: "",
    })




    return [
        ...adjustmentsFicoxCLTV(fullDocs, arrayFullDocs, cltvs, ranges),
        ...adjustmentsFicoxCLTV(bankDocs, arrayBankDocs, cltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxCLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}