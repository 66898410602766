import { useAtom } from 'jotai';
import { Divider, Stack, Box, Flex } from '@chakra-ui/react';
import InputRow from '../shared/InputRow';
import OptionsRow from '../shared/OptionsRow';
import CheckBoxRow from '../shared/CheckboxRow';
import MortgageRow from '../rowsStructures/MortgageRow';
import MonthsAgoRow from '../rowsStructures/MonthsAgoRow';
import {
  bankruptcyTypeAtom,
  citizenshipAtom,
  creditScoreAtom,
  debtToIncomeRatioAtom,
  documentationTypeAtom,
  firstTimeHomeBuyerAtom,
  selfEmployedAtom,
  fc_ss_dil_mco__months_agoAtom,
  debtServCoverageRatioAtom,
  termBankruptcyDismissalAtom,
  termBankruptcyDischargeAtom,
  termBankruptcyFilingAtom,
  covidForbearanceAtom,
  _2MonthsBankStatementsAtom,
} from '../../services/scenarioService';
import {
  BankruptcyType,
  Citizenship,
  DocumentationType,
} from '../../classes/scenario';
import { IoMdPerson } from 'react-icons/io';
import React from 'react';



const BorrowerInformationComponent = () => {
  const [selfEmployed, setSelfEmployed] = useAtom(selfEmployedAtom);
  const [firstHomeBuyer, setFirstHomeBuyer] = useAtom(firstTimeHomeBuyerAtom);
  const [citizenship, setCitizenship] = useAtom(citizenshipAtom);
  const [documentationType, setDocumentationType] = useAtom(
    documentationTypeAtom
  );
  const [debtToIncomeRatio, setDebtToIncomeRatio] = useAtom(
    debtToIncomeRatioAtom
  );
  const [debtServCoverageRatio, setDebtServCoverageRatio] = useAtom(
    debtServCoverageRatioAtom
  );
  const [representativeScore, setRepresentativeScore] = useAtom(creditScoreAtom);

  const [covidForbearance, setCovidForbearance] = useAtom(
    covidForbearanceAtom
  );

  const [bankruptcyType, setBankruptcyType] = useAtom(bankruptcyTypeAtom);
  const [termBankruptcyDismissal, setTermBankruptcyDismissal] = useAtom(
    termBankruptcyDismissalAtom
  );
  const [termBankruptcyDischarge, setTermBankruptcyDischarge] = useAtom(
    termBankruptcyDischargeAtom
  );
  const [termBankruptcyFiling, setTermBankruptcyFiling] = useAtom(
    termBankruptcyFilingAtom
  );

  const [termFCSSDILMCO, setTermFCSSDILMCO] = useAtom(
    fc_ss_dil_mco__months_agoAtom
  );
  const [_2MonthsBankStatements, set2MonthsBankStatements] = useAtom(_2MonthsBankStatementsAtom);

  const handleDebtToIncomeRatio = (ratio: string) => {
    setDebtToIncomeRatio(Number(ratio));
  };

  const handleDebtServiceCoverageRatio = (value: string) => {
    setDebtServCoverageRatio(value);
  };

  const handleRepresentativeScore = (value: string) => {
    setRepresentativeScore(Number(value));
  };



  return (
    <Box
      layerStyle='base'
    >

      <Flex layerStyle='title'>
        <Box  > < IoMdPerson /> </Box>
        <Box ml='4'> Borrower</Box>
      </Flex >

      <Box bg="white" w="100%" p={2} color="white" borderRadius="lg" />

      <Stack spacing={3} p={4}>
        <OptionsRow
          inputLeftAddon={'Citizenship'}
          options={Object.values(Citizenship)}
          value={citizenship}
          setValue={setCitizenship}
          width={'10em'}
          booleanRequired={false} />
        <CheckBoxRow
          inputLeftAddon={'Self Employed'}
          setChecked={setSelfEmployed}
          isChecked={selfEmployed}
          booleanRequired={false}
        />
        <CheckBoxRow
          inputLeftAddon={'First Time Home Buyer'}
          setChecked={setFirstHomeBuyer}
          isChecked={firstHomeBuyer}
        />
        <Divider />
        <OptionsRow
          inputLeftAddon={'Documentation Type'}
          options={Object.values(DocumentationType)}
          value={documentationType}
          setValue={setDocumentationType}
          booleanRequired={false}
          width={'10em'}
        />
        {documentationType === DocumentationType.dscr ? (
          <>
            <InputRow
              inputLeftAddon={'Debt Service Coverage Ratio'}
              width={'15em'}
              handleOnChange={handleDebtServiceCoverageRatio}
              value={debtServCoverageRatio}
              booleanRequired={true}
            />
            <InputRow
              inputLeftAddon={'Subject Property Gross Rent $'}
              width={'15em'}
              booleanRequired={false}
            />
          </>
        ) : null}
        {documentationType === DocumentationType._12M_p_and_l || documentationType === DocumentationType._24M_p_and_l ? (
          <>
            <CheckBoxRow
              inputLeftAddon={'2 months bank stmts'}
              setChecked={set2MonthsBankStatements}
              isChecked={_2MonthsBankStatements}
              booleanRequired={undefined}
            />
          </>
        ) : null}

        {
          documentationType !== DocumentationType.dscr ?
            <InputRow
              inputLeftAddon={'Debt To Income Ratio %'}
              width={'15em'}
              handleOnChange={handleDebtToIncomeRatio}
              value={debtToIncomeRatio}
              booleanRequired={true}
            />
            : null
        }
        <Divider colorScheme={'blue'} />
        {citizenship !== Citizenship.foreign_national ? (
          <InputRow
            inputLeftAddon={'Representative Credit Score'}
            width={'15em'}
            handleOnChange={handleRepresentativeScore}
            value={representativeScore}
            booleanRequired={true}
          />
        ) : null}

        <MortgageRow />
        <MonthsAgoRow
          inputLeftAddon={'FC / SS / DIL / MCO'}
          inputRightAddon={'months ago'}
          term={termFCSSDILMCO}
          setTerm={setTermFCSSDILMCO}
        />
        <CheckBoxRow
          inputLeftAddon={'Covid Forbearance < 6 Payments'}
          setChecked={setCovidForbearance}
          isChecked={covidForbearance}
          booleanRequired={undefined}
        />
        <OptionsRow
          inputLeftAddon={'Bankruptcy Type'}
          options={Object.values(BankruptcyType)}
          value={bankruptcyType}
          setValue={setBankruptcyType}
          width='10em'
          booleanRequired={false}
        />

        {bankruptcyType === BankruptcyType.chapter_7 ||
          bankruptcyType === BankruptcyType.chapter_11 ? (
          <>
            <MonthsAgoRow
              inputLeftAddon={'Bankruptcy Dismisal'}
              inputRightAddon={'months ago'}
              term={termBankruptcyDismissal}
              setTerm={setTermBankruptcyDismissal}
            />
            <MonthsAgoRow
              inputLeftAddon={'Bankruptcy Discharge'}
              inputRightAddon={'months ago'}
              term={termBankruptcyDischarge}
              setTerm={setTermBankruptcyDischarge}
            />
          </>
        ) : bankruptcyType === BankruptcyType.chapter_13 ? (
          <MonthsAgoRow
            inputLeftAddon={'Bankruptcy Filing'}
            inputRightAddon={'months ago'}
            term={termBankruptcyFiling}
            setTerm={setTermBankruptcyFiling}
          />
        ) : null}
      </Stack>
    </Box >
  );
};
export default BorrowerInformationComponent;
