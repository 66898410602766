import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import adjustmentsFicoxCLTV from "../sharedVerusAdjustments/secondLien/adjustmentsFicoxCLTV"
import { adjustmentsLock } from "../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import { RowOthers } from "../sharedVerusAdjustments/firstLien/adjustmentsOther";
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsCLTV from "../sharedVerusAdjustments/secondLien/adjustmentsCLTV";
import adjustmentsOthersCLTV from "../sharedVerusAdjustments/secondLien/adjustmentsOtherCLTV";


const cltvs = [0, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]

const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B77:L90",
        header,
        defval: "",
    })

    return adjustmentsOthersCLTV(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "", "adjustment"];
    type RowLock = { [key: typeof headerLock[number]]: string };

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B92:D96",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


const parseFICOxCLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", ...ranges.map(range => range)];

    /* *************** STANDARD DOC ********** */
    //full doc 24 Months, full doc 12 Months = Standard Doc 24 Months, Standard Doc 12 Months 
    const arrayOfDocumentationTypeStandardDoc = [DocumentationType.full_doc, DocumentationType._12M_full_doc]
    const fullDoc12_24Months = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C59:L65",
        header,
        defval: "",
    })

    //Extra Adjustments for full doc 12 Months = Standard Doc 12 Months 
    const arrayOfDocumentationType_fullDoc12Months = [DocumentationType._12M_full_doc]
    const fullDoc12MonthsExtraAdj = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C66:L66",
        header,
        defval: "",
    })

    /* *************** ALT DOC ********** */
    //12M bank stmts, 24M bank stmts (they don't have extra adjustments for 24M bank stmts ????)
    //12M p&l, 24M p&l
    //1099 12 Months, 1099 24 Months 
    //WVOE 

    const arrayOfDocumentationTypeAltDoc = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType.wvoe]
    const altDoc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C67:L73",
        header,
        defval: "",
    })

    //Extra Adjustments 12M Bank Statements
    const arrayOfDocumentationType_12Months_Bk_Stm = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts]
    const _12MonthsExtraAdj_Bk_Stm_Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C74:L74",
        header,
        defval: "",
    })

    //Extra 1099 12 Months 
    const arrayOfDocumentationType_12Months_1099 = [DocumentationType._12M_1099]
    const _12MonthsExtraAdj_1099Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C75:L75",
        header,
        defval: "",
    })


    return [
        ...adjustmentsFicoxCLTV(fullDoc12_24Months, arrayOfDocumentationTypeStandardDoc, cltvs, ranges),
        ...adjustmentsCLTV(fullDoc12MonthsExtraAdj, arrayOfDocumentationType_fullDoc12Months, cltvs, ranges),
        ...adjustmentsCLTV(_12MonthsExtraAdj_Bk_Stm_Doc, arrayOfDocumentationType_12Months_Bk_Stm, cltvs, ranges),
        ...adjustmentsFicoxCLTV(altDoc, arrayOfDocumentationTypeAltDoc, cltvs, ranges),
        ...adjustmentsCLTV(_12MonthsExtraAdj_1099Doc, arrayOfDocumentationType_12Months_1099, cltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxCLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}