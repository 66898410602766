import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsFicoxLTV from "../sharedNQMFAdjustments/firstLien/adjustmentsFicoxLTV"
import adjustmentsLock, { RowLock, adjustmentsLockExtension } from "../sharedNQMFAdjustments/firstLien/adjustmentsLock";
import adjustmentsOthers, { RowOthers } from "../sharedNQMFAdjustments/secondLien/adjustmentsOther";

const ltvs = [0, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 1.00"]



const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerAdditionalPriceAdj = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "E14:L25",
        header: headerAdditionalPriceAdj,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, ltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", '', '', "adjustment", ''];

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "N9:R11",
        header: headerLock,
        defval: "",
    })

    const lockDaysExtensionTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "N15:R17",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsLockExtension(lockDaysExtensionTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", ...ranges.map(range => range)];

    //all docs
    const arrayOfDocumentationType_fullDoc = [DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_1099]
    const allDocs = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "F7:L11",
        header,
        defval: "",
    })


    return [
        ...adjustmentsFicoxLTV(allDocs, arrayOfDocumentationType_fullDoc, ltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}