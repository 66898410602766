import { Box, Checkbox, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import React from 'react';

const CheckBoxRow = ({ inputLeftAddon, setChecked, isChecked, width = "100 %", booleanRequired }: { inputLeftAddon: any, setChecked: any, isChecked: any, width?: string, booleanRequired?: boolean }) => {
  return (
    <FormControl isRequired={booleanRequired}>
      <Stack spacing={5} direction='row'>
        <Checkbox
          borderColor='#4789b2'
          size="lg"
          colorScheme="green"
          isChecked={isChecked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <FormLabel color="#0f1162" bg='none' >{inputLeftAddon}
        </FormLabel>


      </Stack>
    </FormControl >
  );
};

export default CheckBoxRow;
