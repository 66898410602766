import { Amortization, Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";
import zipCodes from "../zipCodes.json";
export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position
    {
        name: "Lien Position Allowed = First Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    //Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                    ]
                ]
        }
    },
    //DocumentationType Allowed = Full Doc, 12 and 24 Bank Stmts, 12 and 24 1099
    //WVOE AND P&L are not accepted anymore 
    {
        name: "DocumentationType Allowed = Full Doc, 12 and 24 Bank Stmts, 12 and 24 1099",
        rules: {
            "in":
                [
                    { "var": "scenario.documentationType" },
                    [
                        DocumentationType.full_doc, DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099
                    ]

                ]
        },
    },
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $3,000,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 3000000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed (the guidelines are confusing but townhouse it's part of Attached so it has to be included)
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo,  Low-Rise Condo, Modular, Mid-Rise Condo, High-Rise Condo, Condotel, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },
    //PROPERTY TYPES RULES 
    // Rural not eligible
    {
        name: "Rural not eligible",
        rules: {
            "===":
                [
                    {
                        "var": "scenario.rural"
                    },
                    false
                ]
        },
    },
    //Max LTV/CLTV for Property Type 2-4 Units, Condominium - purchase
    {
        name: "Max LTV/CLTV  for 2-4 Units, Condominium = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.purchase
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }

                    ],
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type 2-4 Units, Condominium - refinance
    {
        name: "Max LTV/CLTV for 2-4 Units, Condominium Ref = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }

                    ],
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type Condotel
    {
        name: "Max LTV/CLTV for purchase Condotel = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.condotel]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.purchase
                            ]
                        }
                    ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }

                    ],
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for refinance Property Type Condotel (in the guidelines just says "Refinance", according to Mike that is cash-out and non cash-out refinance)
    {
        name: "Max LTV/CLTV for refinance Condotel = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.condotel]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [
                                    LoanPurpose.refinance_cash_out,
                                    LoanPurpose.refinance_no_cash_out
                                ]
                            ]
                        }
                    ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }

                    ],
                },
                true
            ]
        }
    },
    /******** AMORTIZATIONS INTEREST ONLY */
    // Min Credit Score for interest only 
    {
        name: "Min Credit Score for interest only = 660",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    ">=": [{ "var": "scenario.representativeCreditScore" }, 660]
                },
                true
            ]
        },
    },
    // Max LTV/CLTV for interest only = 85% 
    {
        name: "Max LTV/CLTV for interest only = 85%",
        rules: {
            "if": [

                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //HOUSING HISTORY RESTRICTIONS 
    //0x30 and 1x30 Max LTV = 85 % (this is 1x30x12 rule) for purchase 
    {
        name: "Max LTV/CLTV for housing history = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] },
                        ]
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.85] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.85] }
                        ]
                },
                true
            ]

        }
    },
    //2x30, 3x30, 4x30 and so on  Max LTV = 80 % (this is basically 0x60x12 rule) purchase 
    {
        name: "Max LTV/CLTV for housing history = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and": [
                                    { ">": [{ "var": "scenario.latePaymentsX30" }, 1] },
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                                ]
                            },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] },
                        ]
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                        ]
                },
                true
            ]

        }
    },
    //1x60, 2x60, 3x60 and so on  Max LTV = 70 % (this is basically 0x90x12 rule) purchase 
    {
        name: "Max LTV/CLTV for housing history = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] },
                        ]
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                        ]
                },
                true
            ]

        }
    },
    //0x30 and 1x30 Max LTV/CLTV = 80 % (this is 1x30x12 rule) for refinance
    {
        name: "Max LTV/CLTV for housing history = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] },
                        ]
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                        ]
                },
                true
            ]

        }
    },
    //2x30, 3x30, 4x30 and so on  Max LTV/CLTV = 75 % (this is basically 0x60x12 rule) refinance
    {
        name: "Max LTV/CLTV for scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    { ">": [{ "var": "scenario.latePaymentsX30" }, 1] },
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                                ]
                            },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] },
                        ]
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                        ]
                },
                true
            ]

        }
    },
    //N/A for refinance >= 1x60 (0x90x12 rule)
    {
        name: "Purchase only for housing history restrictions",
        rules: {
            "if": [
                {
                    "or": [
                        { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                        { ">": [{ "var": "scenario.latePaymentsX90" }, 0] },
                        { ">": [{ "var": "scenario.latePaymentsX120" }, 0] }
                    ],
                },
                { "===": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.purchase]] },
                true
            ]

        }
    },
    //MAX LOAN AMOUNT FOR HOUSING HISTORY RESTRICTIONS
    //Max Loan Amount for housing history = $ 3,000,000 1x30x12 rule
    {
        name: "Max Loan Amount for housing history = $ 3,000,000",
        rules: {
            "if":
                [
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]
                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 3000000] },
                    true
                ],
        },

    },
    //Max Loan Amount for housing history = $ 1,500,000 0x60x12 rule
    {
        name: "Max Loan Amount for housing history = $ 1,500,000",
        rules: {
            "if":
                [
                    {
                        "and": [
                            { ">": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]
                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 1500000] },
                    true
                ],
        },

    },
    //Max Loan Amount for housing history = $ 1,000,000 0x90x12 rule
    {
        name: "Max Loan Amount for housing history = $ 1,000,000",
        rules: {
            "if":
                [
                    {
                        "and": [
                            { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]
                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 1000000] },
                    true
                ],
        },

    },

    //HOUSING EVENT SEASONING RESTRICTIONS
    //BANKRUPTCY, FC,SS,DIL,MCO History
    //BK/FC/SS/DIL History
    //Minimum Months Since BK/FC/SS/DIL purchase = 12 
    {
        name: "Min Mos Since BK/FC/SS/DIL purchase = 12",
        rules: {
            "if": [
                { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] },
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                        { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 12] },
                                    ],


                            },
                            {
                                "or":
                                    [
                                        {
                                            "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                        },
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                        },
                                    ],
                            },
                            {
                                "or":
                                    [
                                        {
                                            "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                        },
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                        },
                                    ]
                            },
                            {
                                "or":
                                    [
                                        { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                        { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                    ]
                            },
                        ]
                },
                true
            ]


        }
    },
    // MAX LTV/CLTV FOR HOUSING EVENT SEASONING RESTRICTIONS

    // >= 36 months since BK/FC/SS/DIL BK7/BK11/BK13 for purchase

    {
        name: "Max LTV/CLTV for housing event scenario purchase = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "or":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] }
                        ],
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.85] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.85] }

                    ]
                },
                true

            ],
        },
    },
    // 24 months <= BK < 36 since FC/SS/DIL/MCO BK7/BK11/BK13 for purchase ****
    {
        name: "Max LTV/CLTV for housing event scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] }
                        ],
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                        ]
                },
                true

            ],
        },
    },
    // 12 months <= BK < 24 since FC/SS/DIL/MCO BK7/BK11/BK13 for purchase ****
    {
        name: "Max LTV/CLTV for housing event scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 12] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                                ],


                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                ],
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                ]
                                        },
                                    ],
                            },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] }
                        ],
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                        ]
                },
                true

            ],
        },
    },
    // REFINANCE HOUSING EVENT SEASONING RESTRICTIONS 
    //Minimum Months Since BK/FC/SS/DIL refinance = 24 
    {
        name: "Min Mos Since BK/FC/SS/DIL refinance = 24",
        rules: {
            "if": [
                { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] },
                {
                    "and": [
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                ],


                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                ],
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                ]
                        },
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                ]
                        },
                    ]

                },
                true
            ]

        },
    },
    // >= 36 months since BK/FC/SS/DIL BK7/BK11/BK13 for refinance

    {
        name: "Max LTV/CLTV for housing event scenario refinance = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "or":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] }
                        ],
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                    ]
                },
                true

            ],
        },
    },
    // 24 months <= BK < 36 since BK/FC/SS/DIL BK7/BK11/BK13 for refinance ****
    {
        name: "Max LTV/CLTV for housing event scenario ref = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] }
                        ],
                },
                {
                    "and":
                        [
                            { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                            { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                        ]
                },
                true

            ],
        },
    },
    //MAX LOAN AMOUNTS FOR HOUSING EVENT SEASONING RESTRICTIONS 
    {
        name: "Max Loan Amount for housing event scenario = $ 3,000,000",
        rules: {
            "if": [
                {
                    "and":

                        [
                            {
                                "or":
                                    [
                                        { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                        {
                                            ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36]
                                        }
                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "<=": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                        },
                                        {
                                            ">": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                        }
                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "<=": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                        },
                                        {
                                            ">": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                        }
                                    ]
                            },
                            {
                                "or":
                                    [
                                        { "<=": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                        { ">": [{ "var": "scenario.termBankruptcyFiling" }, 36] }
                                    ]
                            }

                        ],
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        3000000
                    ]
                },
                true
            ],
        }
    },
    {
        name: "Max Loan Amount for housing event scenario = $ 1,500,000",
        rules: {
            "if": [
                {
                    "or":
                        [
                            {
                                "and":
                                    [
                                        { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                        { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                        },
                                        {
                                            "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                        }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                        },
                                        {
                                            "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                        }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                        { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ],
        }
    },
    {
        name: "Max Loan Amount for housing event scenario = $ 1,000,000",
        rules: {
            "if": [
                {
                    "or":
                        [
                            {
                                "and":
                                    [
                                        { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 12] },
                                        { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                        },
                                        {
                                            "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                        }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                        },
                                        {
                                            "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                        }
                                    ]
                            },
                            {
                                "and":
                                    [
                                        { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                        { "<": [{ "var": "scenario.termBankruptcyFiling" }, 24] }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1000000
                    ]
                },
                true
            ],
        }
    },

    //OCCUPANCY RESTRICTTIONS 2ND HOME && INVESTMENT RULES

    //Max LTV/CLTV for Purchase Investment/2ndHome Property
    {
        name: "Max LTV/CLTV for Purchase Investment/2nd Home Property = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.purchase]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                [Occupancy.investment_property, Occupancy.second_home]
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Refinance Investment/2nd Home Property
    {
        name: "Max LTV/CLTV for Refinance Investment/2nd Home Property = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                [
                                    Occupancy.investment_property, Occupancy.second_home

                                ]
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max Loan Amount for Investment/2nd Home Property = $2,500,000
    {
        name: "Max Loan Amount for Investment/2nd Home Property = $2,500,000",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.occupancy"
                        },
                        [
                            Occupancy.investment_property, Occupancy.second_home
                        ]
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2500000
                    ]
                },
                true
            ]
        }
    },
    // ****STATE RESTRICTIONS****
    /* State Name State CBSA Name CBSA Code
    Connecticut CT 
    Idaho ID 
    Illinois IL 
    Louisiana LA
    New Jersey NJ 
    New York NY 
    
    Phoenix-Mesa-Scottsdale, AZ 38060
    Sacramento-Roseville, CA 40900
    San Francisco-Oakland-Hayward, CA 41860
    San Jose-Sunnyvale-Santa Clara, CA 41940
    Santa Cruz-Watsonville, CA 42100
    Boulder, CO 14500
    Breckenridge, CO 14720
    Sevierville, TN 42940
    Austin-Round Rock, TX 12420 */

    //If either or both of the following apply: 1) the appraisal report identifies the property as a declining market; 2) the subject property is in a state or CBSA in the table at the end of this document, the maximum LTV/CLTV is limited to 85% for purchases and 80% for all refinances and the maximum loan amount is limited to $2MM.
    // Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000
    {
        name: "Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000",
        rules: {
            "if": [
                {
                    "or": [
                        {
                            "in": [
                                {
                                    "var": "scenario.state"
                                },
                                [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]
                            ],
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.zipCode"
                                },
                                [
                                    zipCodes
                                ]
                            ]
                        }

                    ]

                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2000000
                    ]
                },
                true
            ]

        },
    },
    // MORE STATE RESTRICTIONS FOR STATE AND CBSA CODES
    // Max LTV/CLTV for purchase and state restrictions = 80% 
    {
        name: "Max LTV/CLTV for purchase and state restrictions = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]

                        }
                    ]

                },
                true
            ],
        },
    },
    // for refinances
    {
        name: "Max LTV/CLTV for ref and state restrictions = 75 %",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]

                        }
                    ]
                },
                true
            ],
        },
    },

    //*************** UNDERWRITING REQUIREMENTS *************/
    //DTI REQUIREMENTS (for summit NON PRIME it's 50% for all docs)
    {
        name: "Max DTI = 50%",
        rules: {
            "<=": [
                {
                    "var": "scenario.debtToIncomeRatio"
                },
                50
            ]
        },
    },

    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = PURCHASE) = MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    //*************** SINCE FOR FULL DOC AND ALT DOC ARE PRACTICALLY THE SAME VALUES TABLE MOST OF THE RULES ARE MADE FOR BOTH TABLES */

    //FOR FICO >= 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.85
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.85
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 && Documentation Type = Full Doc && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.85
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.85
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && Documentation Type = Full Doc && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 700 && Documentation Type = Full Doc && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.85
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.85
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //FOR FICO 640 <= FICO < 660
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660  &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 620 <= FICO < 640
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 620 <= FICO < 640 &&  Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                620
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.LTV"
                                        },
                                        0.70
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.CLTV"
                                        },
                                        0.70
                                    ]
                            }

                        ],
                },
                true
            ],

        },
    },
    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = R/T REFINANCE) = MAX LTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE  UPPER TABLE */
    // FOR FICO >= 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],
        },
    },

    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //FOR FICO 640 <= FICO < 660
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.LTV"
                                        },
                                        0.75
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.CLTV"
                                        },
                                        0.75
                                    ]
                            }

                        ],
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660  &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    // I ADDED THE CONDITION OF DOCUMENTATION TYPE IN THIS RULE SINCE IT DOESNT EXIST IN ALT DOC 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [DocumentationType.full_doc, DocumentationType._12M_full_doc]
                                ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.LTV"
                                        },
                                        0.65
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.CLTV"
                                        },
                                        0.65
                                    ]
                            }

                        ],
                },
                true
            ],

        },
    },
    // FOR FICO 620 <= FICO < 640
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 620 <= FICO < 640 &&  Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                620
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.LTV"
                                        },
                                        0.70
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.CLTV"
                                        },
                                        0.70
                                    ]
                            }

                        ],
                },
                true
            ],

        },
    },
    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = CASH-OUT REFINANCE) = MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */

    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 640 <= FICO < 660
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660  &&  Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    // I ADDED THE CONDITION OF DOCUMENTATION TYPE IN THIS RULE SINCE IT DOESNT EXIST IN ALT DOC 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [DocumentationType.full_doc, DocumentationType._12M_full_doc]
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                        }

                    ]
                },
                true
            ],

        },
    },
    //************** LIMITS TABLE PRIMARY RESIDENCE ********//

    //Minimum credit score for primary residence = 620  
    {
        name: "Minimum credit score for scenario = 620",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.occupancy"
                            },
                            Occupancy.primary_residence
                        ]
                },
                {
                    ">=":
                        [
                            { "var": "scenario.representativeCreditScore" },
                            620
                        ]
                },
                true
            ]

        }
    },
    // Max LoanAmount FOR FICO >= 680 = 3,000,000 (FULL AND ALT DOC) 
    {
        name: "Max LoanAmount for credit score = 3,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    680
                                ]
                        },
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.occupancy"
                                    },
                                    Occupancy.primary_residence
                                ]
                        }
                    ]

                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3000000
                        ]
                },
                true
            ],

        },
    },

    // Max baseLoanAmount for 640 <= Fico < 680 = 2,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x Credit Score scenario = 2,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    640
                                ]
                        },
                        {
                            "<":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    680
                                ]

                        }
                    ]

                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            2000000
                        ]
                },
                true
            ],

        }
    },
    // Max baseLoanAmount for 620 <= Fico < 640 = 1,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x Credit Score scenario = 1,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    620
                                ]
                        },
                        {
                            "<":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    640
                                ]

                        }
                    ]

                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            1000000
                        ]
                },
                true
            ],

        }
    },
    //Purchase only for credit score and loan amount 
    // 640 <= FICO < 660 && 1,500,000 < LOAN AMOUNT <= 2,000,000 NOT AVAILABLE FOR R/T AND CASH-OUT REFINANCE (ALL DOCS)
    {
        name: "Purchase only for credit score and loan amount",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]

                            },
                            {
                                ">":

                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]

                            },
                            {
                                "<=":

                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                        ]

                },
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.purchase
                        ]


                },
                true
            ],

        }
    },
    //Purchase and R/T Ref only for credit score and loan amount 
    // 620 <= FICO < 640 && LOAN AMOUNT <= 1,000,000 NOT AVAILABLE FOR CASH-OUT REFINANCE (ALL DOCS)
    {
        name: "Purchase and R/T Ref only for credit score and loan amount",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        620
                                    ]
                            },
                            {
                                "<":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]

                            },
                            {
                                "<=":

                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]

                            },
                        ]

                },
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.purchase
                        ]


                },
                true
            ],

        }
    },
    //Purchase only for credit score and loan amount and alt Doc 
    //  640 <= FICO < 660 && 1,000,000 < LOAN AMOUNT <= 1,500,000  NOT AVAILABLE FOR R/T AND CASH-OUT REFINANCE AND ALT DOC
    {
        name: "Purchase only for credit score and loan amount and alt doc",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]

                            },
                            {
                                ">":

                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]

                            },
                            {
                                "<=":

                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]

                },
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.purchase
                        ]


                },
                true
            ],

        }
    },
    /************** GUIDELINES RESTRICTIONS  */
    /* Guideline restrictions for Non Permanent Residents 2.3.1.3 NON-PERMANENT RESIDENT ALIEN (2/26/2024)
     o Standard or Alt Documentation limited to 24-months only. Maximum LTV/CLTV limited to 80%. 
     o DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.
     o Non-occupant co-borrowers are not allowed.
     o Gift funds are not allowed.*/

    {
        name: "Standard or Alt Documentation limited to 24-months only",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.citizenship"
                            },
                            Citizenship.non_permanent_resident_alien
                        ]
                },
                {
                    "in":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            [DocumentationType.full_doc, DocumentationType.asset_utilization, DocumentationType._24M_1099, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType.dscr]
                        ]
                },
                true
            ]
        },
    },
    {
        name: "Maximum LTV/CLTV limited to 80% for Non Permanent Residents",
        rules: {
            "if":
                [
                    {
                        "===":
                            [
                                {
                                    "var": "scenario.citizenship"
                                },
                                Citizenship.non_permanent_resident_alien
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]

                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    {
        name: "DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.citizenship"
                                        },
                                        Citizenship.non_permanent_resident_alien
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.dscr
                                    ]
                            }
                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        },
    },
    /** 
     * 2.3.4 FIRST TIME HOMEBUYERS (FTHB) (2/26/2024)
     * Primary residence only.
     • DTI may not exceed 45%. ( I am ignoring this one for now)
     • Minimum six (6) months of reserves.
     • 12-month rental history, reflecting 0x30, documented per Section 2.5.5 - Housing History.
     o First time homebuyers with less than 12-month rental history:
     ▪ DTI may not exceed 43%.
     ▪ LTV may not exceed 80%.
     ▪ Any available portion of a 12-month housing history must be paid as agreed.
 */
    {
        name: "First Time Home Buyer Primary residence only.",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                    },
                    true
                ]

        },
    },
    {
        name: "DTI FTHB = 43%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {
        name: "Max LTV/CLTV for FTHB = 80%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {

        name: "Max Mortgage Lates within last 12 mos for FTHB= 0x30,0x60,0x90,0x120",
        rules:
        {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]

                    },
                    true
                ]

        },
    }



]; // end of rules





