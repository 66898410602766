import { Adjustment } from '../../../../classes/product';
import { RulesLogic } from 'json-logic-js'
import { LienPosition, LockDays, Occupancy, Prepay } from "../../../../classes/scenario";

const locks = [LockDays._45, LockDays._60]
export const headerLock = ["lockDays", '', '', "adjustment", '', '', '', ''];
export const headerLockExtension = ["lockDays", '', "adjustment", '', '', '', ''];
export type RowLock = { [key: typeof headerLock[number]]: string };

export default function adjustmentsLock(rows: object[]): Adjustment[] {
    const adjustments: Adjustment[] = [];
    const name = "Lock Days"

    rows.forEach((row: any, index, rows) => {
        switch (row.lockDays) {
            case "30 Days": {
                const _45lockRule: RulesLogic = {
                    "and": [
                        { "in": [{ "var": "scenario.lockDays" }, [LockDays._45, LockDays._60]] },
                        { "===": [{ "var": "scenario.lienPosition" }, LienPosition.second] },
                    ]

                }
                adjustments.push({ name, rules: _45lockRule, value: row.adjustment })
                break;
            }
            case "45 Days": {
                const _45lockRule: RulesLogic = {
                    "and": [
                        { "in": [{ "var": "scenario.lockDays" }, [LockDays._45, LockDays._60]] },
                        { "!==": [{ "var": "scenario.lienPosition" }, LienPosition.second] },
                    ]

                }
                adjustments.push({ name, rules: _45lockRule, value: row.adjustment })
                break;
            }

            default: return;
        }
    })

    return adjustments
}

export const adjustmentsLockExtension = (rows: object[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];
    const name = "Lock Days Extension"

    rows.forEach((row: any, index, rows) => {
        switch (row.lockDays) {
            case "15 Days": {
                const _45lockRuleExtension15daysSecondLien: RulesLogic = {
                    "and":
                        [
                            { "===": [{ "var": "scenario.lockDays" }, LockDays._45] },
                            { "===": [{ "var": "scenario.lienPosition" }, LienPosition.second] }

                        ]
                }
                const _60lockRuleExtension15daysX2SecondLien: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.lockDays" }, LockDays._60] },
                        { "===": [{ "var": "scenario.lienPosition" }, LienPosition.second] }

                    ]
                }
                const _60lockRuleExtension15days: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.lockDays" }, LockDays._60] },
                        { "!==": [{ "var": "scenario.lienPosition" }, LienPosition.second] }

                    ]
                }
                adjustments.push({ name, rules: _45lockRuleExtension15daysSecondLien, value: Number(row.adjustment) })
                adjustments.push({ name, rules: _60lockRuleExtension15daysX2SecondLien, value: Number(row.adjustment) * 2 })
                adjustments.push({ name, rules: _60lockRuleExtension15days, value: Number(row.adjustment) })
                break;
            }
            default: return;
        }
    })

    return adjustments;
}


const prepay = [Prepay._noPrepay, Prepay._1Year, Prepay._2Year, Prepay._3Year]
export const headerPrepay = ["term", "LLPA", "maxPx"];

export const adjustmentsPrepayWithoutCompunding = (rows: object[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];

    rows.forEach((row: any, index, rows) => {
        switch (row.term) {

            case "6 mos/No PPP St.": {
                const _noPrepayRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._noPrepay],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "No Prepay", rules: _noPrepayRule, value: row.LLPA })
                break;
            }
            case "0 (state Law Only)": {
                const _noPrepayRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._noPrepay],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "No Prepay", rules: _noPrepayRule, value: row.LLPA })
                break;
            }
            case 12: {
                const _1YearRule: RulesLogic = {
                    "and":
                        [
                            {
                                "===": [{ "var": "scenario.prepay" }, Prepay._1Year],

                            },
                            {
                                "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                            }

                        ]

                }
                adjustments.push({ name: "1 Year Prepayment", rules: _1YearRule, value: row.LLPA })
                break;
            }

            case 24: {
                const _2YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._2Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "2 Year Prepayment", rules: _2YearRule, value: row.LLPA })
                break;
            }

            case 36: {
                const _3YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._3Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "3 Year Prepayment", rules: _3YearRule, value: row.LLPA })
                break;
            }
            case 48: {
                const _4YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._4Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "4 Year Prepayment", rules: _4YearRule, value: row.LLPA })
                break;
            }
            case 60: {
                const _5YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._5Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "5 Year Prepayment", rules: _5YearRule, value: row.LLPA })
                break;
            }
            default: return;
        }
    })
    return adjustments;
}
