import { Adjustment } from '../../../classes/product';
import { RulesLogic } from 'json-logic-js'
import { LockDays, Occupancy, Prepay } from "../../../classes/scenario";

const locks = [LockDays._45, LockDays._60]
export const headerLock = ["lockDays", "adjustment"];
export type RowLock = { [key: typeof headerLock[number]]: string };

export default function adjustmentsLock(rows: object[]): Adjustment[] {
    const adjustments: Adjustment[] = [];
    const name = "Lock Days"

    rows.forEach((row: any, index, rows) => {
        switch (row.lockDays) {


            case 45: {
                const _45lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._45]
                }
                adjustments.push({ name, rules: _45lockRule, value: row.adjustment })
                break;
            }

            case 60: {
                const _60lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._60]
                }
                adjustments.push({ name, rules: _60lockRule, value: row.adjustment })
                break;
            }
            default: return;
        }
    })
    return adjustments
}


const prepay = [Prepay._noPrepay, Prepay._1Year, Prepay._2Year, Prepay._3Year]
export const headerPrepay = ["term", "LLPA", "maxPx"];

export const adjustmentsPrepay = (rows: object[]): Adjustment[] => {
    const adjustments: Adjustment[] = [];

    rows.forEach((row: any, index, rows) => {
        switch (row.term) {

            case "No Prepay": {
                const _noPrepayRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._noPrepay],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "No Prepay", rules: _noPrepayRule, value: row.LLPA })
                break;
            }

            case "1 Year": {
                const _1YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._1Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "1 Year", rules: _1YearRule, value: row.LLPA })
                break;
            }

            case "2 Year": {
                const _2YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._2Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "2 Year", rules: _2YearRule, value: row.LLPA })
                break;
            }

            case "3 Year": {
                const _3YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._3Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "3 Year", rules: _3YearRule, value: row.LLPA })
                break;
            }
            case "5 Year": {
                const _5YearRule: RulesLogic = {
                    "and": [
                        {
                            "===": [{ "var": "scenario.prepay" }, Prepay._5Year],

                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                        }

                    ]

                }
                adjustments.push({ name: "5 Year", rules: _5YearRule, value: row.LLPA })
                break;
            }
            default: return;
        }
    })
    return adjustments;
}
