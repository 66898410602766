import { Stack, Box, Flex } from '@chakra-ui/react';
import CheckBoxRow from '../shared/CheckboxRow';
import { useAtom } from 'jotai';
import { delayedFinancingAtom, noVORAtom, residualIncomeLess1500Atom, residualIncomeLess2500Atom, twoOneBuydownAtom, waiveEscrowsAtom } from '../../services/scenarioService';
import { MdFeaturedPlayList } from 'react-icons/md';
import React from 'react';

const ProductFeaturesComponent = () => {
  const [waiveEscrows, setWaiveEscrows] = useAtom(waiveEscrowsAtom);
  const [twoOneBuydown, setTwoOneBuydown] = useAtom(twoOneBuydownAtom);
  const [delayedFinancing, setDelayedFinancing] = useAtom(delayedFinancingAtom);
  const [noVor, setNoVOR] = useAtom(noVORAtom);
  const [residualIncomeLessThan1500, setResidualIncomeLessThan1500] = useAtom(residualIncomeLess1500Atom);
  const [residualIncomeLessThan2500, setResidualIncomeLessThan2500] = useAtom(residualIncomeLess2500Atom);

  return (
    <Box
      layerStyle='base'
    >
      <Flex layerStyle='title'>
        <Box> <  MdFeaturedPlayList /> </Box>
        <Box ml='4'> Features</Box>
      </Flex >
      <Box bg="white" w="100%" p={2} color="white" />

      <Stack spacing={3} p={3}>
        <CheckBoxRow
          inputLeftAddon={'Waive Escrows'}
          setChecked={(val: boolean) => setWaiveEscrows(val)}
          isChecked={waiveEscrows}
          width={'10em'}
          booleanRequired={undefined}
        />
        <CheckBoxRow
          inputLeftAddon={'2-1 Buydown'}
          setChecked={(val: boolean) => setTwoOneBuydown(val)}
          isChecked={twoOneBuydown}
          booleanRequired={undefined}
          width={'10em'}
        />
        <CheckBoxRow
          inputLeftAddon={'Delayed Fin'}
          setChecked={(val: boolean) => setDelayedFinancing(val)}
          isChecked={delayedFinancing}
          booleanRequired={undefined}
          width={'10em'}
        />
        <CheckBoxRow
          inputLeftAddon={'No Verification of Rent'}
          setChecked={(val: boolean) => setNoVOR(val)}
          isChecked={noVor}
          booleanRequired={undefined}
        />
        <CheckBoxRow
          inputLeftAddon={'Residual Inc < $2,500 (min $1,500)'}
          setChecked={(val: boolean) => setResidualIncomeLessThan2500(val)}
          isChecked={residualIncomeLessThan2500}
          booleanRequired={undefined}
        />
        <CheckBoxRow
          inputLeftAddon={'Residual Inc < $1,500'}
          setChecked={(val: boolean) => setResidualIncomeLessThan1500(val)}
          isChecked={residualIncomeLessThan1500}
          booleanRequired={undefined}
        />
      </Stack>
    </Box>
  );
};
export default ProductFeaturesComponent;
