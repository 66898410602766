import React from 'react';

import { Box, Button } from '@chakra-ui/react';
import { navigate } from 'wouter/use-location';
import { citizenshipAtom, creditScoreAtom, debtServCoverageRatioAtom, debtToIncomeRatioAtom, documentationTypeAtom, zipCodeAtom } from '../../services/scenarioService';
import { useAtom } from 'jotai';
import { Citizenship, DocumentationType } from '../../../src/classes/scenario';

const Footer = () => {

  const [zipCode, setZipCode] = useAtom(zipCodeAtom);
  const [debtToIncomeRatio, setDebtToIncomeRatio] = useAtom(debtToIncomeRatioAtom);
  const [representativeScore, setRepresentativeScore] = useAtom(creditScoreAtom);
  const [citizenship, setCitizenship] = useAtom(citizenshipAtom);
  const [documentationType, setDocumentationType] = useAtom(documentationTypeAtom);
  const [debtServCoverageRatio, setDebtServCoverageRatio] = useAtom(debtServCoverageRatioAtom);



  const getRatesButton = () => {
    if (
      (typeof Number(zipCode) === 'number') &&
      (citizenship !== Citizenship.foreign_national && typeof representativeScore === 'number') &&
      ((documentationType !== DocumentationType.dscr && typeof debtToIncomeRatio === 'number'))
    ) return true

    else if (
      (typeof Number(zipCode) === 'number') &&
      (citizenship !== Citizenship.foreign_national && typeof representativeScore === 'number') &&
      ((documentationType === DocumentationType.dscr && typeof Number(debtServCoverageRatio) === 'number'))
    ) return true

    else if (
      (typeof Number(zipCode) === 'number') &&
      (citizenship === Citizenship.foreign_national) &&
      ((documentationType !== DocumentationType.dscr && typeof debtToIncomeRatio === 'number'))
    ) return true

    else if (
      (typeof Number(zipCode) === 'number') &&
      (citizenship === Citizenship.foreign_national) &&
      ((documentationType === DocumentationType.dscr && typeof Number(debtServCoverageRatio) === 'number'))
    ) return true

    else return false;
  };
  return (
    <Box
      display="flex"
      align-items="center"
      height="5em"
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      padding="20px"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection="row"
    >
      {getRatesButton() ?
        (
          <Button isDisabled={false} bg="#f54f64" color={'white'} onClick={() => navigate('results')}>
            Get Rates
          </Button>

        ) : <Button isDisabled={true} bg="#f54f64" color={'white'} onClick={() => navigate('results')}>
          Get Rates
        </Button>
      }
    </Box >
  );
};

export default Footer;
