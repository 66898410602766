// Gets the monthly payment for a fixed rate loan
// @param rate The annual interest rate
// @param term The term of the loan in months
export function payment(principal: number, rate: number, term: number): number {
    const monthlyInterestRate = rate / 12;
    const monthlyPayment = principal * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, term)) / (Math.pow(1 + monthlyInterestRate, term) - 1);
    return Number(monthlyPayment.toFixed(2));
}

export function paymentWithInterestOnly(principal: number, rate: number, term: number): number {
    const monthlyInterestRate = rate / 12;
    const monthlyPayment = principal * monthlyInterestRate;
    return Number(monthlyPayment.toFixed(2));
}
