import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline, MaxPrice } from '../../../classes/product';
import { Amortization, DocumentationType, Occupancy, Prepay } from "../../../classes/scenario";
import guidelines from "./guidelinesSummitNonPrime";
import parseAdjustments from "./adjustmentsSummitNonPrime";

type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}



const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const basePricingTableStdDocs = (XLSX.utils.sheet_to_json(sheet, {
        range: "A8:F33",
        header: ["coupon", Amortization._30_yr_fixed_arm_5_6, '', '', '', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[]);

    const basePricingTableAltDocs = (XLSX.utils.sheet_to_json(sheet, {
        range: "H8:M33",
        header: ["coupon", Amortization._30_yr_fixed_arm_5_6, '', '', '', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[]);


    const basePricingTableStdDocsWithIO = ([...basePricingTableStdDocs] as RowData[]).map(row => {
        // Add IO amortizations
        row[Amortization._40_arm_5_6_IO] = row[Amortization._30_yr_fixed_arm_5_6];
        row[Amortization._40_yr_fixed_IO] = row[Amortization._30_yr_fixed];
        return row
    })

    const basePricingTableAltDocsWithIO = ([...basePricingTableAltDocs] as RowData[]).map(row => {
        // Add IO amortizations
        row[Amortization._40_arm_5_6_IO] = row[Amortization._30_yr_fixed_arm_5_6];
        row[Amortization._40_yr_fixed_IO] = row[Amortization._30_yr_fixed];
        return row
    })

    // .map(row => {
    //     row = {
    //         name: "Doc Types Allowed = 12 and 24 Bank Stmts, 12 and 24 1099",
    //         rules: {
    //             "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_personal_bank_stmts, DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099]]
    //         }
    //     };
    //     return row
    // }));


    const basePricingTableStdDocsWithIOReduced = basePricingTableStdDocsWithIO.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_full_doc, DocumentationType.full_doc]]
                }
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});

    const basePricingTableAltDocsWithIOReduced = basePricingTableAltDocsWithIO.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.asset_utilization, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]]
                }
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});

    const combinedPriceTable = { ...basePricingTableStdDocsWithIOReduced };

    Object.keys(basePricingTableAltDocsWithIOReduced).forEach(amortization => {
        if (combinedPriceTable[amortization as Amortization]) {
            combinedPriceTable[amortization as Amortization] = combinedPriceTable[amortization as Amortization].concat(basePricingTableAltDocsWithIOReduced[amortization as Amortization]);
        } else {
            combinedPriceTable[amortization as Amortization] = basePricingTableAltDocsWithIOReduced[amortization as Amortization];
        }
    });

    return combinedPriceTable;
}



function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
        [Amortization._40_yr_fixed_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_yr_fixed_IO}`,
                    rules: {
                        "in": [Amortization._40_yr_fixed_IO, { "var": "scenario.amortizations" }],
                    }
                },
            ],
            adjustments: [],
        },
        [Amortization._30_yr_fixed_arm_5_6]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed_arm_5_6}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed_arm_5_6, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: [],
        },
        [Amortization._40_arm_5_6_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_arm_5_6_IO}`,
                    rules: {
                        "in": [Amortization._40_arm_5_6_IO, { "var": "scenario.amortizations" }]
                    }
                },
            ],
            adjustments: [],
        },
    }
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "L3:L4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return `${effectiveDate[0]["effectiveDate"]} ${effectiveDate[1]["effectiveDate"]}`;
}

const parsePriceMaximumsPrepay = (coverSheet: XLSX.WorkSheet): MaxPrice[] => {
    const range = "B85:D90";
    const priceMaximums = XLSX.utils.sheet_to_json(coverSheet, {
        range,
        header: ["term", "LLPA", "maxPx"],
        rawNumbers: false,
    });

    function getPrepay(term: string): Prepay | undefined {
        switch (term) {
            case "No Penalty": return Prepay._noPrepay;
            case "No Prepay": return Prepay._noPrepay;
            case "1 Year": return Prepay._1Year;
            case "2 Year": return Prepay._2Year;
            case "3 Year": return Prepay._3Year;
            case "4 Year": return Prepay._4Year;
            case "5 Year": return Prepay._5Year;
        }
    }

    return priceMaximums.map((row: any) => ({
        rules: {
            "and": [
                {
                    "===": [{ "var": "scenario.prepay" }, getPrepay(row.term)],

                },
                {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                }

            ]

        },
        value: Number(row.maxPx),
    }))
}

export default function parseSummitNonPrime(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const program = "Summit"
    const priceMaximums = parsePriceMaximumsPrepay(sheet);
    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            priceMaximums,
            effectiveDate,
        );
    });
}


///SUMMIT NON PRIME  = CREDIT ASCENT 