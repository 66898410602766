// 1. Import `extendTheme`
import { StyleFunctionProps, extendTheme } from '@chakra-ui/react'

// 2. Extend the theme with new layer styles
const theme = extendTheme({
    layerStyles: {
        base: {
            bg: "white",
            color: "white",
            borderWidth: "1px",
            margin: '1em',
            borderRadius: "lg",
            border: 'none',
            borderRight: '3px solid #edf2f6ff',
        },
        title: {
            w: "100%",
            p: '2',
            paddingLeft: '5',
            color: "#5d5c60ff",
            fontWeight: 'bold',
            borderTopRadius: "sm",
            fontSize: '1.5em',
            alignItems: 'center',
        }
    },

})
export default theme; 