import React, { ReactNode } from 'react';
import {
    IconButton,
    Box,
    Flex,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,

} from '@chakra-ui/react';
import {
    FiMenu,
} from 'react-icons/fi';

import FacetContainer from './FacetContainer';
import LockDaysFacet from './LockDaysFacet';
import PaymentSlider from './PaymentSlider';
import PointsFacet from './PointsFacet';
import RateSlider from './RateSlider';
import PrepaysFacet from './PrepayFacet';
import { baseLoanAmountAtom, occupancyAtom, prepayAtom, propertyTypeAtom, stateAtom } from '../../services/scenarioService';
import { useAtom } from 'jotai';
import { Occupancy } from '../../classes/scenario';
import AmortizationTypeFacet from './AmortizationTypeFacet';


export default function SimpleSidebar({ children }: { children: ReactNode }) {


    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box >
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const [occupancy, setOccupancy] = useAtom(occupancyAtom);
    const [state, setState] = useAtom(stateAtom)
    const [baseLoanAmount, setBaseLoanAmount] = useAtom(baseLoanAmountAtom)
    const [propertyType, setPropertyType] = useAtom(propertyTypeAtom)
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            paddingRight={4}
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            h="full"
            {...rest}>
            <FacetContainer name="Amortization">
                <AmortizationTypeFacet />
            </FacetContainer>
            <FacetContainer name="Lock Days">
                <LockDaysFacet />
            </FacetContainer>
            <FacetContainer name="Points">
                <PointsFacet />
            </FacetContainer>
            {
                (occupancy === Occupancy.investment_property) ?
                    <FacetContainer name="Prepay">
                        <PrepaysFacet />
                    </FacetContainer>
                    : null
            }
            <FacetContainer name="Payment">
                <PaymentSlider />
            </FacetContainer>
            <FacetContainer name="Rate">
                <RateSlider />
            </FacetContainer>
        </Box>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
                Logo
            </Text>
        </Flex>
    );
};