import Product from '../classes/product';
import { useEffect, useState } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { InteractiveBrowserCredential } from '@azure/identity';

export default class ProductService {
  private tenantId = '11a8c906-80b7-40c4-8e43-9af8c02a3606';
  private clientId = 'c93e0a47-1bb7-42e9-a281-e8a2b92186a3';
  private account = 'pricingenginedatastore';
  private container = 'product-files';

  authedCredential = new InteractiveBrowserCredential({
    tenantId: this.tenantId,
    clientId: this.clientId,
  });

  private authedClient = new BlobServiceClient(
    `https://${this.account}.blob.core.windows.net`,
    this.authedCredential
  );

  private anonymousClient = new BlobServiceClient(
    `https://${this.account}.blob.core.windows.net`,
    new AnonymousCredential()
  );

  private async blobToString(blob: any) {
    const fileReader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target?.result as string);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  async getProduct(id: string): Promise<Product> {
    const blobClient = this.anonymousClient
      .getContainerClient(this.container)
      .getBlobClient(id);
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await this.blobToString(
      await downloadBlockBlobResponse.blobBody
    );
    return Product.fromJson(JSON.parse(downloaded));
  }

  async getProducts(): Promise<Product[]> {
    const iterator = this.anonymousClient
      .getContainerClient(this.container)
      .listBlobsFlat()
      .byPage({
        // TODO: replace with comprehensive check for all results
        maxPageSize: 1000,
      });
    const response = (await iterator.next()).value;
    const ids = response.segment.blobItems.map((blob: any) => blob.name);
    return Promise.all(ids.map((id: any) => this.getProduct(id)));
  }

  async saveProduct(id: string, json: string) {
    const container = this.authedClient.getContainerClient(this.container);

    const result = await container.uploadBlockBlob(
      id,
      json,
      Buffer.byteLength(json)
    );
    return result.response;
  }

  async saveProducts(products: Product[]) {
    return Promise.all(
      products.map((product) => this.saveProduct(product.id as string, product.toJson()))
    );
  }

  async deleteProduct(id: string): Promise<void> {
    const containerClient = this.authedClient.getContainerClient(this.container);
    const blobClient = containerClient.getBlobClient(id);
    await blobClient.delete();
  }

  async deleteAllProducts(): Promise<void> {
    const containerClient = this.authedClient.getContainerClient(this.container);
    for await (const blob of containerClient.listBlobsFlat()) {
      const blobClient = containerClient.getBlobClient(blob.name);
      await blobClient.delete();
    }
  }
}

export const productServiceAtom = atom<ProductService>(new ProductService());
export const productsAtom = atom<Product[]>([]);
export const lastFetchAtom = atom<Date>(new Date(0));

export const useFetchProducts = () => {
  const [products, setProducts] = useAtom(productsAtom);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);
  const productService = useAtomValue(productServiceAtom);
  const [lastFetch, setLastFetch] = useAtom(lastFetchAtom);

  useEffect(() => {
    if (new Date().getTime() - lastFetch.getTime() < 1000 * 60 * 15) {
      setloading(false);
    } else {
      productService
        .getProducts()
        .then((products) => {
          setProducts(products);
          setloading(false);
          setLastFetch(new Date());
        })
        .catch((error) => {
          seterror(error);
          setloading(false);
        });
    }
  }, []);

  return { products, loading, error };
};
