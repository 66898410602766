import { Guideline } from "../../../classes/product";
import { Amortization, BankruptcyType, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

const guidelines: Guideline[] = [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Self-Employed Bor = True for all documentationType allowed EXCEPT WVOE, Full Doc, 12M Full Doc, DSCR, Asset Utilization
    {
        name: "Self-Employed Bor = True",
        rules: {
            "if": [
                {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l, DocumentationType._12M_1099, DocumentationType._24M_1099
                        ]
                    ],

                },
                {
                    "===":
                        [
                            { "var": "scenario.selfEmployed" },
                            true
                        ]
                },
                true
            ]

        }
    },
    //Max Debt To Income Ratio = 50% 
    {
        name: "Max Debt To Income Ratio = 50% ",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "if":
                [
                    {
                        "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]

                    },
                    { "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]] },
                    true
                ]

        },

    },
    //Documentation Types Allowed = Full Doc,DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l, DocumentationType._12M_1099, DocumentationType._24M_1099
    {
        name: "Documentation Types Allowed = Full Doc, Asset Utilization, 12M Business Bank Statements, 12M Personal Bank Statements, 24M Business Bank Statements, 24M Personal Bank Statements, 12M P&L, 24M P&L, 12M 1099, 24M 1099, WVOE",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.full_doc, DocumentationType.asset_utilization, DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType.wvoe]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Condotel, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien, FN, ITIN
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien (w/ US Credit), Foreign National, ITIN",
        rules: {
            "in": [{ "var": "scenario.citizenship" }, [Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien, Citizenship.foreign_national, Citizenship.itin]]
        }
    },
    /******* AMORTIZATIONS (NOT IO) */
    {
        name: "Min Credit Score = 640",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._15_yr_fixed, Amortization._30_yr_fixed, Amortization._40_yr_fixed, Amortization._30_yr_fixed_arm_5_6]],
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            640
                        ]
                },
                true
            ]

        },

    },
    /********* AMORTIZATIONS */
    //Max LTV/CLTV for interest only
    {
        name: "Max LTV for interest only = 80%",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }
                    ],
                },
                true
            ]

        }
    },
    //Min credit score for IO 
    {
        name: "Min Credit Score for IO= 680",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {

                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                true
            ]

        },
    },
    /******* INTEREST ONLY */
    {
        name: "Non 40Yr IO for Foreign National",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]],
                },
                {
                    "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                true
            ]

        },

    },
    //************** PROGRAM REQUIREMENTS ********//
    //Minimum residual income 1500 
    {
        name: "Minimum Residual Income = $1,500",
        rules: {
            "===": [{ "var": "scenario.residualIncomeLess1500" }, false]
        },
    },
    //Min Credit Score x PNL with 2 months bank statements = 640 
    {
        name: "Min Credit Score x PNL with 2 months bank statements = 640",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario._2MonthsBankStatements" }, true]
                },
                {
                    ">=": [{ "var": "scenario.representativeCreditScore" }, 640]
                },
                true
            ]
        },
    },
    //Min Credit Score x PNL Only = 680
    {
        name: "Min Credit Score x PNL Only = 680",
        rules: {
            "if": [
                {
                    "and": [
                        { "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]] },
                        { "===": [{ "var": "scenario._2MonthsBankStatements" }, false] }
                    ]
                },
                {
                    ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                },
                true
            ]
        },
    },
    //Max LTV/CLTV for PNL Only and 680 <=FICO < 700 = 75%
    {
        name: "Max LTV/CLTV for PNL Only and 680 <=FICO < 700 = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        { "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]] },
                        { "===": [{ "var": "scenario._2MonthsBankStatements" }, false] },
                        { ">=": [{ "var": "scenario.representativeCreditScore" }, 680] },
                        { "<": [{ "var": "scenario.representativeCreditScore" }, 700] },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        },
    },
    //Max LTV/CLTV for PNL Only and FICO >= 700 = 80%
    {
        name: "Max LTV/CLTV for PNL Only and FICO >= 700 = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        { "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]] },
                        { "===": [{ "var": "scenario._2MonthsBankStatements" }, false] },
                        { ">": [{ "var": "scenario.representativeCreditScore" }, 700] },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ]
        },
    },
    // Min Credit Score x WVOE = 680 
    {
        name: "Min Credit Score x WVOE = 680",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.documentationType" }, DocumentationType.wvoe]
                    },
                    {
                        ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                    },
                    true
                ]
        },
    },
    // ****LIMITS****
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            "if":
                [
                    {
                        "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]

                    },
                    { ">=": [{ "var": "scenario.baseLoanAmount" }, 150000] },
                    true
                ]
        }
    },
    {
        name: "Maximum Loan Amount = $3,500,000",
        rules: {
            "if":
                [
                    {
                        "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]

                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 3500000] },
                    true
                ]

        }
    },
    //Maximum cash-out if LTV/CLTV > 60% 
    {
        name: "Maximum Cash-Out for LTV/CLTV > 60% = $750,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or": [
                                    { ">": [{ "var": "scenario.LTV" }, 0.60] },
                                    { ">": [{ "var": "scenario.CLTV" }, 0.60] }
                                ]

                            },
                            {

                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ],
                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 750000]
                },
                true

            ]
        }
    },
    //Condos - Max 75% LTV/CLTV Cash Out 
    {
        name: "Max LTV/CLTV for Condos = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                                ]
                            },
                            {
                                "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condotel - NOO Purchase and Rate/Term Refinance Only and FICO >= 700 - Max 75% LTV/CLTV
    {
        name: "Max LTV/CLTV for Scenario = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condotel - NOO Purchase and Rate/Term Refinance Only and 680 <= FICO < 700 - Max 70% LTV/CLTV
    {
        name: "Max LTV/CLTV for Scenario = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                        },
                        {
                            "<": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },

    //Condotel - NOO Cash-Out and FICO >= 700 - Max 65% LTV/CLTV
    {
        name: "Max LTV/CLTV for Scenario = 65%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.refinance_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condotel - NOO Cash-Out and 680 <= FICO < 700 - Max 60% LTV/CLTV
    {
        name: "Max LTV/CLTV for Scenario = 60%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                        },
                        {
                            "<": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.refinance_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Min creditScore for NOO Condotel = 680
    {
        name: "Min Credit Score x Scenario = 680",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                },
                true
            ]
        },

    },
    //Max Loan Amount for NOO Condotel = $1,500,000
    {
        name: "Max Loan Amount x Scenario = $1,500,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "<=": [{ "var": "loanAmount" }, 1500000]
                },
                true
            ]
        },

    },
    // Max Cash-Out for NOO Condotel = $250,000
    {
        name: "Max Cash-Out x Scenario = $250,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.refinance_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 250000]
                },
                true
            ]
        },

    },
    //*******Condo 2-4 Unit LTV/CLTV Limits*********//

    //"Max LTV/CLTV for warrantable condos = 85%",
    {
        name: "Max LTV/CLTV for warrantable condos = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, false]
                        },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ]
        },
    },
    //"Max LTV/CLTV for non-warrantable condominium = 85%",
    {
        name: "Max LTV/CLTV for warrantable condos = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, true]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ]
        },
    },
    //"Max LTV/CLTV 2-4 unit = 80%",
    {
        name: "Max LTV/CLTV 2-4 Unit = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, true]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ]
        },
    },
    //"Max LTV/CLTV for Cash-Out in condos = 75%",
    {
        name: "Max LTV/CLTV for condos - Cash-Out = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        },
    },
    //Mortgage History ( O/O only)
    {
        name: "Max Mortgage Lates within last 12 mos O/O= 0x90,0x120",
        rules: {
            "if": [
                {
                    "and": [
                        { "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence] },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                        }
                    ]

                },
                true
            ]
        }
    },
    {
        name: "Max Mortgage Lates within last 12 mos = 0x60,0x90,0x120",
        rules: {
            "and": [
                {
                    "<=": [{ "var": "scenario.latePaymentsX30" }, 2]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                },
                {
                    "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                }
            ]
        },
    },
    // Max LTV/CLTV for Housing History 1x30x12 or BK/FC/SS/DIL >= 48 mos = 90% 
    {
        name: "Max LTV/CLTV for Scenario = 90%",
        rules: {
            "if":
                [
                    {
                        "and":
                            [
                                {
                                    "and": [
                                        { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "or":
                                        [
                                            {
                                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                            },
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                            },
                                        ]
                                },
                                {

                                    "or":
                                        [
                                            {
                                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                            },
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                            },
                                        ]

                                },
                                {
                                    "or":
                                        [
                                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                                        ]
                                },
                                {
                                    "!==": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                                },
                                {
                                    "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                                }
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.90]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.90]
                            }
                        ]
                    },
                    true
                ]
        }
    },
    // Max LTV/CLTV Cash-Out for Housing History 1x30x12 or BK/FC/SS/DIL >= 48 mos = 80% 
    {
        name: "Max LTV/CLTV for Scenario = 80%",
        rules: {
            "if":
                [
                    {
                        "and":
                            [
                                {
                                    "and": [
                                        { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "or":
                                        [
                                            {
                                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                            },
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                            },
                                        ]
                                },
                                {

                                    "or":
                                        [
                                            {
                                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                            },
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                            },
                                        ]

                                },
                                {
                                    "or":
                                        [
                                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                                        ]
                                },
                                {
                                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                                },
                                {
                                    "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                                }
                            ],
                    },
                    {
                        "and": [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.80]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.80]
                            }
                        ]
                    },
                    true
                ]
        }
    },
    // Max Loan Amount for Housing History 1x30x12 or BK/FC/SS/DIL >= 48 mos = $3,500,000 
    {
        name: "Max Loan Amount = $3,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                ]

                            },
                            {
                                "or":
                                    [
                                        {
                                            "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                        },
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                        },
                                    ]
                            },
                            {

                                "or":
                                    [
                                        {
                                            "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                        },
                                        {
                                            ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                        },
                                    ]

                            },
                            {
                                "or":
                                    [
                                        { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                        { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ],
                },
                {
                    "<=": [{ "var": "loanAmount" }, 3500000]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for Housing History 2x30x12 or BK/FC/SS/DIL >= 36 mos = 80% 
    {
        name: "Max LTV/CLTV for Scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and": [
                                                { "===": [{ "var": "scenario.latePaymentsX30" }, 2] },
                                                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                            ]

                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                                    },

                                                ]
                                        },
                                        {

                                            "and":
                                                [

                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                                    },
                                                ]

                                        },
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 48] },

                                                ]


                                        },

                                    ],
                            },
                            {
                                "!==": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV Cash-Out for Housing History 2x30x12 or BK/FC/SS/DIL >= 36 mos = 75% 
    {
        name: "Max LTV/CLTV for Scenario = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or":
                                [
                                    {
                                        "and": [
                                            { "===": [{ "var": "scenario.latePaymentsX30" }, 2] },
                                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                        ]

                                    },
                                    {
                                        "and":
                                            [
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                },
                                                {
                                                    "<": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                                },

                                            ]
                                    },
                                    {

                                        "and":
                                            [

                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                },
                                                {
                                                    "<": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                                },
                                            ]

                                    },
                                    {
                                        "and":
                                            [
                                                { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                { "<": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                                            ]
                                    },
                                ]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ],
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max Loan Amount for Housing Hitory 2x30x12 or BK/FC/SS/DIL >= 36 mos = $3,000,000 
    {
        name: "Max Loan Amount = $3,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {
                                    "and": [
                                        { "===": [{ "var": "scenario.latePaymentsX30" }, 2] },
                                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                            },

                                        ]
                                },
                                {

                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                            },

                                        ]

                                },
                                {
                                    "and":
                                        [
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                            { "<": [{ "var": "scenario.termBankruptcyFiling" }, 48] },

                                        ]
                                },
                            ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]

                },
                {
                    "<=": [{ "var": "loanAmount" }, 3000000]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for Housing Hitory 0x60x12 or BK/FC/SS/DIL >= 24 mos = 70% (all loan purposes)
    {
        name: "Max LTV/CLTV for Scenario = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {
                                    "and": [
                                        { ">": [{ "var": "scenario.latePaymentsX30" }, 2] },
                                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                            },

                                        ]
                                },
                                {

                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                            },

                                        ]

                                },
                                {
                                    "and":
                                        [
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                            { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },

                                        ]
                                },
                            ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }

                    ],
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max Loan Amount for Housing Hitory 0x60x12 or BK/FC/SS/DIL >= 24 mos = $2,000,000 
    {
        name: "Max Loan Amount = $2,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {
                                    "and": [
                                        { ">": [{ "var": "scenario.latePaymentsX30" }, 2] },
                                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                            },

                                        ]
                                },
                                {

                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                            },

                                        ]

                                },
                                {
                                    "and":
                                        [
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                            { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },

                                        ]
                                },
                            ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]

                },
                {
                    "<=": [{ "var": "loanAmount" }, 2000000]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for Housing Hitory 0x90x12 or BK/FC/SS/DIL >= 12 mos = 65% (non available for cash-out)
    {
        name: "Max LTV/CLTV for Scenario = 65%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {
                                    "and": [
                                        { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "and": [
                                        {
                                            "in":
                                                [
                                                    { "var": "scenario.bankruptcyType" },
                                                    [BankruptcyType.chapter_7, BankruptcyType.chapter_11]
                                                ],
                                        },
                                        {
                                            "or": [
                                                {
                                                    "and":
                                                        [
                                                            {
                                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                                            },
                                                            {
                                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                            },

                                                        ]
                                                },
                                                {

                                                    "and":
                                                        [
                                                            {
                                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                                            },
                                                            {
                                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                            },

                                                        ]

                                                },
                                            ]

                                        }
                                    ]

                                },
                                {
                                    "and": [
                                        {
                                            "in":
                                                [
                                                    { "var": "scenario.bankruptcyType" },
                                                    [BankruptcyType.chapter_13]
                                                ],
                                        },
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 24] },

                                                ]
                                        }
                                    ]

                                },
                            ]
                        },
                        {
                            "!==": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ],
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ]
        }
    },
    // NA Max LTV/CLTV Cash-Out for Housing Hitory 0x90x12 or BK/FC/SS/DIL >= 12 mos  (cash-out)
    {
        name: "Only non cash-out for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or": [
                                    {
                                        "and": [
                                            { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                        ]

                                    },
                                    {
                                        "and":
                                            [
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                                },
                                                {
                                                    "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                },
                                            ]
                                    },
                                    {

                                        "and":
                                            [
                                                {
                                                    ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                                },
                                                {
                                                    "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                },

                                            ]

                                    },
                                    {
                                        "and":
                                            [
                                                { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                                { "<": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                            ]
                                    },
                                ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ],
                },
                {
                    "!==": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                },
                true
            ]
        }

    },
    // Max Loan Amount for Housing Hitory 0x90x12 or BK/FC/SS/DIL >= 12 mos = $2,000,000 (non cash-out)
    {
        name: "Max Loan Amount = $2,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {
                                    "and": [
                                        { ">=": [{ "var": "scenario.latePaymentsX60" }, 1] },
                                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                                    ]

                                },
                                {
                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                            },

                                        ]
                                },
                                {

                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                            },

                                        ]

                                },
                                {
                                    "and":
                                        [
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                            { "<": [{ "var": "scenario.termBankruptcyFiling" }, 24] },

                                        ]
                                },
                            ]
                        },
                        {
                            "!==": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ],
                },
                {
                    "<=": [{ "var": "loanAmount" }, 2000000]
                },
                true
            ]
        }
    },


    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE AND LOAN PURPOSE UPPER TABLE (PRIMARY RESIDENCE)*/

    //FICO >= 760 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV for baseLoanAmount <= 1,500,000 && FICO >= 760 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 760 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if":
                [
                    {
                        "and":
                            [
                                {
                                    "and": [
                                        {
                                            ">":
                                                [
                                                    {
                                                        "var": "scenario.baseLoanAmount"
                                                    },
                                                    1500000
                                                ]
                                        },
                                        {
                                            "<=":
                                                [
                                                    {
                                                        "var": "scenario.baseLoanAmount"
                                                    },
                                                    2000000
                                                ]
                                        }
                                    ]
                                },
                                {
                                    "<=":
                                        [
                                            {
                                                "var": "scenario.baseLoanAmount"
                                            },
                                            2000000
                                        ]
                                },
                                {
                                    ">=":
                                        [
                                            {
                                                "var": "scenario.representativeCreditScore"
                                            },
                                            760
                                        ]
                                },
                                {
                                    "in":
                                        [
                                            {
                                                "var": "scenario.loanPurpose"
                                            },
                                            [
                                                LoanPurpose.purchase,
                                                LoanPurpose.refinance_no_cash_out,
                                            ]
                                        ]
                                },
                                {
                                    "in":
                                        [
                                            {
                                                "var": "scenario.occupancy"
                                            },
                                            [
                                                Occupancy.second_home,
                                                Occupancy.investment_property
                                            ]
                                        ]
                                },
                                {
                                    "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                                }
                            ]
                    },
                    {
                        "and":
                            [
                                {
                                    "<=": [{ "var": "scenario.LTV" }, 0.85]
                                },
                                {
                                    "<=": [{ "var": "scenario.CLTV" }, 0.85]
                                }
                            ]
                    },
                    true
                ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 740 <= FICO < 760 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,
                                        LoanPurpose.refinance_no_cash_out,
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,
                                        LoanPurpose.refinance_no_cash_out,
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //720 <= FICO < 740 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc & Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },

    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc & Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.90]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.90]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ],

        },
    },

    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property


                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property


                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home, Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":

                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":

                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* DEBT-CONSOLIDATION REFINANCE UPPER TABLE */

    //FICO > 760 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 740 <= FICO < 760 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.primary_residence]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE:  Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt Consolidation) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Debt-Consolidation)
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.debt_consolidation
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* CASH-OUT REFINANCE UPPER TABLE */

    //FICO > 760 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && FICO >= 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 740 <= FICO < 760 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if":
                [
                    {
                        "and":
                            [
                                {
                                    "and": [
                                        {
                                            ">":
                                                [
                                                    {
                                                        "var": "scenario.baseLoanAmount"
                                                    },
                                                    2500000
                                                ]
                                        },
                                        {
                                            "<=":
                                                [
                                                    {
                                                        "var": "scenario.baseLoanAmount"
                                                    },
                                                    3000000
                                                ]
                                        }
                                    ]

                                },
                                {
                                    ">=":
                                        [
                                            {
                                                "var": "scenario.representativeCreditScore"
                                            },
                                            740
                                        ]
                                },
                                {
                                    "<":
                                        [
                                            {
                                                "var": "scenario.representativeCreditScore"
                                            },
                                            760
                                        ]
                                },
                                {
                                    "in":
                                        [
                                            {
                                                "var": "scenario.loanPurpose"
                                            },
                                            [
                                                LoanPurpose.refinance_cash_out,
                                            ]
                                        ]
                                },
                                {
                                    "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                                }
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.70]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.70]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 740 <= FICO < 760 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        760
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 720 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY, SECONDARY AND INVESTMENT: Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 700 <= FICO < 720 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE:  Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT:  Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 && PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 && PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT 

    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [

                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ],

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //OCCUPANCY SECONDARY AND INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && Documentation Type = Full Doc && Alt Doc && (LoanPurpose = Cash-Out Ref)
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },

                                        [
                                            Occupancy.second_home,
                                            Occupancy.investment_property

                                        ]

                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.55]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.55]
                        }
                    ]
                },
                true
            ],

        },
    },


    //************** LIMITS TABLE  ********//
    // Min FICO for baseLoanAmount <= 1,500,000 
    {
        name: "Min Credit Scoree = 640",
        rules: {
            "if": [
                {
                    "!": [
                        {
                            "in": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                        }
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            640
                        ]
                },
                true
            ]

        },
    },
    // Min FICO for baseLoanAmount > 2,500,000 
    {
        name: "Min Credit Score x scenario = 680",
        rules: {
            "if":
                [
                    {
                        "and": [
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        2500000
                                    ]
                            }
                        ]

                    },
                    {
                        ">=":
                            [
                                {
                                    "var": "scenario.representativeCreditScore"
                                },
                                680
                            ]
                    },
                    true
                ]
        },
    },
    // Min FICO for baseLoanAmount > 3,000,000 
    {
        name: "Min Credit Score x scenario = 700",
        rules: {
            "if":
                [
                    {
                        "and": [
                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        3000000
                                    ]
                            }
                        ]

                    },
                    {
                        ">=":
                            [
                                {
                                    "var": "scenario.representativeCreditScore"
                                },
                                700
                            ]
                    },
                    true
                ]
        },
    },
    // Min FICO for baseLoanAmount > 3,500,000 
    {
        name: "Min Credit Score x scenario = 720",
        rules: {
            "if":
                [
                    {
                        "and": [

                            {
                                "!==": [{ "var": "scenario.citizenship" }, [Citizenship.foreign_national, Citizenship.itin]]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        3500000
                                    ]
                            }
                        ]

                    },
                    {
                        ">=":
                            [
                                {
                                    "var": "scenario.representativeCreditScore"
                                },
                                720
                            ]
                    },
                    true
                ]
        },
    },



    /***********FOREIGN NATIONAL GENERAL REQUIREMENTS */
    // Occupancy FOREIGN NATIONAL 
    {
        name: "Occupancy Allowed FN =  Second Home, Investment Property",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { "in": [{ "var": "scenario.occupancy" }, [Occupancy.second_home, Occupancy.investment_property]] },
                    true
                ]

        }
    },
    //Minimum and maximum loan amount FOREIGN NATIONAL 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { ">=": [{ "var": "scenario.baseLoanAmount" }, 150000] },
                    true
                ]
        }
    },
    {
        name: "Maximum Loan Amount = $3,000,000",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 3000000] },
                    true
                ]

        }
    },
    //Mortgage History FOREIGN NATIONAL
    {
        name: "Max Mortgage Lates = 0x30,0x60,0x90,0x120",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.latePaymentsX30" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                        }
                    ]

                },
                true
            ]
        }
    },
    //Housing History FOREIGN NATIONAL BK/FC/SS/Dil/Mod > 48 
    {
        name: "Housing History BK/FC/SS/Dil/Mod > 48",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                {
                    "and": [
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                    },
                                ]
                        },
                        {

                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                    },
                                ]

                        },
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                                ]
                        },
                    ]

                },
                true
            ]
        },
    },
    //Rural properties non permitted 
    {
        name: "Rural Properties Not Allowed",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                    },
                    {
                        "===": [{ "var": "scenario.rural" }, false]
                    },
                    true
                ]
        },
    },
    //Maximum cash-out if LTV/CLTV > 60% FOREIGN NATIONAL
    {
        name: "Maximum Cash-Out for LTV/CLTV > 50% = $750,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">": [{ "var": "LTV/CLTV" }, 0.50]
                            },
                            {

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ],
                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 750000]
                },
                true

            ]
        }
    },
    //Condos and 2-4 Units Purchase & R/T  - Max 70% LTV/CLTV 
    {
        name: "Max LTV/CLTV (Condos and 2-4 Units) for Purchase & R/T = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_no_cash_out, LoanPurpose.purchase]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condos and 2-4 Units Cash Out - Max 65% LTV/CLTV  
    {
        name: "Max LTV/CLTV (Condos and 2-4 Units) for Cash-Out Refi = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condotel Purchase and R/T - Max 70% LTV/CLTV 
    {
        name: "Max LTV/CLTV Condotel for Purchase & R/T = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_no_cash_out, LoanPurpose.purchase]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condotel Cash-Out - Max 60% LTV/CLTV 
    {
        name: "Max LTV/CLTV Condotel for Cash-Out Refi = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max Loan Amount Condotel = $1,500,000
    {
        name: "Max Loan Amount Condotel = $1,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ]

        }
    },
    //Max Cash-Out Amount Condotel = $250,000
    {
        name: "Max Cash-Out Amount Condotel = $250,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        250000
                    ]
                },
                true
            ]

        }
    },
    /********** FOREIGN NATIONAL RULES SECOND HOME*/
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and SECOND HOME: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.55]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.55]
                        }
                    ]
                },
                true
            ],

        },
    },
    /********** FOREIGN NATIONAL RULES INVESTMENT*/
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.55]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.55]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.55]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.55]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for   1,000,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.55]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.55]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and INVESTMENT: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && Documentation Type = Full Doc && Alt Doc (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 50%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.50]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.50]
                        }
                    ]
                },
                true
            ],

        },
    },

    /******************** ITIN TABBLE *************/
    //FICO >= 740 
    //ITIN and PRIMARY RESIDENCE and PURCHASE & R/T and 1Unit, SFR, Condo, PUD 

    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1500000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 700 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //*************ITIN and PRIMARY RESIDENCE and CASH-OUT and 1Unit, SFR, Condo, PUD ************

    //FICO >= 740 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 700 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //*************ITIN and PRIMARY RESIDENCE and PURCHASE & R/T and 2-4 Units, Family Housing ************

    // FICO >= 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },

    //*************ITIN and PRIMARY RESIDENCE and CASH-OUT and 2-4 Units, Family Housing ************
    // FICO >= 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 
    //ITIN AND PRIMARY RESIDENCE: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.primary_residence
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //*************ITIN and SECOND HOME and PURCHASE & R/T and 1 Unit, SFR, Condo, PUD ************
    // FICO >= 720 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 700 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },

    //*************ITIN and SECOND HOME and CASH-OUT and 1 Unit, SFR, Condo, PUD ************
    // FICO >= 720 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 700 <= FICO < 720 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND SECOND HOME: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 700 
    //ITIN AND SECOND HOME: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //*************ITIN and SECOND HOME and PURCHASE & R/T and 1-4 Units, Condo, PUD ************
    // FICO >= 700 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Purchase and R/T Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },

    //*************ITIN and INVESTMENT PROPERTY and CASH-OUT and 1 Unit, SFR, Condo, PUD ************
    // FICO >= 700 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 
    //ITIN AND INVESTMENT PROPERTY: Max LTV/CLTV for 125,000 < baseLoanAmount <= 1,500,000 && Documentation Type = Full Doc  && (LoanPurpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        125000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        }
                                        ,
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.investment_property
                                        ]

                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.pud,]]

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                            }

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ],

        },
    },
]

export default guidelines; 