export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const toNumber = (str: string) =>
  Number(str.replace(/[.](?=.*[.])/g, '').replace(/[^0-9.]/g, ''));

export const twoDecimals = (num: number) =>
  num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
