import React from 'react';

import { Divider, Stack, Box, Flex } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';

import LtvRow from '../rowsStructures/LtvRow';
import InputRow from '../shared/InputRow';
import LienPositionRow from '../rowsStructures/LienPositionRow';
import OptionsRow from '../shared/OptionsRow';
import { Occupancy, LoanPurpose, Prepay, LienPosition, RefinancePurposeCashOut, RefinancePurposeRateTerm, DrawTerm } from '../../classes/scenario';
import {
  occupancyAtom,
  loanPurposeAtom,
  baseLoanAmountAtom,
  propertyValueAtom,
  ltvAtom,
  cltvAtom,
  otherFinancingAtom,
  cashOutAmountAtom,
  prepayAtom,
  lienPositionAtom,
  refinancePurposeCashOutAtom,
  refinancePurposeRateTermAtom,
  drawTermAtom
} from '../../services/scenarioService';
import {
  currencyFormat,
  toNumber,
  twoDecimals,
} from '../../sharedUtils/numberUtils';
import { useState, useEffect } from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';

const FinancingStructureComponent = () => {
  const [lienPosition, setLienPosition] = useAtom(lienPositionAtom)
  const [occupancy, setOccupancy] = useAtom(occupancyAtom);
  const [loanPurpose, setLoanPurpose] = useAtom(loanPurposeAtom);
  const [drawTerm, setDrawTerm] = useAtom(drawTermAtom);
  const [baseLoanAmount, setBaseLoanAmount] = useAtom(baseLoanAmountAtom);
  const [propertyValue, setPropertyValue] = useAtom(propertyValueAtom);
  const [otherFinancingAmount, setOtherFinancingAmount] =
    useAtom(otherFinancingAtom);
  const [cashOutAmount, setCashOutAmount] = useAtom(cashOutAmountAtom);
  const ltvValue = useAtomValue(ltvAtom);
  const cltvValue = useAtomValue(cltvAtom);
  const [prepay, setPrepay] = useAtom(prepayAtom);
  const [refinancePurposeCashOut, setRefinancePurposeCashOut] = useAtom(refinancePurposeCashOutAtom);
  const [refinancePurposeRateTerm, setRefinancePurposeRateTerm] = useAtom(refinancePurposeRateTermAtom);

  const [propertyValueString, setPropertyValueString] = useState(
    currencyFormat.format(propertyValue)
  );

  const [baseLoanAmountString, setBaseLoanAmountString] = useState(
    currencyFormat.format(baseLoanAmount)
  );

  const [otherFinancingAmountString, setOtherFinancingAmountString] = useState(
    currencyFormat.format(otherFinancingAmount)
  );

  const [cashOutAmountString, setCashOutAmountString] = useState(
    currencyFormat.format(cashOutAmount)
  );

  const [ltvValueString, setLtvValueString] = useState(twoDecimals(ltvValue));

  const [cltvValueString, setCltvValueString] = useState(
    twoDecimals(cltvValue)
  );


  useEffect(() => {
    if (toNumber(baseLoanAmountString) !== baseLoanAmount) {
      setBaseLoanAmountString(currencyFormat.format(baseLoanAmount));
    }
  }, [baseLoanAmount]);

  useEffect(() => {
    if (toNumber(otherFinancingAmountString) !== otherFinancingAmount) {
      setOtherFinancingAmountString(
        currencyFormat.format(otherFinancingAmount)
      );
    }
  }, [otherFinancingAmount]);

  useEffect(() => {
    if (toNumber(ltvValueString) !== ltvValue) {
      setLtvValueString(twoDecimals(ltvValue));
    }
  }, [ltvValue]);

  useEffect(() => {
    const numberValue = toNumber(cltvValueString);
    const existing =
      numberValue < ltvValue ? numberValue + ltvValue : numberValue;
    if (existing !== cltvValue) {
      setCltvValueString(twoDecimals(cltvValue));
    }
  }, [cltvValue]);

  const handlePropertyValueChange = (value: string) => {
    setPropertyValueString(value);
    setPropertyValue(toNumber(value));
  };

  const handleBaseLoanChange = (value: string) => {
    setBaseLoanAmountString(value);
    setBaseLoanAmount(toNumber(value));
  };

  const handleOtherFinancingChange = (value: string) => {
    setOtherFinancingAmountString(value);
    setOtherFinancingAmount(toNumber(value));
  };

  const handleCashOutChange = (value: string) => {
    setCashOutAmountString(value);
    setCashOutAmount(toNumber(value));
  }

  const handleLTVChange = (value: string) => {
    setLtvValueString(value);
    setBaseLoanAmount((Number(value) / 100) * propertyValue);
  };

  const handleCLTVChange = (value: string) => {
    setCltvValueString(value);
    const numberValue = Number(value);
    const newVal =
      numberValue < ltvValue ? numberValue + ltvValue : numberValue;
    setOtherFinancingAmount(((newVal - ltvValue) / 100) * propertyValue);
  };

  const handleSetOccupancy = (value: Occupancy) => {
    setOccupancy(value);
    if (value !== Occupancy.investment_property) {
      setPrepay(Prepay._3Year);
    }
  };

  return (
    <Box
      layerStyle='base'
    >
      <Flex layerStyle='title'>
        <Box > < FaMoneyBillWave /> </Box>
        <Box ml='4'> Loan</Box>
      </Flex >
      <Box bg="white" p={2} color="white" />

      <Stack spacing={2} p={4}>
        <LienPositionRow />
        <OptionsRow
          inputLeftAddon={'Occupancy'}
          width={'10rem'}
          options={Object.values(Occupancy)}
          value={occupancy}
          setValue={handleSetOccupancy}
          booleanRequired={false}
        />
        <OptionsRow
          inputLeftAddon={'Loan Purpose'}
          width={'10rem'}
          options={lienPosition === LienPosition.second ? [LoanPurpose.refinance_cash_out] : Object.values(LoanPurpose)}
          value={loanPurpose}
          setValue={(val: LoanPurpose) => setLoanPurpose(val)}
          booleanRequired={false}
        />
        {loanPurpose === LoanPurpose.refinance_cash_out && lienPosition === LienPosition.first ? (
          <OptionsRow
            inputLeftAddon={'Refinance Purpose'}
            width={'10rem'}
            options={Object.values(RefinancePurposeCashOut)}
            setValue={(val: RefinancePurposeCashOut) => setRefinancePurposeCashOut(val)}
            booleanRequired={false}
            value={refinancePurposeCashOut}
          />
        ) : loanPurpose === LoanPurpose.refinance_no_cash_out ? (
          <OptionsRow
            inputLeftAddon={'Refinance Purpose'}
            width={'10rem'}
            value={refinancePurposeRateTerm}
            options={Object.values(RefinancePurposeRateTerm)}
            setValue={(val: RefinancePurposeRateTerm) => setRefinancePurposeRateTerm(val)}
            booleanRequired={false}
          />
        ) : null}
        {loanPurpose === LoanPurpose.refinance_cash_out && lienPosition === LienPosition.first ? (
          <InputRow
            inputLeftAddon={'Cash-Out Amount'}
            widthBox={undefined}
            width={'10rem'}
            handleOnBlur={(_) => {
              setCashOutAmountString(currencyFormat.format(cashOutAmount));
            }
            }
            handleOnChange={(val) => { handleCashOutChange(val) }}
            value={cashOutAmountString}
          />
        ) : null}
        {lienPosition === LienPosition.second ? (
          <OptionsRow
            inputLeftAddon={'Draw Term'}
            width={'10rem'}
            options={Object.values(DrawTerm)}
            value={drawTerm}
            setValue={(val: DrawTerm) => setDrawTerm(val)}
            booleanRequired={false}
          />
        ) : null}
        <Divider />
        <InputRow
          inputLeftAddon={'Property Value'}
          width={'10rem'}
          handleOnBlur={(_) =>
            setPropertyValueString(currencyFormat.format(propertyValue))
          }
          handleOnChange={handlePropertyValueChange}
          value={propertyValueString}
        />
        {
          lienPosition === LienPosition.first ?
            <>
              <LtvRow
                labelLeft={'LTV'}
                labelRight={'loan amount'}
                valueLeft={ltvValueString}
                valueRight={baseLoanAmountString}
                onChangeLeft={handleLTVChange}
                onChangeRight={handleBaseLoanChange}
                onBlurLeft={(_) => setLtvValueString(twoDecimals(ltvValue))}
                onBlurRight={(_) =>
                  setBaseLoanAmountString(currencyFormat.format(baseLoanAmount))
                }
              />
              <LtvRow
                labelLeft={'CLTV'}
                labelRight={'subordinate financing'}
                valueLeft={cltvValueString}
                valueRight={otherFinancingAmountString}
                onChangeLeft={handleCLTVChange}
                onChangeRight={handleOtherFinancingChange}
                onBlurLeft={(_) => setCltvValueString(twoDecimals(cltvValue))}
                onBlurRight={(_) => {
                  setOtherFinancingAmountString(
                    currencyFormat.format(otherFinancingAmount)
                  )
                }}
              />
            </>
            : null
        }
        {
          lienPosition === LienPosition.second ?
            <>
              <LtvRow
                labelLeft={'LTV'}
                labelRight={'existing lien'}
                valueLeft={ltvValueString}
                valueRight={baseLoanAmountString}
                onChangeLeft={handleLTVChange}
                onChangeRight={handleBaseLoanChange}
                onBlurLeft={(_) => setLtvValueString(twoDecimals(ltvValue))}
                onBlurRight={(_) =>
                  setBaseLoanAmountString(currencyFormat.format(baseLoanAmount))
                }
              />
              <LtvRow
                labelLeft={'CLTV'}
                labelRight={'second loan amount'}
                valueLeft={cltvValueString}
                valueRight={otherFinancingAmountString}
                onChangeLeft={handleCLTVChange}
                onChangeRight={handleOtherFinancingChange}
                onBlurLeft={(_) => setCltvValueString(twoDecimals(cltvValue))}
                onBlurRight={(_) => {
                  setOtherFinancingAmountString(
                    currencyFormat.format(otherFinancingAmount)
                  )
                }}
              />
            </>
            : null
        }
      </Stack>
    </Box>
  );
};
export default FinancingStructureComponent;
