import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import adjustmentsFicoxCLTV from "../sharedVerusAdjustments/secondLien/adjustmentsFicoxCLTV"
import { adjustmentsLock } from "../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import { RowOthers } from "../sharedVerusAdjustments/firstLien/adjustmentsOther";
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsOthersCLTV from "../sharedVerusAdjustments/secondLien/adjustmentsOtherCLTV";


const cltvs = [0, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]

const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B67:L81",
        header,
        defval: "",
    })

    return adjustmentsOthersCLTV(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "", "adjustment"];
    type RowLock = { [key: typeof headerLock[number]]: string };

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B84:D86",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


const parseFICOxCLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", ...ranges.map(range => range)];

    /* *************** STANDARD DOC ********** */
    //full doc 24 Months = Standard Doc 24 Months
    const arrayOfDocumentationTypeStandardDoc = [DocumentationType.full_doc]
    const fullDoc_24Months = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C59:L64",
        header,
        defval: "",
    })


    return [
        ...adjustmentsFicoxCLTV(fullDoc_24Months, arrayOfDocumentationTypeStandardDoc, cltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxCLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}