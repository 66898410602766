import { payment, paymentWithInterestOnly } from '../sharedUtils/rateUtils';
import Scenario, { Amortization, LienPosition, interestOnlyAmortizations } from './scenario';
import { RulesLogic, apply } from 'json-logic-js';

export enum ProductType {
  BaseAdjusted = 'BaseAdjusted',
}

export type Guideline = {
  name: string;
  rules: RulesLogic<{}>;
};

export type Result = {
  payment: number | undefined;
  costTable: Cost[];
  cost: Cost;
  scenario: Scenario;
};

export type Cost = {
  coupon: number;
  costPoints: number;
  rules: RulesLogic<{}>;
};



export default abstract class Product {
  id: string = '';
  name: string = '';
  program: string = '';
  amortization: Amortization | undefined;
  guidelines: Guideline[] = [];
  effectiveDate: string | undefined;
  primeRate: string | undefined;

  abstract explainRate(scenario: Scenario): (string | undefined)[];
  abstract getCostTable(scenario: Scenario): Cost[];
  abstract getCost(scenario: Scenario): Cost;

  getTerm(amortization: Amortization): number {
    switch (amortization) {
      case Amortization._15_yr_fixed: return 180;
      case Amortization._30_yr_fixed: return 360;
      case Amortization._40_yr_fixed: return 480;
      case Amortization._40_yr_fixed_IO: return 480;
      case Amortization._30_yr_fixed_IO: return 360;
      case Amortization._30_yr_fixed_arm_5_6: return 360;
      case Amortization._30_yr_fixed_arm_5_6_IO: return 360;
      case Amortization._40_arm_5_6_IO: return 480;
      default: return 180;
    }
  }

  getMonthlyPayment(scenario: Scenario): number {
    let term = this.getTerm(this.amortization || Amortization._30_yr_fixed);

    if (scenario.CLTV !== undefined && scenario.lienPosition === LienPosition.second && scenario.otherFinancing) {
      return payment(
        scenario.otherFinancing,
        this.getCost(scenario)?.coupon / 100,
        term
      )
    }
    else if (this.amortization && interestOnlyAmortizations.includes(this.amortization) && scenario.propertyValue) {
      return paymentWithInterestOnly(
        scenario.CLTV * scenario.propertyValue,
        this.getCost(scenario)?.coupon / 100,
        term
      );
    }
    else if (scenario.propertyValue) {
      return payment(
        scenario.CLTV * scenario.propertyValue,
        this.getCost(scenario)?.coupon / 100,
        term
      );
    }
    return 0;
  }

  getMonthlyPaymentOnlyInterest(scenario: Scenario): number | undefined {
    const monthlyInterestRate = this.getCost(scenario)?.coupon / 100 / 12;
    if (scenario.baseLoanAmount) return scenario.baseLoanAmount * monthlyInterestRate;
  }

  getResult(scenario: Scenario): Result {
    return {
      payment: this.getMonthlyPayment(scenario),
      costTable: this.getCostTable(scenario),
      cost: this.getCost(scenario),
      scenario,
    };
  }

  qualify(scenario: Scenario): boolean {

    return this.guidelines.every((guideline) => apply(guideline["rules"], { scenario, product: this })
    );
  }

  reasonsDisqualified(scenario: Scenario): string[] {
    return this.guidelines.filter((guideline) => !apply(guideline.rules, { scenario, product: this })).map((guideline) => guideline.name);
  }

  explainGuidelines(scenario: Scenario): { [key: string]: boolean } {
    return this.guidelines.reduce((explanation, guideline) => {
      return {
        ...explanation,
        [guideline.name]: apply(guideline.rules, { scenario, product: this }),
      };
    }, {});
  }



  static fromJson(json: any): Product {
    switch (json.type) {
      case ProductType.BaseAdjusted:
        return new BaseAdjustedProduct(
          json.name,
          json.program,
          json.amortization,
          json.basePriceTable,
          json.guidelines,
          json.adjustments,
          json.priceMaximums,
          json.effectiveDate,
          json.primeRate


        );
      default:
        throw new Error('Product not recognized');
    }
  }

  toJson(): any {
    return JSON.stringify(this);
  }
}

export type BasePriceTable = {
  coupon: number;
  price: number;
  rules: RulesLogic<{}>;
}[];

export type Adjustment = {
  name: string;
  rules: RulesLogic<{}>;
  // How much the rate is adjusted by
  value: number;
};

export type MaxPrice = {
  rules: RulesLogic<{}>;
  value: number;
}

export class BaseAdjustedProduct extends Product {
  type = ProductType.BaseAdjusted;
  basePriceTable: BasePriceTable;
  adjustments: Adjustment[];
  priceMaximums: MaxPrice[];
  // This is what NewPoint makes
  profitMargin: number = 1.75;
  primeRate: string | undefined;

  getApplicableAdjustments(scenario: Scenario): Adjustment[] {
    return this.adjustments?.filter((adjustment) => {
      return apply(adjustment.rules, { scenario, product: this })
    }
    );
  }

  explainRate(scenario: Scenario): (string | undefined)[] {
    return this.getApplicableAdjustments(scenario).map((adjustment) => {
      if (adjustment.value === undefined) return;
      return `${adjustment.name}: ${Number(adjustment.value).toFixed(3)}`
    });
  }

  sumAdjustments(scenario: Scenario): number {
    return this.getApplicableAdjustments(scenario)?.reduce(
      (sum, adjustment) => {
        if (adjustment.value === undefined) return sum;
        return sum - adjustment.value;
      }, 0
    );
  }

  basePriceTableForScenario(scenario: Scenario): BasePriceTable {
    return this.basePriceTable?.filter((row) => {
      return apply(row.rules, { scenario, product: this });
    })
  }

  getCostTable(scenario: Scenario): Cost[] {
    const adjustments = this.sumAdjustments(scenario)
    const price = 100 + this.profitMargin + adjustments;
    const priceMaximums = this.priceMaximums?.filter((priceMaximum) => apply(priceMaximum['rules'], { scenario, product: this })).map((priceMaximum) => Number(priceMaximum.value));
    const maxAllowed = priceMaximums?.length ? Math.min(...priceMaximums) + adjustments : price
    const couponObjects: Cost[] = [];

    const basePriceTableFiltered = this.basePriceTableForScenario(scenario);


    let previous: number | null = null;
    for (let i = 0; i < basePriceTableFiltered?.length; i++) {
      const row = basePriceTableFiltered[i];
      const applicablePrice = Math.min(price, maxAllowed, row.price)
      const points = price - applicablePrice;

      if (points === previous) {
        return couponObjects;
      } else if (points < 0) {
        const couponObject: Cost = {
          coupon: this.program?.includes('Heloc') ? Number((Math.ceil(row?.coupon * 200) / 200).toFixed(3)) + Number(Number(this?.primeRate).toFixed(3)) : Number((Math.ceil(row?.coupon * 200) / 200).toFixed(3)),
          costPoints: 0,
          rules: apply(row.rules, { scenario, product: this }),
        };
        couponObjects.push(couponObject);
        return couponObjects;
      } else {
        const couponObject: Cost = {
          coupon: this.program?.includes('Heloc') ? Number((Math.ceil(row?.coupon * 200) / 200).toFixed(3)) + Number(Number(this?.primeRate).toFixed(3)) : Number((Math.ceil(row?.coupon * 200) / 200).toFixed(3)),
          costPoints: Number(points.toFixed(3)),
          rules: apply(row.rules, { scenario, product: this }),
        };
        couponObjects.push(couponObject);
        previous = points
      }
    }

    return couponObjects;
  }

  getCost = (scenario: Scenario): Cost => {
    const couponsObjects = this.getCostTable(scenario);

    const pointsRow = couponsObjects?.find((couponObject) => {
      if (couponObject.coupon !== undefined && scenario.points)
        return apply(couponObject.rules, { scenario, product: this }) && Number(couponObject.costPoints) <= scenario.points;
    });

    return pointsRow || couponsObjects[couponsObjects.length - 1];
  };

  constructor(
    name: string,
    program: string,
    amortization: Amortization,
    basePriceTable: BasePriceTable,
    guidelines: Guideline[],
    adjustments: Adjustment[],
    priceMaximums: MaxPrice[],
    effectiveDate?: string,
    primeRate?: string,
  ) {
    super();
    this.id = name.replace(/\//g, '-').replace(/\s/g, '_');
    this.name = name;
    this.program = program;
    this.amortization = amortization;
    this.basePriceTable = basePriceTable;
    this.guidelines = guidelines;
    this.adjustments = adjustments;
    this.priceMaximums = priceMaximums;
    this.effectiveDate = effectiveDate;
    this.primeRate = primeRate;
  }
}
