import { Adjustment } from '../../../classes/product';
import { RulesLogic } from 'json-logic-js'
import { LockDays, Occupancy, Prepay } from "../../../classes/scenario";

const locks = [LockDays._45, LockDays._60]
export const headerLock = ["lockDays", "adjustment"];
export type RowLock = { [key: typeof headerLock[number]]: string };

export default function adjustmentsLock(rows: object[]): Adjustment[] {
    const adjustments: Adjustment[] = [];
    const name = "Lock Days"

    rows.forEach((row: any, index, rows) => {
        switch (row.lockDays) {


            case 45: {
                const _45lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._45]
                }
                adjustments.push({ name, rules: _45lockRule, value: row.adjustment })
                break;
            }

            case 60: {
                const _60lockRule: RulesLogic = {
                    "===": [{ "var": "scenario.lockDays" }, LockDays._60]
                }
                adjustments.push({ name, rules: _60lockRule, value: row.adjustment })
                break;
            }
            default: return;
        }
    })
    return adjustments
}


