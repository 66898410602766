import { minRateAtom, maxRateAtom } from '../../services/scenarioService';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react'
import { productsAtom } from '../../services/productService'
import { scenarioAtom } from '../../services/scenarioService'
import {
    Box,
    Stack,
    Input,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
} from "@chakra-ui/react"
import React from 'react';

const RateSlider = () => {
    const [minRate, setMinRate] = useAtom(minRateAtom)
    const [maxRate, setMaxRate] = useAtom(maxRateAtom)

    const products = useAtomValue(productsAtom);
    const scenario = useAtomValue(scenarioAtom);
    const min = useMemo(() => {
        return Math.min(...products.filter((product) => product.qualify(scenario)).map((product) => product.getCost(scenario)?.coupon))
    }, [products, scenario])

    const max = useMemo(() => {
        return Math.max(...products.filter((product) => product.qualify(scenario)).map((product) => product.getCost(scenario)?.coupon))
    }, [products, scenario])


    const handleSetMinRate = (value: number) => {
        if (value === min) setMinRate(undefined)
        else setMinRate(value)
    }

    const handleSetMaxRate = (value: number) => {
        if (value === max) setMaxRate(undefined)
        else setMaxRate(value)
    }

    const handleSliderChange = ([min, max]: number[]) => {
        handleSetMinRate(min)
        handleSetMaxRate(max)
    }

    return (<Box>
        <RangeSlider aria-label={['min', 'max']} min={min} max={max} value={[minRate || min, maxRate || max]} step={0.001} onChange={handleSliderChange}>
            <RangeSliderTrack>
                <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
        </RangeSlider>
        <Stack direction='row'>
            <Input type='number' value={minRate || min.toFixed(3)} onChange={(event) => handleSetMinRate(Number(event.target.value))} />
            <Input type='number' value={maxRate || max.toFixed(3)} onChange={(event) => handleSetMaxRate(Number(event.target.value))} />
        </Stack>
    </Box>

    )

}

export default RateSlider