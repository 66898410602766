import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { DocumentationType, Prepay } from "../../classes/scenario";
import { useAtom } from "jotai";
import { documentationTypeAtom, prepayAtom } from "../../services/scenarioService";
import React from "react";




const PrepaysFacet = () => {
    const [prepay, setPrepay] = useAtom(prepayAtom)
    const [documentationType, SetDocumentationType] = useAtom(documentationTypeAtom)
    return (
        <RadioGroup onChange={(value) => setPrepay(value as Prepay)} value={prepay}>
            <Stack direction='column'>
                {

                    (documentationType === DocumentationType.dscr) ?
                        Object.values(Prepay).map((value, index) => {
                            return <Radio key={value} value={value} >{value}</Radio>
                        }) :
                        Object.values(Prepay).filter(prepay => prepay !== Prepay._5Year && prepay !== Prepay._4Year).map((value, index) => {
                            return <Radio key={value} value={value} >{value}</Radio>
                        })
                }
            </Stack>
        </RadioGroup >
    );
};

export default PrepaysFacet;