import { WorkBook } from "xlsx";
import Product from "../../classes/product";
import parseSummitPrime from "./summitPrime/parseSummitPrime";
import parseSummitNonPrime from "./summitNonPrime/parseSummitNonPrime";
import parseSummitDSCR from "./summitDSCR/parseSummitDSCR";
import parseSummitForeignNationalDSCR from "./summitForeignNationalDSCR/parseSummitForeignNationalDSCR";
import parseSummitStandAlone2ndLien from "./summitStandAlone2ndLien/parseSummitStandAlone2ndLien";
import parseSummitDSCR_5_8Units from "./summitDSCRMulti/summitDSCR_5_8_Units/parseSummitDSCR_5_8_Units";
import parseSummitDSCRMixedUse from "./summitDSCRMulti/summitDSCR_MixedUse/parseSummitDSCR_MixedUse";
import parseSummitHeloc from "./summitHeloc/parseSummitHeloc";



//Prime Ascend Sheet (PA), Credit Ascend Sheet (CA)
export default function parseSummit(workbook: WorkBook): Product[] {
    const summitPrimeSheet = workbook.Sheets["PA"];
    const summitNonPrimeSheet = workbook.Sheets["CA"];
    const summitDSCRSheet = workbook.Sheets["DSCR"];
    const summitDSCR_5_8_UnitsSheet = workbook.Sheets["DSCR Multi"];
    const summitDSCR_MixedUseSheet = workbook.Sheets["DSCR Multi"];
    const summitForeignNationalSheet = workbook.Sheets["FN"];
    const summitStandAlone2ndLienSheet = workbook.Sheets["CES"];
    const summitHelocSheet = workbook.Sheets["HELOC"];
    return [
        ...parseSummitPrime(summitPrimeSheet),
        ...parseSummitNonPrime(summitNonPrimeSheet),
        ...parseSummitDSCR(summitDSCRSheet),
        ...parseSummitDSCR_5_8Units(summitDSCR_5_8_UnitsSheet),
        ...parseSummitDSCRMixedUse(summitDSCR_MixedUseSheet),
        ...parseSummitForeignNationalDSCR(summitForeignNationalSheet),
        ...parseSummitStandAlone2ndLien(summitStandAlone2ndLienSheet),
        ...parseSummitHeloc(summitHelocSheet)
    ]
}