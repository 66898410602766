import { Amortization } from '../../classes/scenario'
import { amortizationsAtom } from '../../services/scenarioService';
import { useAtom } from 'jotai';
import { Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { useMemo } from 'react'
import React from 'react';

const AmortizationTypeFacet = () => {
    const [amortizations, setAmortizations] = useAtom(amortizationsAtom)

    const allSelected = useMemo(() => {
        return Object.values(Amortization).every((amortization) => amortizations.includes(amortization))
    }, [amortizations])

    const toggle = (amortization: Amortization) => {
        if (allSelected) {
            setAmortizations([amortization])
        } else if (amortizations.includes(amortization)) {
            const updated = amortizations.filter((a) => a !== amortization)
            setAmortizations(updated.length === 0 ? Object.values(Amortization) : updated)
        } else {
            setAmortizations([...amortizations, amortization])
        }
    }

    return (
        <CheckboxGroup colorScheme='blue' defaultValue={['naruto', 'kakashi']}>
            <Stack spacing={[2, 2]} direction={['row', 'column']}>
                {Object.values(Amortization).map((amortization) => (
                    <Checkbox key={amortization} isChecked={!allSelected && amortizations.includes(amortization)} onChange={() => toggle(amortization)}>{amortization}</Checkbox>
                ))}
            </Stack>
        </CheckboxGroup>
    )
}

export default AmortizationTypeFacet