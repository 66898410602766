import React from 'react';

import {
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Box,
  Input,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  labelLeft: string;
  labelRight: string;
  valueLeft: string;
  valueRight: string;
  onChangeLeft(value: string): void;
  onChangeRight(value: string): void;
  onBlurRight?(value: string): void;
  onBlurLeft?(value: string): void;
}

const LtvRow: FC<Props> = ({
  labelLeft,
  labelRight,
  valueLeft,
  valueRight,
  onChangeLeft,
  onChangeRight,
  onBlurRight,
  onBlurLeft,
}) => {
  return (
    <InputGroup>
      <Box display={'flex'} w="40%">
        <InputLeftAddon children={labelLeft} color="#0f1162" fontWeight='bold' width="4.5rem" bg='none' border='none' />
        <Input
          color="black"
          value={valueLeft}
          onChange={(e) => onChangeLeft(e.target.value)}
          onFocus={(event) => event.target.select()}
          onBlur={onBlurLeft ? (e) => onBlurLeft(e.target.value) : undefined}
          marginLeft={1}
        />
        <InputRightAddon
          children="%"
          color="black"
          width="3rem"
          fontSize={'1xs'}
          fontWeight={'extrabold'}
          bg='none'
          border='none'
        />
      </Box>

      <Box display={'flex'} w="50%">
        <Input
          color="black"
          value={valueRight}
          onChange={(e) => onChangeRight(e.target.value)}
          onFocus={(event) => event.target.select()}
          onBlur={onBlurRight ? (e) => onBlurRight(e.target.value) : undefined}
        />
        <InputRightAddon
          width="8rem"
          children={labelRight}
          color="#0f1162"
          fontSize={'4xs'}
          fontWeight={'bold'}
          bg='none'
          border='none'
        />
      </Box>
    </InputGroup>
  );
};

export default LtvRow;
