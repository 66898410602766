import { Adjustment } from '../../../../classes/product';
import { compoundCLTVAdjustments, compoundLTVAdjustments, rangeRuleLoanAmount } from '../../utilsNQMF/ruleUtils';
import { RulesLogic } from 'json-logic-js'
import { Amortization, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../../classes/scenario";

export type RowOthers = { [key: string]: string | number };

export default function adjustmentsOthers(othersTable: RowOthers[], LTVs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];

    othersTable.forEach((row, index, othersTable) => {
        //It matchs loan amount range up to 3,000,000 (to avoid super jumbo nqmf)
        const bound = /(^(<|>)\s*[1-2](?:,)\d{1,3}(?:,)\d{1,3})|(^(<|>)\s*[3](?:,)[0]{1,3}(?:,)[0]{3})/;
        const boundStr = row.priceAdjustments.toString().match(bound)?.input ? row.priceAdjustments.toString().match(bound)?.input : undefined;

        const prev = boundStr ? othersTable[index - 1]?.priceAdjustments : undefined;
        const prevStr = prev?.toString().match(bound)?.input ? prev?.toString().match(bound)?.input : undefined;


        const nextBound = othersTable[index + 1]?.priceAdjustments;
        const nextBoundStr = nextBound?.toString().match(bound)?.input ? nextBound?.toString().match(bound)?.input : undefined;

        const priceAdjustmentsRow = row.priceAdjustments.toString();

        switch (true) {
            //Stand-Alone 2nd Lien
            case /\bStand-Alone 2nd Lien\b/i.test(priceAdjustmentsRow): {
                const standAloneSecondLienRule: RulesLogic = {
                    "===": [{ "var": "scenario.lienPosition" }, LienPosition.second]
                }
                adjustments.push(...compoundCLTVAdjustments("Stand-Alone 2nd Lien", row, LTVs, ranges, standAloneSecondLienRule));
                break;
            }
            /***HOUSING HISTORY ADJUSTMENTS***/
            //1 x 30 MTG
            case /^[\s]*1 x 30 MTG[\s]*$/.test(priceAdjustmentsRow): {
                // If there is no delay in the payment history, or there is only one of 1x30x12
                const _1x30x12HousingHistoryRule: RulesLogic = {
                    "===": [{ "var": "scenario.latePaymentsX30" }, 1]
                }
                adjustments.push(...compoundLTVAdjustments("Housing History: 1x30x12", row, LTVs, ranges, _1x30x12HousingHistoryRule))
                break;
            }
            //1 x 30 MTG (Min .75 DSCR)
            case /\b1 x 30 MTG (Min .75 DSCR)\b/.test(priceAdjustmentsRow): {
                const _1x30x12HousingHistoryRule: RulesLogic = {
                    "===": [{ "var": "scenario.latePaymentsX30" }, 1]
                }
                adjustments.push(...compoundLTVAdjustments("Housing History: 1x30x12", row, LTVs, ranges, _1x30x12HousingHistoryRule))
                break;
            }
            //2 x 30 MTG
            case /^[\s]*2 x 30 MTG[\s]*$/.test(priceAdjustmentsRow): {
                const _2x30x12HousingHistoryRule: RulesLogic = {
                    "or": [
                        { "===": [{ "var": "scenario.latePaymentsX30" }, 1] },
                        { "===": [{ "var": "scenario.latePaymentsX30" }, 2] }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("Housing History: 2x30x12", row, LTVs, ranges, _2x30x12HousingHistoryRule))
                break;
            }
            //0 x 60 MTG
            case /^[\s]*0 x 60 MTG[\s]*$/.test(priceAdjustmentsRow): {
                // If there is delay in the payment history, at least 3x30x12, that is more than 2x30x12.
                // Then you use the rate for 0x60x12
                const _0x60x12HousingHistoryRule: RulesLogic = {
                    ">": [{ "var": "scenario.latePaymentsX30" }, 2]
                }
                adjustments.push(...compoundLTVAdjustments("Housing History: 0x60x12", row, LTVs, ranges, _0x60x12HousingHistoryRule))
                break;
            }
            //0 x 90 MTG
            case /^[\s]*0 x 90 MTG[\s]*$/.test(priceAdjustmentsRow): {
                // If there is delay in the payment history, >= 1x60x12. That is 1x60x12 or 2x60x12 or 3x60x12..ecc
                // Then you use the rate for 0x90x12
                const _0x90x12HousingHistoryRule: RulesLogic = {
                    ">=": [{ "var": "scenario.latePaymentsX60" }, 1]
                }
                adjustments.push(...compoundLTVAdjustments("Housing History: 0x90x12", row, LTVs, ranges, _0x90x12HousingHistoryRule))
                break;
            }
            /***HOUSING EVENT SEASONING ADJUSTMENTS***/
            //BK/SS/NOD/DIL <24> 12
            case /\bBK\/SS\/NOD\/DIL <24> 12\b/.test(priceAdjustmentsRow): {
                const housingEventSeasoningRule: RulesLogic = {
                    "or": [
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 12] },
                                    { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 12]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 12]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                                    { "<=": [{ "var": "scenario.termBankruptcyFiling" }, 24] }
                                ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("BK/SS/NOD/DIL <24> 12", row, LTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //BK/SS/NOD/DI >24 <36
            case /\bBK\/SS\/NOD\/DI >24 <36\b/.test(priceAdjustmentsRow): {

                const housingEventSeasoningRule: RulesLogic = {
                    "or": [
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                    { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                    { "<=": [{ "var": "scenario.termBankruptcyFiling" }, 36] }
                                ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("BK/SS/NOD/DIL <36 > 24", row, LTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //BK/SS/NOD/DIL <36 > 24
            case /\bBK\/SS\/NOD\/DIL <36 > 24\b/.test(priceAdjustmentsRow): {

                const housingEventSeasoningRule: RulesLogic = {
                    "or": [
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                    { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                    { "<=": [{ "var": "scenario.termBankruptcyFiling" }, 36] }
                                ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("BK/SS/NOD/DIL <36 > 24", row, LTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //BK/SS/NOD/DIL < 48 > 36
            case /\bBK\/SS\/NOD\/DIL < 48 > 36\b/.test(priceAdjustmentsRow): {

                const housingEventSeasoningRule: RulesLogic = {
                    "or": [
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                    { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 48] }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    {
                                        ">": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                    },
                                    {
                                        "<=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                                    }
                                ]
                        },
                        {
                            "and":
                                [
                                    { ">": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                    { "<=": [{ "var": "scenario.termBankruptcyFiling" }, 48] }
                                ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("BK/SS/NOD/DIL < 48 > 36", row, LTVs, ranges, housingEventSeasoningRule))
                break;
            }
            /***PRODUCTS***/
            //12 Mos Income
            case /\b12 Mos Income\b/i.test(priceAdjustmentsRow): {
                const twelveMosIncomeRule: RulesLogic = {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._12M_full_doc,
                            DocumentationType._12M_1099,
                            DocumentationType._12M_business_bank_stmts,
                            DocumentationType._12M_personal_bank_stmts,
                            DocumentationType._12M_full_doc,
                            DocumentationType._12M_p_and_l
                        ]
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("12 Mos Income", row, LTVs, ranges, twelveMosIncomeRule));
                break;
            }
            /***LOAN PURPOSE***/
            //Purchase
            case /^[\s]*Purchase[\s]*/.test(priceAdjustmentsRow): {
                const purchaseRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase]
                }
                adjustments.push(...compoundLTVAdjustments("Purchase", row, LTVs, ranges, purchaseRule));
                break;
            }
            //Rate and Term
            case /^[\s]*Rate and Term[\s]*$/.test(priceAdjustmentsRow): {
                const rateAndTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_no_cash_out]
                }
                adjustments.push(...compoundLTVAdjustments("Rate and Term", row, LTVs, ranges, rateAndTermRule));
                break;
            }
            //Purchase (Max 85%) / Rate & Term (Max 80%)	(DSCR program)
            case /[\s]*Rate\s*&?\s*Term[\s]*/.test(priceAdjustmentsRow): {
                const rateAndTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_no_cash_out]
                }
                adjustments.push(...compoundCLTVAdjustments("Rate and Term", row, LTVs, ranges, rateAndTermRule));
                break;
            }
            //Cash-Out
            case /^[\s]*Cash-Out[\s]*$/.test(priceAdjustmentsRow): {
                const cashOutRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                }
                adjustments.push(...compoundLTVAdjustments("Cash-Out", row, LTVs, ranges, cashOutRule));
                break;
            }
            /***LOAN PURPOSE SECOND LIEN***/
            //Cash Out (Max 80% Condo @ 700+ Fico)
            case /\bCash Out (Max 80% Condo @ 700+ Fico)\b/.test(priceAdjustmentsRow): {
                const cashOutRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash Out", row, LTVs, ranges, cashOutRule));
                break;
            }
            /***OCCUPANCY***/
            //Second Home
            case /\bSecond Home\b/i.test(priceAdjustmentsRow): {
                const secondHomeRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.second_home]
                }
                adjustments.push(...compoundLTVAdjustments("Second Home", row, LTVs, ranges, secondHomeRule));
                break;
            }
            //Investment
            case /\bInvestment\b/i.test(priceAdjustmentsRow): {
                const investmentRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                }
                adjustments.push(...compoundLTVAdjustments("Investment", row, LTVs, ranges, investmentRule));
                break;
            }
            /***OCCUPANCY SECOND LIEN***/
            //Second Home (Reduce Max TV by 5% from O/O)
            case /\bSecond Home \(Reduce Max TV by 5% from O\/O\)\b/.test(priceAdjustmentsRow): {
                const secondHomeRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.second_home]
                }
                adjustments.push(...compoundCLTVAdjustments("Second Home", row, LTVs, ranges, secondHomeRule));
                break;
            }
            /***PROPERTY TYPE***/
            //Condo
            case /^[\s]*Condo[\s]*$/.test(priceAdjustmentsRow): {
                const condoRule: RulesLogic = {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, false]
                        }
                    ]

                }
                adjustments.push(...compoundLTVAdjustments("Condo", row, LTVs, ranges, condoRule));
                break;
            }
            //NW Condo
            case /\bNW Condo\b/i.test(priceAdjustmentsRow): {
                const nwCondoRule: RulesLogic = {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, true]
                        }
                    ]

                }
                adjustments.push(...compoundLTVAdjustments("NWCondo", row, LTVs, ranges, nwCondoRule));
                break;
            }
            //Condotel
            case /\bCondotel\b/.test(priceAdjustmentsRow): {
                const condotelRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.condotel]]
                }
                adjustments.push(...compoundLTVAdjustments("Condotel", row, LTVs, ranges, condotelRule));
                break;
            }
            //2 Unit //2Units 
            case /\b2 Units?\b/.test(priceAdjustmentsRow): {
                const twoUnitRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit]]
                }
                adjustments.push(...compoundLTVAdjustments("2 Unit", row, LTVs, ranges, twoUnitRule));
                break;
            }
            //2-4 Units
            case /\b2-4 Units?\b/.test(priceAdjustmentsRow): {
                const twoToFourUnitsRule: RulesLogic = {
                    "in": [
                        { "var": "scenario.propertyType" },
                        [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("2-4 Units", row, LTVs, ranges, twoToFourUnitsRule));
                break;
            }
            //3-4 Unit //3-4 Units
            case /\b3-4 Units?\b/.test(priceAdjustmentsRow): {
                const threeFourUnitRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType._3_unit, PropertyType._4_unit]]
                }
                adjustments.push(...compoundLTVAdjustments("3-4 Unit", row, LTVs, ranges, threeFourUnitRule));
                break;
            }
            //Mixed Use
            case /^\s*Mixed Use\s*$/.test(priceAdjustmentsRow): {
                const mixedUseRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.mixed_use_2_to_8_units]]
                }
                adjustments.push(...compoundLTVAdjustments("Mixed Use", row, LTVs, ranges, mixedUseRule));
                break;
            }
            //5-10 Unit
            case /^5-10 Units?\b/.test(priceAdjustmentsRow): {
                const fiveToTenUnitRule: RulesLogic = {
                    "===": [{ "var": "scenario.propertyType" }, PropertyType.residential_units]
                }
                adjustments.push(...compoundLTVAdjustments("5-10 Unit", row, LTVs, ranges, fiveToTenUnitRule));
                break;
            }
            /*** Property Type Second Lien ***/
            //Condo (Reduce Max LTV by 5% on NW Condos)
            case /\bCondo (Reduce Max LTV by 5% on NW Condos)\b/.test(priceAdjustmentsRow): {
                const condoRule: RulesLogic = {
                    "in": [
                        { "var": "scenario.propertyType" },
                        [PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Condo", row, LTVs, ranges, condoRule));
                break;
            }
            /***NON-WARRANTABLE***/
            //Non-Warrantable
            case /\bNon-Warrantable\b/.test(priceAdjustmentsRow): {
                const nonWarrantableRule: RulesLogic = {
                    "===": [{ "var": "scenario.nonWarrantable" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("Non-Warrantable", row, LTVs, ranges, nonWarrantableRule));
                break;
            }
            /*** Citizenship and Occupancy Foreign National */
            //Foreign 2nd HM
            case /\bForeign 2nd HM\b/.test(priceAdjustmentsRow): {
                const FN2ndHomeRule: RulesLogic = {
                    "and": [
                        {
                            "===": [
                                { "var": "scenario.citizenship" },
                                Citizenship.foreign_national
                            ],
                        },
                        {
                            "===": [
                                { "var": "scenario.occupancy" },
                                Occupancy.second_home
                            ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("Foreign National 2nd Home", row, LTVs, ranges, FN2ndHomeRule));
                break;
            }
            // non-owner occupied FOREIGN NATIONAL (Foreign NOO)
            case /\bForeign NOO\b/.test(priceAdjustmentsRow): {
                const FNNOORule: RulesLogic = {
                    "and": [
                        {
                            "===": [
                                { "var": "scenario.citizenship" },
                                Citizenship.foreign_national
                            ],
                        },
                        {
                            "===": [
                                { "var": "scenario.occupancy" },
                                Occupancy.investment_property
                            ]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("Foreign National NOO", row, LTVs, ranges, FNNOORule));
                break;
            }
            /*** CITIZENSHIP AND LOAN AMOUNT ***/
            //ITIN (Min. $125k)
            case /s*ITIN\s*\(Min\.\s*\$125k\)/.test(priceAdjustmentsRow): {
                const ITINRule: RulesLogic = {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        { "var": "scenario.citizenship" }, Citizenship.itin
                                    ]
                            },
                            {

                                ">=":
                                    [
                                        { "var": "scenario.baseLoanAmount" }, 125000
                                    ]
                            }

                        ]
                }
                adjustments.push(...compoundLTVAdjustments("ITIN,125K", row, LTVs, ranges, ITINRule));
                break;
            }
            /***LOAN AMOUNT SECOND LIEN***/
            //Loan Amounts < $180k
            case /\bLoan Amounts < $180k\b/.test(priceAdjustmentsRow): {
                const loanAmount180KRule: RulesLogic = {
                    "<": [{ "var": "scenario.otherFinancing" }, 180000]
                }
                adjustments.push(...compoundCLTVAdjustments("Loan Amount < 180K", row, LTVs, ranges, loanAmount180KRule));
                break;
            }
            /*** Escrow Waiver ***/
            //Escrow Waiver
            case /\bEscrow Waiver\b/.test(priceAdjustmentsRow): {
                const waiveEscrowsRule: RulesLogic = {
                    "===": [{ "var": "scenario.waiveEscrows" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("Escrow Waiver", row, LTVs, ranges, waiveEscrowsRule));
                break;
            }
            /**** DTI */
            //DTI > 43
            case /DTI\s*>\s*43/.test(priceAdjustmentsRow): {
                const debtToIncomeRule: RulesLogic = {
                    ">": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                }
                adjustments.push(...compoundLTVAdjustments("DTI > 43", row, LTVs, ranges, debtToIncomeRule));
                break;
            }
            //DTI > 43<50 (Owner Occupied Only)
            case /DTI > 43<50 (Owner Occupied Only)/.test(priceAdjustmentsRow): {
                const debtToIncomeRule: RulesLogic = {
                    "and": [
                        {
                            ">": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                        },
                        {
                            "<": [{ "var": "scenario.debtToIncomeRatio" }, 50]
                        },
                        {
                            "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                        }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("DTI >43<50", row, LTVs, ranges, debtToIncomeRule));
                break;
            }
            /***DOCUMENTATION TYPE & FICO***/
            //FICO>=680 @ Max 75%, >=700 @ Max 80% ( FOR P&L ONLY)
            case /FICO\s*>=.*?700/.test(priceAdjustmentsRow): {
                const pnlOnlyRule: RulesLogic = {
                    "and": [
                        {
                            "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "===": [{ "var": "scenario._2MonthsBankStatements" }, false]
                        }
                    ]

                }
                adjustments.push(...compoundLTVAdjustments("PNL Only and Fico >= 700", row, LTVs, ranges, pnlOnlyRule));
                break;
            }
            //FICO>=640
            case /FICO\s*>=.*?640/.test(priceAdjustmentsRow): {
                const pnlWith2moBS: RulesLogic = {
                    "and": [
                        {
                            "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 640]
                        },
                        {
                            "===": [{ "var": "scenario._2MonthsBankStatements" }, true]
                        }
                    ]

                }
                adjustments.push(...compoundLTVAdjustments("PNL w/2mo BS and Fico >= 640", row, LTVs, ranges, pnlWith2moBS));
                break;
            }
            /***DSCR***/
            //No Ratio <.75
            case /^\s*No Ratio <\.75/.test(priceAdjustmentsRow): {
                const noRatioRule: RulesLogic = {
                    "and": [
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                        { "<": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] }

                    ]
                }
                adjustments.push(...compoundLTVAdjustments("No Ratio <.75", row, LTVs, ranges, noRatioRule));
                break;
            }
            //<1.00x >.75
            case /^\s*<1.00x >.75/.test(priceAdjustmentsRow): {
                const ratioRule: RulesLogic = {
                    "and": [
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                        {
                            "<": [{ "var": "scenario.debtServCoverageRatio" }, 1.00]
                        },
                        {
                            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("<1.00x >.75", row, LTVs, ranges, ratioRule));
                break;

            }
            //>1.24 (AirDNA or MU/5-10 Units doesn't apply))
            case /^\s*\"?>\s*1\.?24\s*\(AirDNA\s*or\s*MU\s*\/5\s*-\s*10\s*Units?\s*doesn't\s*apply\)\)?\"?/.test(priceAdjustmentsRow): {
                const ratioRule: RulesLogic = {
                    "and":
                        [
                            { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                            {
                                ">": [{ "var": "scenario.debtServCoverageRatio" }, 1.24]
                            },
                            {
                                "!": [
                                    {
                                        "in": [{ "var": "scenario.propertyType" }, [PropertyType.residential_units, PropertyType.mixed_use_2_to_8_units]]
                                    }
                                ]
                            },
                        ]
                }
                adjustments.push(...compoundLTVAdjustments("DSCR>1.24", row, LTVs, ranges, ratioRule));
                break;

            }
            //Foreign National > = 1.0
            case /\bForeign National > = 1.0\b/.test(priceAdjustmentsRow): {
                const ratioRule: RulesLogic = {
                    "and": [
                        {
                            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.00]
                        },
                        {
                            "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("FN DSCR >= 1.0", row, LTVs, ranges, ratioRule));
                break;
            }
            //Foreign National >=.75 <1.0 (Fixed only)
            case /Foreign\s*National\s*>=.75\s*<1.0/.test(priceAdjustmentsRow): {
                const ratioRule: RulesLogic = {
                    "and": [
                        {
                            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.00]
                        },
                        {
                            "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                }
                adjustments.push(...compoundLTVAdjustments("FN DSCR >= 1.0", row, LTVs, ranges, ratioRule));
                break;
            }
            /***STAT ***/
            //Property State NY/GA
            case /\bProperty State NY\/GA\b/.test(priceAdjustmentsRow): {
                const gaRule: RulesLogic = {
                    "===": [{ "var": "scenario.state" }, State.georgia]
                }
                adjustments.push(...compoundLTVAdjustments("State GA", row, LTVs, ranges, gaRule));
                break;
            }
            //2-1Buydown
            case /\b2-1Buydown\b/.test(priceAdjustmentsRow): {
                const buydownRule: RulesLogic = {
                    "===": [{ "var": "scenario.twoOneBuydown " }, true]
                }
                adjustments.push(...compoundLTVAdjustments("2-1 Buydown", row, LTVs, ranges, buydownRule));
                break;
            }
            //Delayed Financing // Delayed Fin.
            case /\bDelayed Fin/.test(priceAdjustmentsRow): {
                const delayedFinancingRule: RulesLogic = {
                    "===": [{ "var": "scenario.delayedFinancing" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("Delayed Financing", row, LTVs, ranges, delayedFinancingRule));
                break;
            }
            /*** DOCUMENTATION */
            //PNL with 2 Months Bank Statements
            case /\bPNL with 2 Months Bank Statements\b/.test(priceAdjustmentsRow): {
                const _2MonthsBankStatementsRule: RulesLogic = {
                    "===": [{ "var": "scenario._2MonthsBankStatements" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("PNL with 2 Months Bk Stmts", row, LTVs, ranges, _2MonthsBankStatementsRule));
                break;
            }
            //2 Year Full Doc
            case /\b2 Year Full Doc\b/.test(priceAdjustmentsRow): {
                const twoYearFullDocRule: RulesLogic = {
                    "===": [{ "var": "scenario.documentationType" }, DocumentationType.full_doc]
                }
                adjustments.push(...compoundCLTVAdjustments("2 Year Full Doc", row, LTVs, ranges, twoYearFullDocRule));
                break;
            }
            //2 Year Alt Doc
            case /\b2 Year Alt Doc\b/.test(priceAdjustmentsRow): {
                const twoYearAltDocRule: RulesLogic = {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_1099]]
                }
                adjustments.push(...compoundCLTVAdjustments("2 Year Alt Doc", row, LTVs, ranges, twoYearAltDocRule));
                break;
            }
            //No VOR (No Verification of Rent)
            case /\bNo VOR\b/.test(priceAdjustmentsRow): {
                const noVORRule: RulesLogic = {
                    "===": [{ "var": "scenario.noVOR" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("No VOR", row, LTVs, ranges, noVORRule));
                break;
            }
            //Residual Inc < 2500 (min $1500)
            case /\bResidual Inc < 2500 (min $1500)\b/.test(priceAdjustmentsRow): {
                const residualIncomeLess2500Rule: RulesLogic = {
                    "===": [{ "var": "scenario.residualIncomeLess2500" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("Residual Income < 2500", row, LTVs, ranges, residualIncomeLess2500Rule));
                break;
            }
            /*** Covid Forbearance ***/
            //Covid Forbearance < 6 Payments //Covid Forbearance < 6 Pmts
            case /\bCovid Forbearance < 6/.test(priceAdjustmentsRow): {

                const covidForbearanceRule: RulesLogic = {
                    "===": [{ "var": "scenario.covidForbearance" }, true]
                }
                adjustments.push(...compoundLTVAdjustments("Covid Forbearance", row, LTVs, ranges, covidForbearanceRule));
                break;
            }
            //Interest Only // Interest-Only //InterestOnly //interest-only (not follow words) // FOR DSCR 
            case /^Interest\s*\-*\s*Only[\s]*$/i.test(priceAdjustmentsRow): {
                const interestOnlyRule: RulesLogic = {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                }
                adjustments.push(...compoundCLTVAdjustments("Interest Only", row, LTVs, ranges, interestOnlyRule));
                break;

            }
            //Interest-Only 30 YR 
            case /^Interest\s*\-*\s*Only[\s]*30\s*Y?R?\s*$/i.test(priceAdjustmentsRow): {
                const interestOnlyRule: RulesLogic = {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO]]
                }
                adjustments.push(...compoundCLTVAdjustments("Interest Only 30Yr", row, LTVs, ranges, interestOnlyRule));
                break;

            }
            //Interest-Only 40 YR 
            case /^Interest\s*\-*\s*Only[\s]*40\s*Y?R?\s*$/i.test(priceAdjustmentsRow): {
                const interestOnlyRule: RulesLogic = {
                    "in": [{ "var": "product.amortization" }, [Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                }
                adjustments.push(...compoundCLTVAdjustments("Interest Only 40Yr", row, LTVs, ranges, interestOnlyRule));
                break;

            }
            //40 Yr Fixed Full Amor
            case /^40\s+Yr\s*Fixed\s+Full\s*Amor\s*$/i.test(priceAdjustmentsRow): {
                const fortyYearFixedRule: RulesLogic = {
                    "in": [{ "var": "product.amortization" }, [Amortization._40_yr_fixed]]
                }
                adjustments.push(...compoundCLTVAdjustments("40 Yr Fixed Full Amor", row, LTVs, ranges, fortyYearFixedRule));
                break;

            }
            //LOAN AMOUNT ADJUSTMENTS
            case bound.test(row.priceAdjustments.toString()): {
                const loanAmountRule = rangeRuleLoanAmount(prevStr as string, boundStr as string, nextBoundStr as string, { "var": "scenario.baseLoanAmount" });
                adjustments.push(...compoundCLTVAdjustments("Loan Amount x CLTV", row, LTVs, ranges, loanAmountRule));
                break;
            }

            default: return;
        }
    })
    return adjustments;
}