import { atom } from 'jotai'
import Scenario, { LienPosition, LoanPurpose, Occupancy, Amortization, PropertyType, Citizenship, DocumentationType, BankruptcyType, LockDays, Prepay, ProductGroup, RefinancePurposeCashOut, RefinancePurposeRateTerm, DrawTerm } from '../classes/scenario';

export const lienPositionAtom = atom<LienPosition>(LienPosition.first)
export const occupancyAtom = atom<Occupancy>(Occupancy.primary_residence)
export const loanPurposeAtom = atom<LoanPurpose>(LoanPurpose.purchase)
export const refinancePurposeCashOutAtom = atom<RefinancePurposeCashOut>(RefinancePurposeCashOut.cash_out_consolidation)
export const refinancePurposeRateTermAtom = atom<RefinancePurposeRateTerm>(RefinancePurposeRateTerm.non_cash_out)
export const drawTermAtom = atom<DrawTerm>(DrawTerm._24)
export const amortizationsAtom = atom<Amortization[]>(Object.values(Amortization))
export const productsGroupAtom = atom<ProductGroup[]>(Object.values(ProductGroup))
export const propertyValueAtom = atom<number>(1000000);
export const baseLoanAmountAtom = atom<number>(700000);
export const cashOutAmountAtom = atom<number>(0);
export const ltvAtom = atom<number>((get) => Number(((get(baseLoanAmountAtom) / get(propertyValueAtom)) * 100).toFixed(4)));
export const cltvAtom = atom<number>((get) => Number((((get(baseLoanAmountAtom) + get(otherFinancingAtom)) / get(propertyValueAtom)) * 100).toFixed(4)));
export const otherFinancingAtom = atom<number>(0);
export const propertyTypeAtom = atom<PropertyType>(PropertyType.single_family)
export const ruralAtom = atom<boolean>(false)
export const zipCodeAtom = atom<string | undefined>('')
export const waiveEscrowsAtom = atom<boolean>(false)
export const citizenshipAtom = atom<Citizenship>(Citizenship.us_citizen)
export const selfEmployedAtom = atom<boolean>(false)
export const firstTimeHomeBuyerAtom = atom<boolean>(false)
export const documentationTypeAtom = atom<DocumentationType>(DocumentationType.full_doc)
export const debtToIncomeRatioAtom = atom<string | number | undefined>('')
export const debtServCoverageRatioAtom = atom<string | number | undefined>('')
export const creditScoreAtom = atom<number | string | undefined>('')
export const latePaymentsX30Atom = atom<number>(0)
export const latePaymentsX60Atom = atom<number>(0)
export const latePaymentsX90Atom = atom<number>(0)
export const latePaymentsX120Atom = atom<number>(0)
export const fc_ss_dil_mco__months_agoAtom = atom<number>(0)
export const covidForbearanceAtom = atom<boolean>(false)
export const bankruptcyTypeAtom = atom<BankruptcyType>(BankruptcyType.none)
export const termBankruptcyDismissalAtom = atom<number>(0)
export const termBankruptcyDischargeAtom = atom<number>(0)
export const termBankruptcyFilingAtom = atom<number>(0)
export const stateAtom = atom<string>("")
export const lockAtom = atom<LockDays>(LockDays._45)
export const pointsAtom = atom<number>(0)
export const nonWarrantableAtom = atom<boolean>(false);
export const prepayAtom = atom<Prepay>(Prepay._3Year)
export const minPaymentAtom = atom<number | undefined>(undefined)
export const maxPaymentAtom = atom<number | undefined>(undefined)
export const minRateAtom = atom<number | undefined>(undefined)
export const maxRateAtom = atom<number | undefined>(undefined)
export const twoOneBuydownAtom = atom<boolean>(false);
export const delayedFinancingAtom = atom<boolean>(false);
export const _2MonthsBankStatementsAtom = atom<boolean>(false);
export const residualIncomeLess1500Atom = atom<boolean>(false);
export const residualIncomeLess2500Atom = atom<boolean>(false);
export const noVORAtom = atom<boolean>(false);

export const scenarioAtom = atom((get) => {
    const scenario = new Scenario();
    scenario.lienPosition = get(lienPositionAtom);
    scenario.occupancy = get(occupancyAtom);
    scenario.amortizations = get(amortizationsAtom);
    scenario.loanPurpose = scenario.lienPosition === LienPosition.second ? LoanPurpose.refinance_cash_out : get(loanPurposeAtom);
    scenario.refinancePurposeCashOut = get(refinancePurposeCashOutAtom);
    scenario.refinancePurposeRateTerm = get(refinancePurposeRateTermAtom);
    scenario.drawTerm = get(drawTermAtom);
    scenario.propertyValue = get(propertyValueAtom);
    scenario.baseLoanAmount = get(baseLoanAmountAtom);
    scenario.cashOutAmount = get(cashOutAmountAtom);
    ltvAtom;
    scenario.otherFinancing = get(otherFinancingAtom);
    cltvAtom;
    scenario.propertyType = get(propertyTypeAtom);
    scenario.rural = get(ruralAtom);
    scenario.nonWarrantable = get(nonWarrantableAtom);
    scenario.zipCode = get(zipCodeAtom);
    scenario.waiveEscrows = get(waiveEscrowsAtom);
    scenario.citizenship = get(citizenshipAtom);
    scenario.selfEmployed = get(selfEmployedAtom);
    scenario.firstTimeHomeBuyer = get(firstTimeHomeBuyerAtom);
    scenario.documentationType = get(documentationTypeAtom);
    scenario.debtToIncomeRatio = get(debtToIncomeRatioAtom);
    scenario.debtServCoverageRatio = get(debtServCoverageRatioAtom);
    scenario.representativeCreditScore = get(creditScoreAtom);
    scenario.latePaymentsX30 = get(latePaymentsX30Atom);
    scenario.latePaymentsX60 = get(latePaymentsX60Atom);
    scenario.latePaymentsX90 = get(latePaymentsX90Atom);
    scenario.latePaymentsX120 = get(latePaymentsX120Atom);
    scenario.fc_ss_dil_mco__months_ago = get(fc_ss_dil_mco__months_agoAtom);
    scenario.covidForbearance = get(covidForbearanceAtom);
    scenario.bankruptcyType = get(bankruptcyTypeAtom);
    scenario.termBankruptcyDismissal = get(termBankruptcyDismissalAtom);
    scenario.termBankruptcyDischarge = get(termBankruptcyDischargeAtom);
    scenario.termBankruptcyFiling = get(termBankruptcyFilingAtom);
    scenario.state = get(stateAtom);
    scenario.lockDays = get(lockAtom);
    scenario.points = get(pointsAtom);
    scenario.minPayment = get(minPaymentAtom);
    scenario.maxPayment = get(maxPaymentAtom);
    scenario.minRate = get(minRateAtom);
    scenario.maxRate = get(maxRateAtom);
    scenario.prepay = get(prepayAtom);
    scenario.twoOneBuydown = get(twoOneBuydownAtom);
    scenario.delayedFinancing = get(delayedFinancingAtom);
    scenario._2MonthsBankStatements = get(_2MonthsBankStatementsAtom);
    scenario.residualIncomeLess1500 = get(residualIncomeLess1500Atom);
    scenario.residualIncomeLess2500 = get(residualIncomeLess2500Atom);
    scenario.noVOR = get(noVORAtom);

    return scenario;
})

