import { InputGroup, Input, FormControl, FormLabel, FormHelperText, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import { FC, ReactNode } from 'react';

interface Props {
  widthBox?: number | string;
  inputLeftAddon?: ReactNode;
  width?: number | string;
  bg?: string;
  handleOnBlur?(val: string): void;
  handleOnChange?(val: string): void;
  value?: number | string;
  booleanRequired?: boolean;
}

const InputRow: FC<Props> = ({
  widthBox,
  inputLeftAddon,
  width,
  handleOnBlur,
  handleOnChange,
  value,
  booleanRequired,
}) => {
  const isError = value === null || value === undefined || value === '';

  return (
    < FormControl width={widthBox}  >
      <FormLabel color="#0f1162" bg='none'>{inputLeftAddon}  </FormLabel>
      <InputGroup >
        <Input
          borderStartRadius='1'
          onFocus={(event) => event.target.select()}
          color="black"
          value={value}
          onBlur={
            handleOnBlur ? (e) => handleOnBlur(e.target.value) : undefined
          }
          onChange={
            handleOnChange ? (e) => handleOnChange(e.target.value) : undefined
          }
        />
      </InputGroup>
      {isError && booleanRequired ? (
        <FormHelperText color='red'>{inputLeftAddon} is required.</FormHelperText>

      ) : null
      }
    </FormControl >
  );
};

export default InputRow;
