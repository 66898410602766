import { Guideline } from "../../../classes/product";
import { DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";
import zipCodesNQMF from "../zipCodesNQMF.json";

const guidelines: Guideline[] = [
    //************** GENERAL ********//
    //States allowed 
    // Not allowed for second liens NQMF NJ, TN, TX 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, PA, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.pennsylvania, State.virginia]]
        }
    },
    // Lien Position = Second 
    {
        name: "Lien Position Allowed = Second",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.second]
        }
    },
    //Documentation Types Allowed = Full Doc, DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts,  DocumentationType._24M_p_and_l, DocumentationType._24M_1099
    //Asset Utilization non permitted
    {
        name: "Documentation Types Allowed = Full Doc, DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts,  DocumentationType._24M_p_and_l, DocumentationType._24M_1099",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.full_doc, DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_1099]]
        }
    },
    // Self-Employed Bor = True for all documentationType allowed EXCEPT WVOE, Full Doc, 12M Full Doc, DSCR, Asset Utilization
    {
        name: "Self-Employed Bor = True",
        rules: {
            "if": [
                {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._24M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_1099
                        ]
                    ],

                },
                {
                    "===":
                        [
                            { "var": "scenario.selfEmployed" },
                            true
                        ]
                },
                true
            ]

        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home]]
        },
    },
    //Loan Purpose Allowed = Cash-Out Refinance
    //NQMF actually allows Purchase, Refinance No Cash-Out, Refinance Cash-Out but we are offering only cash-out
    {
        name: "Loan Purpose Allowed =  Cash-Out",
        rules: {
            "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse]]
        }
    },
    //Rural properties non permitted 
    {
        name: "Rural Properties Not Allowed",
        rules: {
            "===": [{ "var": "scenario.rural" }, false]
        },
    },
    //Min Credit Score = 680
    {
        name: "Min Credit Score = 680",
        rules: {
            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
        }
    },
    //Max DTI Primary = 50% 
    {
        name: "Max DTI Primary = 50%",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                },
                {
                    "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
                },
                true
            ]
        }
    },
    //Max DTI Secondary = 43% 
    {
        name: "Max DTI Secondary = 43%",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.second_home]
                },
                {
                    "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                },
                true
            ]
        }
    },
    //State Restrictions 
    // IL counties Cook, Kane, Peoria, Will not permitted 
    {
        name: "IL Counties Cook, Kane, Peoria, Will Not Permitted",
        rules: {
            "!==": [
                {
                    "var": "scenario.zipCode"
                },
                [
                    zipCodesNQMF
                ]
            ]
        },
    },

    //************** PROGRAM REQUIREMENTS ********//
    //Minimum residual income 1500 
    {
        name: "Minimum Residual Income = $1,500",
        rules: {
            "===": [{ "var": "scenario.residualIncomeLess1500" }, false]
        },
    },
    //Minimum 2 months business bank statements for P&L and 24 M personal bank statements
    {
        name: "Min 2 months business bank stments for P&L and 24M psnal bank statements",
        rules: {
            "if": [
                {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._24M_p_and_l, DocumentationType._24M_personal_bank_stmts]]
                },
                { "===": [{ "var": "scenario._2MonthsBankStatements" }, true] },
                true
            ]

        },
    },
    // ****LIMITS****
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $ 150,000",
        rules: {

            ">=": [{ "var": "scenario.otherFinancing" }, 150000]
        },
    },
    {
        name: "Maximum Loan Amount = $ 500,000",
        rules: {

            "<=": [{ "var": "scenario.otherFinancing" }, 500000]
        },
    },

    //Mortgage History 0x30x24
    {
        name: "Max Mortgage Lates within last 24 mos = 0x30,0x60,0x90,0x120",
        rules: {
            "and": [
                {
                    "<=": [{ "var": "scenario.latePaymentsX30" }, 0]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                },
                {
                    "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                },

            ]
        },
    },
    //Housing Event and BK Seasoning >= 48 mos
    {
        name: "Housing Event and BK Seasoning >= 48 mos",
        rules: {
            "and": [
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                            },
                        ]
                },
                {

                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                            },
                        ]

                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                        ]
                },
            ],
        },
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN PURPOSE UPPER TABLE && PROPERTY TYPE && OCCUPANCY PRIMARY RESIDENCE*/
    //SFR, PUD, TOWNHOUSE: Max LTV for FICO >= 740 
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.85]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.85]
                            }
                        ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM  && PROPERTY TYPE 
    //SFR, PUD, TOWNHOUSE: Max LTV for 720 <= FICO < 740 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN PURPOSE UPPER TABLE && PROPERTY TYPE INCLUDING WARRANTABLE CONDO*/
    //SFR, PUD, TOWNHOUSE, CONDO: Max LTV for 700 <= FICO <= 720 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "in": [
                                                {
                                                    "var": "scenario.propertyType"
                                                },
                                                [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                            ]

                                        },
                                        {
                                            "and": [
                                                {
                                                    "in":
                                                        [
                                                            PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo
                                                        ]
                                                },
                                                {
                                                    "===":
                                                        [
                                                            { "var": "scenario.nonWarrantable" }, false
                                                        ]
                                                }
                                            ]
                                        }
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],

        },
    },
    //SFR, PUD, TOWNHOUSE, CONDO: Max LTV for 680 <= FICO <= 700 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "in": [
                                                {
                                                    "var": "scenario.propertyType"
                                                },
                                                [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                            ]

                                        },
                                        {
                                            "and": [
                                                {
                                                    "in":
                                                        [
                                                            PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo
                                                        ]
                                                },
                                                {
                                                    "===":
                                                        [
                                                            { "var": "scenario.nonWarrantable" }, false
                                                        ]
                                                }
                                            ]
                                        }
                                    ]

                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN PURPOSE UPPER TABLE && PROPERTY TYPE && OCCUPANCY SECOND HOME AND NON WARRANTABLES CONDOMINIUMS*/
    //SECOND HOME AND NON WARRANTABLES CONDOMINIUMS*/
    //SFR, PUD, TOWNHOUSE: Max LTV for FICO >= 740 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                    ]
                            }
                        ]
                },
                {
                    "and":
                        [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.80]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.80]
                            }
                        ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM  && PROPERTY TYPE 
    //SFR, PUD, TOWNHOUSE: Max LTV for 720 <= FICO < 740 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN PURPOSE UPPER TABLE && PROPERTY TYPE INCLUDING WARRANTABLE CONDO*/
    //SFR, PUD, TOWNHOUSE, CONDO: Max LTV for 700 <= FICO <= 720 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    {
                                                        "in":
                                                            [
                                                                {
                                                                    "var": "scenario.occupancy"
                                                                },
                                                                Occupancy.second_home

                                                            ]
                                                    },
                                                    {
                                                        "in": [
                                                            {
                                                                "var": "scenario.propertyType"
                                                            },
                                                            [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                                        ]
                                                    }
                                                ]
                                        },
                                        {
                                            "and": [
                                                {
                                                    "in":
                                                        [
                                                            PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo
                                                        ]
                                                },
                                                {
                                                    "===":
                                                        [
                                                            { "var": "scenario.nonWarrantable" }, true
                                                        ]
                                                }
                                            ]
                                        }
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],

        },
    },
    //SFR, PUD, TOWNHOUSE, CONDO: Max LTV for 680 <= FICO <= 700 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    {
                                                        "in":
                                                            [
                                                                {
                                                                    "var": "scenario.occupancy"
                                                                },
                                                                Occupancy.second_home

                                                            ]
                                                    },
                                                    {
                                                        "in": [
                                                            {
                                                                "var": "scenario.propertyType"
                                                            },
                                                            [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                                        ]
                                                    }
                                                ]
                                        },
                                        {
                                            "and": [
                                                {
                                                    "in":
                                                        [
                                                            PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo
                                                        ]
                                                },
                                                {
                                                    "===":
                                                        [
                                                            { "var": "scenario.nonWarrantable" }, true
                                                        ]
                                                }
                                            ]
                                        }
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],

        },
    },
    // MAX LOAN AMOUNT WITH EXPOSURE OF 70.01% TO 80% CLTV FOR SFR, PUD, TOWNHOUSE
    //SFR, PUD, TOWNHOUSE: Max Loan Amount for 70 < CLTV <= 80 and FICO >= 720 
    {
        name: "Max Loan Amount = $3,500,000%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">": [{ "var": "scenario.CLTV" }, 0.70]
                                        },
                                        {
                                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                                        }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [{ "var": "scenario.otherFinancing" }, 3500000]
                },
                true
            ],

        },
    },
    // MAX LOAN AMOUNT WITH EXPOSURE OF CLTV < 70 % 
    //SFR, PUD, TOWNHOUSE: Max Loan Amount for CLTV < 70 and FICO >= 720 
    {
        name: "Max Loan Amount = $4,000,000%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse]
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">": [{ "var": "scenario.CLTV" }, 0.70]
                                        },
                                        {
                                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                                        }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [{ "var": "scenario.otherFinancing" }, 4000000]
                },
                true
            ],

        },
    },
    // MAX LOAN AMOUNT WITH EXPOSURE OF 70.01% TO 80% CLTV FOR SFR, PUD, TOWNHOUSE, CONDO 
    //SFR, PUD, TOWNHOUSE, CONDO: Max Loan Amount for 70 < CLTV <= 80 && 680 <= FICO < 720 
    {
        name: "Max Loan Amount = $3,000,000%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">": [{ "var": "scenario.CLTV" }, 0.70]
                                        },
                                        {
                                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                                        }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [{ "var": "scenario.otherFinancing" }, 3000000]
                },
                true
            ],

        },
    },
    // MAX LOAN AMOUNT WITH EXPOSURE OF CLTV < 70 % 
    //SFR, PUD, TOWNHOUSE, CONDO: Max Loan Amount for 70 < CLTV  && 680 <= FICO < 720 
    {
        name: "Max Loan Amount = $3,500,000%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [PropertyType.single_family, PropertyType.pud, PropertyType.townhouse, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">": [{ "var": "scenario.CLTV" }, 0.70]
                                        },
                                        {
                                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                                        }
                                    ]
                            },
                        ]
                },
                {
                    "<=": [{ "var": "scenario.otherFinancing" }, 3500000]
                },
                true
            ],

        },
    },
]

export default guidelines; 