import { Flex, Modal, ModalHeader, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button, useToken, ModalOverlay, Box, Stack, HStack, Text, Center } from '@chakra-ui/react'
import Product, { Cost } from '../../classes/product';
import Scenario, { LockDays } from '../../classes/scenario';
import { useMemo, useState } from 'react';
import TableDetails from '../product_details/TableDetails';
import { useAtom } from 'jotai';
import { lockAtom, pointsAtom } from '../../services/scenarioService';
import React from 'react';


export interface Props {
    product: Product;
    scenario: Scenario;
    isOpen: boolean;
    onClose: () => void;
}
const ProductDetailModal = ({ onClose, isOpen, product, scenario }: Props) => {
    const results = useMemo(() => {
        const scenariosForLockDays = Object.values(LockDays).map((lockDays) => {
            const withLockDays = Object.assign(new Scenario(), scenario);
            withLockDays.lockDays = lockDays;
            return withLockDays;
        });
        return scenariosForLockDays.map((scenario) => ({ scenario, result: product.getResult(scenario) }));
    }, [product, scenario]);

    const maxRowResult = useMemo(() => {
        return results.reduce((prev, item) => {
            if (item.result.costTable.length > prev.result.costTable.length) return item
            return prev
        });
    }, [results])

    const [pointsTable, setPoints] = useAtom(pointsAtom)
    const [lockDaysTable, setLockDays] = useAtom(lockAtom)
    const [couponTable, setCoupon] = useState(0)


    const handlePointsCell = (objectPoints: Cost, indexLockDays: number) => {
        if (indexLockDays === 0) setLockDays(LockDays._45)
        else if (indexLockDays === 1) setLockDays(LockDays._60)
        setPoints(Number(objectPoints.costPoints));
        setCoupon(objectPoints.coupon);
    }

    return <>
        <Modal isOpen={isOpen} onClose={onClose} isCentered blockScrollOnMount={false}  >

            <ModalContent maxW={900}>
                <ModalHeader >

                    <Center>
                        Details Rate: {product.name}
                    </Center>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody width={900}>
                    <Flex justify={'space-evenly'}>

                        <Box bg='white' p={4} borderWidth='1px' borderRadius='lg' minWidth={300} marginRight={4}>

                            <TableDetails results={results} maxRowResult={maxRowResult} handlePointsCell={handlePointsCell} />

                        </Box>
                        <Box>
                            <Box bg='white' p={4} color='white' borderWidth='1px' borderRadius='lg' marginBottom={'1em'}>

                                <Box bg='#053742' p={2} color='white' borderWidth='1px' borderRadius='sm'>
                                    <Text>Base Rate</Text>

                                </Box>
                                <Box color='black' p={2} fontWeight={'bolder'} >
                                    <Flex>
                                        <Text color={'#f54f64'} marginRight={'2'}>Base Rate: </Text>
                                        <Text color={'teal.500'}>  {couponTable || product.getCost(scenario)?.coupon.toFixed(3)}%</Text>
                                    </Flex>
                                </Box>


                            </Box>
                            <Box bg='white' p={4} color='white' borderWidth='1px' borderRadius='lg' marginBottom={'1em'}>

                                <Box bg='#053742' p={2} color='white' borderWidth='1px' borderRadius='sm'>
                                    <Text>Price Adjustments</Text>
                                </Box>
                                <Box p={2} color='black' fontWeight={'bold'} >
                                    {product.explainRate(scenario).map((item, i) => (
                                        <div key={i}>
                                            <Text>{item}</Text>
                                        </div>

                                    ))}
                                    {<Flex>
                                        <Text color={'#f54f64'} marginRight={'2'}>Net Points: </Text> <Text color={'teal.500'}> {pointsTable || product.getCost(scenario)?.costPoints} </Text>
                                    </Flex>
                                    }

                                </Box>


                            </Box>
                        </Box>

                    </Flex>



                </ModalBody>
            </ModalContent>
        </Modal >
    </>

}
export default ProductDetailModal;