import { WorkBook } from "xlsx";
import Product from "../../classes/product";

import parseSecondLienDeephaven from "./standAlone2ndLien/parse2ndLien";


export default function parseDeephavenSecondLien(workbook: WorkBook): Product[] {
    const equityAdvantageSheet = workbook.Sheets["Equity Advantage"];


    return [
        ...parseSecondLienDeephaven(equityAdvantageSheet),
    ]
}