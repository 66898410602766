import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import adjustmentsLock, { RowLock, headerLock } from "../sharedDeephavenAdjustments/adjustmentsLock";
import adjustmentsOthers, { RowOthers } from '../sharedDeephavenAdjustments/adjustmentsOther'
import { adjustmentsPrepayWithoutCompunding, headerPrepay } from "../sharedDeephavenAdjustments/adjustmentsPrepayWOCompounding";

const ltvs = [0, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80]
//the upper bound is not included in the range ex: 0.55 - 0.60 means 0.55 <= x < 0.60
const ranges = ["0 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 1.00"]

const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "R20:Y38",
        header,
        defval: "",
    })
    return adjustmentsOthers(priceAdjustmentsTable, ltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "T40:U42",
        header: headerLock,
        defval: "",
    })

    const prepayTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "O40:Q46",
        header: headerPrepay,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsPrepayWithoutCompunding(prepayTable),
    ];
}


export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}