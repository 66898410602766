import { Amortization, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Min credit score = 660 for loan amount <= 1,000,000
    {
        name: "Min Credit Score x loanAmount= 660",
        rules: {
            "if": [
                {
                    "<=": [
                        { "var": "scenario.baseLoanAmount" }, 1000000
                    ]
                },
                {
                    ">=": [
                        { "var": "scenario.representativeCreditScore" }, 660
                    ]
                },
                true
            ]

        }
    },
    // Min credit score = 680 for loan amount > 1,000,000 && <= 2,000,000
    {
        name: "Min Credit Score x loanAmount= 680",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">": [
                                { "var": "scenario.baseLoanAmount" }, 1000000
                            ]
                        },
                        {
                            "<=": [
                                { "var": "scenario.baseLoanAmount" }, 2000000
                            ]
                        }
                    ]
                },
                {
                    ">=": [
                        { "var": "scenario.representativeCreditScore" }, 680
                    ]
                },
                true
            ]
        }
    },
    /********* INTEREST ONLY */
    //Max LTV/CLTV for interest only
    {
        name: "Max LTV/CLTV for interest only = 75%",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }
                    ],
                },
                true
            ]

        }
    },
    // Min DSCR for interest only = 1.00
    {
        name: "Min DSCR for interest only = 1.00",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ]
                },
                true
            ]

        },
    },
    //******** INVESTOR EXPERIENCE */
    // First Time Home Buyer Allowed = No
    {
        name: "First Time Home Buyer Allowed = No",
        rules: {
            "===": [{ "var": "scenario.firstTimeHomeBuyer" }, false]
        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    //Maximum loan amount 
    {
        name: "Maximum Loan Amount = $2,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 2500000]
        }
    },
    //Minimum loan amount if DSCR < 1.0 = $250,000
    {
        name: "Minimum Loan Amount if DSCR < 1.0 = $250,000",
        rules: {
            "if": [
                {
                    "<":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            250000
                        ]
                },
                true
            ]
        }
    },
    //Maximum cash-out 
    {
        name: "Maximum Cash-Out = $500,000",
        rules: {
            "<=": [{ "var": "scenario.cashOutAmount" }, 500000]
        }
    },
    // //Mortgage History
    {
        name: "Max Mortgage Lates within last 12 mos = 0x30,0x60,0x90,0x120",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //Minimum Months Since BK/FC/SS/DIL  = 36 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 36",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                        ],


                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                            },
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                        ]
                },
            ]
        }
    },
    // ****LTV/CLTV MAX FOR PROPERTY TYPE****
    //Max LTV/CLTV for Non-Warrantable Property
    {
        name: "Max LTV/CLTV for Non-Warrantable property = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.nonWarrantable"
                                },
                                true
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],
        }
    },
    //************** INCOME ********//
    // Documentation Type
    {
        name: "Doc Types Allowed = DSCR",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.dscr]]
        }
    },

    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Condotel",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in": [
                { "var": "scenario.citizenship" },
                [
                    Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                ],
            ]
        }
    },
    //Min DSCR = 0.75
    {
        name: "Min DSCR = 0.75",
        rules: {
            "if": [
                { "<": [{ "var": "scenario.debtServCoverageRatio" }, 1.00] },
                { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] },
                true
            ]
        }
    },
    // DSCR < 1 Purchase only 
    {
        name: "DSCR < 1 Purchase only",
        rules: {
            "if": [
                {
                    "<":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"


                            },
                            1
                        ],
                },
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.purchase
                        ]
                },
                true
            ]
        }
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT && DSCR UPPER TABLE*/

    //DSCR >=1 &&  LOAN PURPOSE = (PURCHASE || RATE TERM)  && LOAN AMOUNT <= 1,000,000 

    //Max LTV/CLTV for baseLoan <= 1,500,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //(DSCR >=1) &&  LOAN PURPOSE = (PURCHASE || RATE TERM)  &&  (1,500,000 < LOAN AMOUNT <= 2,000,000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ],


                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //(DSCR >=1) &&  LOAN PURPOSE = (PURCHASE || RATE TERM)  &&  (2,000,000 < LOAN AMOUNT <= 2,500,000)
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ],


                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1 &&  LOAN PURPOSE = (CASH-OUT)  && LOAN AMOUNT <= 1,500,000 

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 740 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //(DSCR >=1) &&  LOAN PURPOSE = (PURCHASE || RATE TERM)  &&  (1,500,000 < LOAN AMOUNT <= 2,000,000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ],


                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  680 <= FICO < 700 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //(DSCR >=1) &&  LOAN PURPOSE = (PURCHASE || RATE TERM)  &&  (2,000,000 < LOAN AMOUNT <= 2,500,000)
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 700 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ],


                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  680 <= FICO < 700 && (Loan Purpose = Cash-Out) && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    // 0.75<= DSCR < 1 &&  LOAN PURPOSE = (PURCHASE)  && LOAN AMOUNT <= 1,500,000 

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && (Loan Purpose = Purchase) && 0.75 < DSCR < 1.00
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                    }
                                ]

                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 740 && (Loan Purpose = Purchase) && DSCR < 1.00
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ],
                                    }
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },


    //(DSCR < 1) &&  LOAN PURPOSE = (PURCHASE)  &&  (1,500,000 < LOAN AMOUNT <= 2,000,000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && (Loan Purpose = Purchase) && DSCR < 1.00
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ],


                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                    }
                                ]

                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ],

                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //**************LIMITS TABLE UPPERTABLE */
    // Min Credit Score for DSCR < 1 
    {
        name: "Min Credit Score for DSCR < 1 = 700",
        rules: {
            "if": [
                {
                    "<":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ],
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]
        }
    },
    // Min Credit Score for DSCR >= 1 and Loan Amount <= 1,500,000
    {
        name: "Min Credit Score for DSCR >= 1 = 660 and Loan Amount <= 1,500,000",
        rules: {
            "if": [
                {

                    "and": [
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ],
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.baseLoanAmount"
                                    },
                                    1500000
                                ]
                        }
                    ]

                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            660
                        ]
                },
                true
            ]
        }
    },
    // Min Credit Score for DSCR >= 1 and Loan Amount > 1,500,000
    {
        name: "Min Credit Score for DSCR >= 1 = 680 and Loan Amount > 1,500,000",
        rules: {
            "if": [
                {

                    "and": [
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ],
                        },
                        {
                            ">":
                                [
                                    {
                                        "var": "scenario.baseLoanAmount"
                                    },
                                    1500000
                                ]
                        }
                    ]

                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                true
            ]
        }
    },
    //Only purchase for DSCR < 1
    {
        name: "Only Purchase for DSCR < 1",
        rules: {
            "if": [
                {
                    "<":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ],
                },
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.purchase
                        ]
                },
                true
            ]
        }
    },
    //Minimum DSCR = 0.75 
    {
        name: "Min DSCR = 0.75",
        rules:
        {
            ">=": [
                {
                    "var": "scenario.debtServCoverageRatio"
                },
                0.75
            ]
        }

    }
];
