import FinancingStructureComponent from '../structures/FinancingStructure';
import PropertyInformationStructureComponent from '../structures/PropertyInformation';
import ProductFeaturesComponent from '../structures/ProductFeatures';
import BorrowerInformationComponent from '../structures/BorrowerInformation';

import { Box, Stack, Image, SimpleGrid, Container, Button, Link } from '@chakra-ui/react';
import Logo from '../screens/Logo';
import Footer from '../footer/footer';
import React from 'react';

const ScenarioScreen = () => {
  return (
    <Stack >
      <Box as="header" backgroundColor="#0f1162"
        w="100%" h={"60px"}>
        <Link href='https://newpointmortgage.com/' isExternal>
          <Logo />
        </Link>
      </Box>

      <SimpleGrid minChildWidth='497px'>

        <FinancingStructureComponent />
        <Box>
          <PropertyInformationStructureComponent />
          <ProductFeaturesComponent />
        </Box>
        <BorrowerInformationComponent />

      </SimpleGrid>
      <Box h={'60px'}> </Box>
      <Footer />
    </Stack >
  );
};
export default ScenarioScreen;
