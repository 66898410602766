import { Adjustment } from '../../../../classes/product';
import { compoundCLTVAdjustments, rangeRuleLoanAmount } from '../../utilsSummit/ruleUtils';
import { RulesLogic } from 'json-logic-js'
import { Citizenship, DocumentationType, DrawTerm, LoanPurpose, Occupancy, PropertyType, State } from "../../../../classes/scenario";

export type RowOthers = { [key: string]: string | number };

export default function adjustmentsOthers(othersTable: RowOthers[], CLTVs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];

    othersTable.forEach((row, index, othersTable) => {
        const priceAdjustmentsRow = row.priceAdjustments.toString();

        switch (true) {
            /********* DRAW TERM */
            case /\b24\b/.test(priceAdjustmentsRow): {
                const drawTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.drawTerm" }, DrawTerm._24]
                }
                adjustments.push(...compoundCLTVAdjustments("Draw Term 24", row, CLTVs, ranges, drawTermRule))
                break;
            }
            case /\b36\b/.test(priceAdjustmentsRow): {
                const drawTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.drawTerm" }, DrawTerm._36]
                }
                adjustments.push(...compoundCLTVAdjustments("Draw Term 36", row, CLTVs, ranges, drawTermRule))
                break;
            }
            case /\b60\b/.test(priceAdjustmentsRow): {
                const drawTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.drawTerm" }, DrawTerm._60]
                }
                adjustments.push(...compoundCLTVAdjustments("Draw Term 60", row, CLTVs, ranges, drawTermRule))
                break;
            }
            /****HOUSING HISTORY ADJUSTMENTS****/
            //1x30x12
            case /\b1x30x12\b/.test(priceAdjustmentsRow): {
                // If there is no delay in the payment history, or there is only one of 1x30x12
                const _1x30x12HousingHistoryRule: RulesLogic = {
                    "===": [{ "var": "scenario.latePaymentsX30" }, 1]
                }
                adjustments.push(...compoundCLTVAdjustments("Housing History: 1x30x12", row, CLTVs, ranges, _1x30x12HousingHistoryRule))
                break;
            }
            //0x60x12
            case /\b0x60x12\b/.test(priceAdjustmentsRow): {
                // If there is delay in the payment history, at least two of 1x30x12. That is 2x30x12 or 3x30x12
                // Then you use the rate for 0x60x12
                const _0x60x12HousingHistoryRule: RulesLogic = {
                    ">": [{ "var": "scenario.latePaymentsX30" }, 1]
                }
                adjustments.push(...compoundCLTVAdjustments("Housing History: 0x60x12", row, CLTVs, ranges, _0x60x12HousingHistoryRule))
                break;
            }
            //0x90x12
            case /\b0x90x12\b/.test(priceAdjustmentsRow): {
                // If there is delay in the payment history, >= 1x60x12. That is 1x60x12 or 2x60x12 or 3x60x12
                // Then you use the rate for 0x90x12
                const _0x90x12HousingHistoryRule: RulesLogic = {
                    ">=": [{ "var": "scenario.latePaymentsX60" }, 1]
                }
                adjustments.push(...compoundCLTVAdjustments("Housing History: 0x90x12", row, CLTVs, ranges, _0x90x12HousingHistoryRule))
                break;
            }
            /***HOUSING EVENT SEASONING ADJUSTMENTS***/
            //>=36 Mo
            case /^>=\s*36\s*Mo$/.test(priceAdjustmentsRow): {
                const housingEventSeasoningRule: RulesLogic = {
                    ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36]
                }
                adjustments.push(...compoundCLTVAdjustments("Housing Event Seasoning >= 36", row, CLTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //24 - 35 Mo
            case /^\s*24\s*-\s*35\s*Mo\s*$/.test(priceAdjustmentsRow): {

                const housingEventSeasoningRule: RulesLogic = {
                    "and":
                        [
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                            { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 35] }
                        ]

                }
                adjustments.push(...compoundCLTVAdjustments("Housing Event Seasoning >= 24 && Housing Event Seasoning <=35", row, CLTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //12 - 23 Mo
            case /^\s*12\s*-\s*23\s*Mo\s*$/.test(priceAdjustmentsRow): {
                const housingEventSeasoningRule: RulesLogic = {
                    "and":
                        [
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 12] },
                            { "<=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 23] }
                        ]
                }
                adjustments.push(...compoundCLTVAdjustments("Housing Event Seasoning >= 12 && Housing Event Seasoning <= 23", row, CLTVs, ranges, housingEventSeasoningRule))
                break;
            }

            /***DTI ADJUSTMENTS***/
            //<= 40%
            case /^<=\s*40\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncomeLessThan40Rule: RulesLogic = {
                    "<=": [
                        { "var": "scenario.debtToIncomeRatio" }, 40
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("DTI <= 40", row, CLTVs, ranges, debtToIncomeLessThan40Rule));
                break;
            }
            //>43% // > 43%
            case /^\s*>\s*43\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncomeMoreThan43Rule: RulesLogic = {
                    ">": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                }
                adjustments.push(...compoundCLTVAdjustments("DTI > 43", row, CLTVs, ranges, debtToIncomeMoreThan43Rule));
                break;
            }
            //36.01%-43%
            case /^36\.01\s*-\s*43\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncome36To43To50Rule: RulesLogic = {
                    "and": [
                        { ">": [{ "var": "scenario.debtToIncomeRatio" }, 36] },
                        { "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("36 < DTI <= 43", row, CLTVs, ranges, debtToIncome36To43To50Rule));
                break;
            }
            //40.01 - 45%
            case /^40\.01\s*-\s*45\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncome40_45Rule: RulesLogic = {
                    "and": [
                        { ">": [{ "var": "scenario.debtToIncomeRatio" }, 40] },
                        { "<=": [{ "var": "scenario.debtToIncomeRatio" }, 45] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("40 < DTI <= 45", row, CLTVs, ranges, debtToIncome40_45Rule));
                break;
            }
            //43.01%-50%
            case /^43\.01\s*-\s*50\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncome43To50Rule: RulesLogic = {
                    "and": [
                        { ">": [{ "var": "scenario.debtToIncomeRatio" }, 43] },
                        { "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("43 < DTI <= 50", row, CLTVs, ranges, debtToIncome43To50Rule));
                break;
            }
            //45.01 - 50%
            case /^45\.01\s*-\s*50\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncome45_50Rule: RulesLogic = {
                    "and": [
                        { ">": [{ "var": "scenario.debtToIncomeRatio" }, 45] },
                        { "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("45 < DTI <= 50", row, CLTVs, ranges, debtToIncome45_50Rule));
                break;
            }
            //>50%
            case /\s*>\s*50\s*%$/.test(priceAdjustmentsRow): {
                const debtToIncomeMore50Rule: RulesLogic = {
                    ">": [{ "var": "scenario.debtToIncomeRatio" }, 50]
                }
                adjustments.push(...compoundCLTVAdjustments("DTI > 50", row, CLTVs, ranges, debtToIncomeMore50Rule));
                break;
            }
            /***PURPOSE ADJUSTMENTS***/
            //Asset Utilization
            case /\bAsset Utilization\b/.test(priceAdjustmentsRow): {
                const assetUtilizationRule: RulesLogic = {
                    "===": [{ "var": "scenario.documentationType" }, DocumentationType.asset_utilization]
                }
                adjustments.push(...compoundCLTVAdjustments("Asset Utilization", row, CLTVs, ranges, assetUtilizationRule))
                break;
            }
            //Purchase
            case /\bPurchase\b/.test(priceAdjustmentsRow): {
                const purchaseRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase]
                }
                adjustments.push(...compoundCLTVAdjustments("Purchase", row, CLTVs, ranges, purchaseRule))
                break;
            }
            //R/T Refi
            case /\bR\/T Refi\b/.test(priceAdjustmentsRow): {
                const rateTermRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_no_cash_out]
                }
                adjustments.push(...compoundCLTVAdjustments("R/T Refi", row, CLTVs, ranges, rateTermRule))
                break;
            }
            //Cash-Out Refi
            case /^[\s]*Cash-Out[\s]*Refi$/.test(row.priceAdjustments.toString()): {
                const cashOutRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out", row, CLTVs, ranges, cashOutRule));
                break;
            }
            //Cash-Out Refi & FICO>=700
            case /\bCash-Out Refi \& FICO\s*(>=|≥)\s*700\b/.test(priceAdjustmentsRow): {
                const cashOutAndFicoRule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { ">=": [{ "var": "scenario.representativeCreditScore" }, 700] },
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                    ],
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out Refi & FICO>=700", row, CLTVs, ranges, cashOutAndFicoRule));
                break;
            }
            //Cash-Out Refi & FICO<700
            case /^\bCash-Out Refi \& FICO\s*<\s*700\b$/.test(priceAdjustmentsRow): {
                const cashOutAndFicoRule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { "<": [{ "var": "scenario.representativeCreditScore" }, 700] },
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                    ],
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out Refi & FICO<700", row, CLTVs, ranges, cashOutAndFicoRule));
                break;
            }
            //Cash-Out Refi & FICO<700 or Foreign Credit
            case /\bCash-Out Refi \& FICO\s*<\s*700\s*or\s*Foreign\s*Credit\b/.test(priceAdjustmentsRow): {
                const cashOutAndFicoRule: RulesLogic = {
                    "or": [
                        {
                            "and": [
                                { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                                { "<": [{ "var": "scenario.representativeCreditScore" }, 700] },
                            ],
                        },
                        {
                            "and": [
                                { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                                { "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                            ],
                        }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out Refi & FICO<700 or Foreign Credit", row, CLTVs, ranges, cashOutAndFicoRule));
                break;
            }
            //Cash-Out Refi & DSCR>=1.0
            case /\bCash-Out Refi \& DSCR\s*(>=|≥)\s*1.0\b/.test(priceAdjustmentsRow): {
                const cashOutAndDSCRRule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out Refi & DSCR>=1.0", row, CLTVs, ranges, cashOutAndDSCRRule));
                break;
            }
            //Cash-Out Refi & DSCR<1.0
            case /\bCash-Out Refi\s*\&\s*DSCR\s*<\s*1.0\b/.test(priceAdjustmentsRow): {
                const cashOutAndDSCRRule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { "<": [{ "var": "scenario.debtServCoverageRatio" }, 1] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out Refi & DSCR<1.0", row, CLTVs, ranges, cashOutAndDSCRRule));
                break;
            }
            /***OCCUPANCY ADJUSTMENTS***/
            //2nd Home
            case /\b2nd Home\b/.test(priceAdjustmentsRow): {
                const secondHomeRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.second_home]
                }
                adjustments.push(...compoundCLTVAdjustments("Second Home", row, CLTVs, ranges, secondHomeRule));
                break;
            }
            //Investor
            case /\bInvestor\b/.test(priceAdjustmentsRow): {
                const investorRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                }
                adjustments.push(...compoundCLTVAdjustments("Investor", row, CLTVs, ranges, investorRule));
                break;
            }
            /***PROPERTY TYPE ADJUSTMENTS***/
            //Condo
            case /\bCondo\b/i.test(priceAdjustmentsRow): {
                const condoRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                }
                adjustments.push(...compoundCLTVAdjustments("Condo", row, CLTVs, ranges, condoRule));
                break;
            }
            //Condotel
            case /\bCondotel\b/.test(priceAdjustmentsRow): {
                const condoRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.condotel]]
                }
                adjustments.push(...compoundCLTVAdjustments("Condo", row, CLTVs, ranges, condoRule));
                break;
            }
            //2-4 Unit
            case /\b2\s*-\s*4\s+Units?\b/i.test(priceAdjustmentsRow): {
                const _2_to_4_unitsRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
                }
                adjustments.push(...compoundCLTVAdjustments("2-4 Units", row, CLTVs, ranges, _2_to_4_unitsRule));
                break;
            }
            //2-8 Mixed Use
            case /^2\s*-\s*8\s*Mixed\s*Use$/i.test(priceAdjustmentsRow): {
                const _2_8MixedUseRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.mixed_use_2_to_8_units]]
                }
                adjustments.push(...compoundCLTVAdjustments("2-8 Mixed Use", row, CLTVs, ranges, _2_8MixedUseRule));
                break;
            }
            //5-8 Residential Units
            case /^5\s*-\s*8\s*Residential\s*Units$/.test(priceAdjustmentsRow): {
                const _5_8ResidentialUnitsRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.residential_units]]
                }
                adjustments.push(...compoundCLTVAdjustments("5-8 Residential Units", row, CLTVs, ranges, _5_8ResidentialUnitsRule));
                break;
            }
            /***OTHER ADJUSTMENTS***/
            //Non-Warrantable
            case /\bNon-Warrantable\b/.test(priceAdjustmentsRow): {
                const nonWarrantableRule: RulesLogic = {
                    "===": [{ "var": "scenario.nonWarrantable" }, true]
                }
                adjustments.push(...compoundCLTVAdjustments("Non-Warrantable", row, CLTVs, ranges, nonWarrantableRule));
                break;
            }
            //Escrow Waiver
            case /\bEscrow Waiver\b/.test(priceAdjustmentsRow): {
                const waiveEscrowsRule: RulesLogic = {
                    "===": [{ "var": "scenario.waiveEscrows" }, true]
                }
                adjustments.push(...compoundCLTVAdjustments("Escrow Waiver", row, CLTVs, ranges, waiveEscrowsRule));
                break;
            }
            /***CITIZENSHIP ADJUSTMENTS***/
            //ITIN
            case /\bITIN\b/.test(priceAdjustmentsRow): {
                const ITINRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                }
                adjustments.push(...compoundCLTVAdjustments("ITIN", row, CLTVs, ranges, ITINRule));
                break;
            }
            //Foreign National
            case /\bForeign National\b/.test(priceAdjustmentsRow): {
                const FNRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                }
                adjustments.push(...compoundCLTVAdjustments("Foreign National", row, CLTVs, ranges, FNRule));
                break;
            }
            //Foreign Credit
            case /\bForeign Credit\b/.test(priceAdjustmentsRow): {
                const foreignNationalRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                }
                adjustments.push(...compoundCLTVAdjustments("Foreign Credit", row, CLTVs, ranges, foreignNationalRule));
                break;
            }
            /***STATE ADJUSTMENTS***/
            //CT, IL, NJ, NY
            case /\bCT, IL, NJ, NY\b/.test(priceAdjustmentsRow): {
                const StateRule: RulesLogic = {
                    "in": [{ "var": "scenario.state" }, [State.connecticut, State.illinois, State.new_jersey, State.new_york]]
                }
                adjustments.push(...compoundCLTVAdjustments("State CT, IL, NJ, NY", row, CLTVs, ranges, StateRule));
                break;
            }
            //FL
            case /\bFL\b/.test(priceAdjustmentsRow): {
                const StateRule: RulesLogic = {
                    "===": [{ "var": "scenario.state" }, State.florida]
                }
                adjustments.push(...compoundCLTVAdjustments("State FL", row, CLTVs, ranges, StateRule));
                break;
            }
            /***SELF EMPLOYED ADJUSTMENTS***/
            //Self-Employed
            case /^\s*Self\s*-\s*Employed\s*$/.test(priceAdjustmentsRow): {
                const SelfEmployedRule: RulesLogic = {
                    "===": [{ "var": "scenario.selfEmployed" }, true]
                }
                adjustments.push(...compoundCLTVAdjustments("State", row, CLTVs, ranges, SelfEmployedRule));
                break;
            }
            /***LOAN AMOUNT ADJUSTMENTS***/
            case /(?<=\$)\w+/.test(priceAdjustmentsRow): {
                const boundStr = row.priceAdjustments;
                const loanAmountRule = rangeRuleLoanAmount(boundStr as string, { "var": "scenario.otherFinancing" });
                adjustments.push(...compoundCLTVAdjustments("Loan Amount x CLTV", row, CLTVs, ranges, loanAmountRule));
                break;
            }


            default: return;
        }
    })
    return adjustments;
}