import React from 'react'

import { Table, Thead, Th, Tr, Tbody, Td, } from '@chakra-ui/react'
import { Cost } from '../../../src/classes/product'
import { Result } from '../../../src/classes/product'
import Scenario from '../../../src/classes/scenario'


const TableDetails = ({ results, maxRowResult, handlePointsCell }: { results: { scenario: Scenario; result: Result; }[], maxRowResult: { scenario: Scenario; result: Result; }, handlePointsCell: Function }) => {

    const handleOnClickCell = (pointsObject: Cost, indexLock: number) => {
        handlePointsCell(pointsObject, indexLock)
    }
    return (

        <Table size={'sm'} variant='striped' >
            <Thead>
                <Tr>
                    <Th>Rate</Th>
                    {results.map(({ scenario }: { scenario: Scenario }) => (<Th key={scenario.lockDays}>{scenario.lockDays}</Th>))}
                </Tr>
            </Thead>
            <Tbody>
                {maxRowResult.result.costTable.map((cost: Cost, i) => (
                    <Tr key={i}>
                        <Td key={i + "coupon"}>{cost.coupon.toFixed(3)} %</Td>
                        {
                            results.map(({ result }: { result: Result }, j) => (

                                i < result.costTable.length &&
                                    result.costTable[i]?.costPoints === result.cost?.costPoints
                                    ? <Td key={j} fontWeight={'bold'} _hover={{
                                        color: "pink",
                                    }}
                                        onClick={() => handleOnClickCell(result.costTable[i], j)}
                                    >{result.costTable[i]?.costPoints.toFixed(3)}</Td>
                                    : <Td key={j}
                                        _hover={{
                                            color: "pink",
                                            fontWeight: 'bolder',
                                            fontSize: '0.99em',
                                        }}
                                        onClick={() => handleOnClickCell(result.costTable[i], j)}
                                    >{result.costTable[i]?.costPoints.toFixed(3)}</Td>
                            )
                            )
                        }
                    </Tr>
                ))}
            </Tbody>
        </Table>

    )
}

export default TableDetails;