import * as XLSX from "xlsx";
import { Adjustment } from '../../../../classes/product';
import { DocumentationType } from "../../../../classes/scenario";
import adjustmentsFicoxCLTV from "../../sharedVerusAdjustments/firstLien/adjustmentsFicoxCLTV"
import { adjustmentsLock, adjustmentsPrepay } from "../../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import adjustmentsOthers, { RowOthers } from "../../sharedVerusAdjustments/firstLien/adjustmentsOther";

const cltvs = [0, 0.50, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 1.00"]


//prepay adjustments are inside the parseOtherAdjustments function for DSCR, DSCR Multi and DSCR FN 
const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B46:J77",
        header,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "adjustment"];
    type RowLock = { [key: typeof headerLock[number]]: string };
    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B78:C81",
        header: headerLock,
        defval: "",
    })


    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


const parseFICOxCLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", "", ...ranges.map(range => range)];

    /* *************** DSCR DOC ********** */
    //DSCR doc 
    const arrayOfDocumentationTypeDSCRDoc = [DocumentationType.dscr]
    const DSCRDocMonths = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "B38:J45",
        header,
        defval: "",
    })




    return [
        ...adjustmentsFicoxCLTV(DSCRDocMonths, arrayOfDocumentationTypeDSCRDoc, cltvs, ranges),

    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxCLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}