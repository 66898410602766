export enum LienPosition {
    first = "First",
    second = "Second",
}

export enum DrawTerm {
    _24 = "2 year",
    _36 = "3 year",
    _60 = "5 year",
}

export enum Occupancy {
    primary_residence = "Primary Residence",
    second_home = "Second Home",
    investment_property = "Investment Property",
}

export enum LoanPurpose {
    purchase = "Purchase",
    refinance_no_cash_out = "No Cash Out Refinance",
    refinance_cash_out = "Cash Out Refinance",
    debt_consolidation = "Debt Consolidation",
}

export enum RefinancePurposeCashOut {
    cash_out_other = 'Cash-Out/Other',
    cash_out_improvment = 'Cash-Out/Home Improvement',
    cash_out_consolidation = 'Cash-Out/Debt Consolidation',
}

export enum RefinancePurposeRateTerm {
    non_cash_out = 'No Cash-Out',
    limited_cash_out = 'Limited Cash-Out',
}
export enum Amortization {
    _15_yr_fixed = "15 Year Fixed",
    _30_yr_fixed = "30 Year Fixed",
    _40_yr_fixed = "40 Year Fixed",
    _30_yr_fixed_arm_5_6 = "5/6 ARM",
    _30_yr_fixed_IO = "30 Year Fixed IO",
    _30_yr_fixed_arm_5_6_IO = "5/6 ARM IO (30 Year)",
    _40_yr_fixed_IO = "40 Year Fixed IO",
    _40_arm_5_6_IO = "5/6 ARM IO (40 Year)",
}

export enum ProductGroup {
    _summit = "Summit",
    _deephaven = "Everest",
    _deephaven_5_9 = "Everest 5-9 Unit",
    _deephaven_second_lien = "Everest Second Lien",
    _NQMF = "NQMF",
}

export const interestOnlyAmortizations = [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO];

export enum PropertyType {
    single_family = "Single Family",
    townhouse = "Townhouse",
    _2_unit = "2 Unit",
    _3_unit = "3 unit",
    _4_unit = "4 Unit",
    low_rise_condo = "Low Rise Condo",
    mid_rise_condo = "Mid Rise Condo",
    high_rise_condo = "High Rise Condo",
    detached_condo = "Detached Condo",
    condotel = "Condotel",
    pud = "PUD",
    mixed_use_2_to_8_units = "Mixed Use 2 to 8 Units",
    residential_units = "Residential Units",
}

// mixed used 2 to 8 units (verus and deephaven)
// residential units (no minimum residential units they look at sqf)

export enum Citizenship {
    us_citizen = "US Citizen",
    permanent_resident_alien = "Permanent Resident Alien",
    non_permanent_resident_alien = "Non-Permanent Resident Alien",
    itin = "ITIN",
    foreign_national = "Foreign National",
}

export enum DocumentationType {
    full_doc = "Full Doc",
    _12M_full_doc = "12M Full Doc",
    _12M_personal_bank_stmts = "12M Personal Bank Stmts",
    _24M_personal_bank_stmts = "24M Personal Bank Stmts",
    _12M_business_bank_stmts = "12M Business Bank Stmts",
    _24M_business_bank_stmts = "24M Business Bank Stmts",
    asset_utilization = "Asset Utilization",
    wvoe = "WVOE",
    dscr = "DSCR",
    _12M_1099 = "12M 1099",
    _24M_1099 = "24M 1099",
    _12M_p_and_l = "12M P&L",
    _24M_p_and_l = "24M P&L",
}

export enum LatePaymentDays {
    _30 = "30 Days",
    _60 = "60 Days",
    _90 = "90 Days",
    _120 = "120 Days",
}

export enum BankruptcyType {
    none = "None",
    chapter_7 = "Chapter 7",
    chapter_11 = "Chapter 11",
    chapter_13 = "Chapter 13",
}

export enum State {
    alabama = "AL",
    alaska = "AK",
    arizona = "AZ",
    arkansas = "AR",
    california = "CA",
    colorado = "CO",
    connecticut = "CT",
    delaware = "DE",
    florida = "FL",
    georgia = "GA",
    hawaii = "HI",
    idaho = "ID",
    illinois = "IL",
    indiana = "IN",
    iowa = "IA",
    kansas = "KS",
    kentucky = "KY",
    louisiana = "LA",
    maine = "ME",
    maryland = "MD",
    massachusetts = "MA",
    michigan = "MI",
    minnesota = "MN",
    mississippi = "MS",
    missouri = "MO",
    montana = "MT",
    nebraska = "NE",
    nevada = "NV",
    new_hampshire = "NH",
    new_jersey = "NJ",
    new_mexico = "NM",
    new_york = "NY",
    north_carolina = "NC",
    north_dakota = "ND",
    ohio = "OH",
    oklahoma = "OK",
    oregon = "OR",
    pennsylvania = "PA",
    rhode_island = "RI",
    south_carolina = "SC",
    south_dakota = "SD",
    tennessee = "TN",
    texas = "TX",
    utah = "UT",
    vermont = "VT",
    virginia = "VA",
    washington = "WA",
    west_virginia = "WV",
    wisconsin = "WI",
    wyoming = "WY",

}

export enum LockDays {
    _45 = "45 Days",
    _60 = "60 Days",
}

export enum Prepay {
    _5Year = "5 Year",
    _4Year = "4 Year",
    _3Year = "3 Year",
    _2Year = "2 Year",
    _1Year = "1 Year",
    _noPrepay = "No Prepay",
}

export enum SortBy {
    payment = "Payment",
    rate = "Rate",
    alphabetical = "Alphabetical",
}
export default class Scenario {
    lienPosition: LienPosition | undefined;
    occupancy: Occupancy | undefined;
    loanPurpose: LoanPurpose | undefined;
    refinancePurposeCashOut: RefinancePurposeCashOut | undefined;
    refinancePurposeRateTerm: RefinancePurposeRateTerm | undefined;
    amortizations: Amortization[] | undefined;
    propertyValue: number | undefined;
    baseLoanAmount: number | undefined;
    otherFinancing: number | undefined;
    cashOutAmount: number | undefined;
    covidForbearance: boolean | undefined;
    drawTerm: DrawTerm | undefined;


    get LTV(): number {

        if (this.baseLoanAmount !== undefined && this.propertyValue !== undefined) {
            return Number((this.baseLoanAmount / this.propertyValue).toFixed(4));
        }
        return 0;
    }

    get CLTV(): number {
        if (this.baseLoanAmount !== undefined && this.otherFinancing !== undefined && this.propertyValue !== undefined) {
            return Number(((this.baseLoanAmount + this.otherFinancing) / this.propertyValue).toFixed(4));
        }
        return 0;
    }
    propertyType: PropertyType | undefined;
    rural: boolean | undefined;
    nonWarrantable: boolean | undefined;
    zipCode: string | number | undefined;
    state: string | undefined;
    waiveEscrows: boolean | undefined;
    citizenship: Citizenship | undefined;
    selfEmployed: boolean | undefined;
    firstTimeHomeBuyer: boolean | undefined;
    documentationType: DocumentationType | undefined;
    debtToIncomeRatio: string | number | undefined;
    debtServCoverageRatio: string | number | undefined;
    representativeCreditScore: string | number | undefined;
    latePaymentsX30: number | undefined;
    latePaymentsX60: number | undefined;
    latePaymentsX90: number | undefined;
    latePaymentsX120: number | undefined;
    fc_ss_dil_mco__months_ago: number | undefined
    bankruptcyType: BankruptcyType | undefined;
    termBankruptcyDismissal: number | undefined;
    termBankruptcyDischarge: number | undefined;
    termBankruptcyFiling: number | undefined;
    lockDays: LockDays | undefined;
    points: number | undefined;
    product: string | undefined;
    prepay: Prepay | undefined;
    minPayment: number | undefined;
    maxPayment: number | undefined;
    minRate: number | undefined;
    maxRate: number | undefined;
    twoOneBuydown: boolean | undefined;
    delayedFinancing: boolean | undefined;
    _2MonthsBankStatements: boolean | undefined;
    residualIncomeLess2500: boolean | undefined;
    residualIncomeLess1500: boolean | undefined;
    noVOR: boolean | undefined;
}