import React from 'react';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Box,
} from '@chakra-ui/react';

const MonthsAgoRow = ({
  inputLeftAddon,
  inputRightAddon,
  setTerm,
  term,
}: {
  inputLeftAddon: React.ReactNode;
  inputRightAddon: React.ReactNode;
  setTerm: (value: number) => void;
  term: number;
}) => {
  return (
    <InputGroup>
      <Box display={'flex'}>
        <InputLeftAddon
          children={inputLeftAddon}
          color="#0f1162" bg='none'
          width={'15rem'}
          border='none'
          padding={0}
        />
        <Input
          color="black"
          onChange={(e) => setTerm(Number(e.target.value))}
          value={term}
        />
        <InputRightAddon children={inputRightAddon} width="8rem"
          color="#0f1162"
          fontSize={'4xs'}
          fontWeight={'bold'}
          bg='none'
          border='none'
        />

      </Box>
    </InputGroup>
  );
};

export default MonthsAgoRow;
