import { Adjustment } from '../../../classes/product';
import { compoundCLTVAdjustments, rangeRuleLoanAmount } from '../utilsDeephaven/ruleUtils';
import { RulesLogic } from 'json-logic-js'
import { Amortization, Citizenship, DocumentationType, LoanPurpose, Occupancy, PropertyType } from "../../../classes/scenario";

export type RowOthers = { [key: string]: string | number };

export default function adjustmentsOthers(othersTable: RowOthers[], LTVs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];
    othersTable.forEach((row, index, othersTable) => {
        const priceAdjustmentsRow = row.priceAdjustments.toString();

        const bound = /^((>|<)\s?)\w+/;
        const boundStr = row.priceAdjustments.toString().match(bound)?.input ? row.priceAdjustments.toString().match(bound)?.input : undefined;

        const prev = boundStr ? othersTable[index - 1]?.priceAdjustments : undefined;
        const prevStr = prev?.toString().match(bound)?.input ? prev?.toString().match(bound)?.input : undefined;


        const nextBound = othersTable[index + 1]?.priceAdjustments;
        const nextBoundStr = nextBound?.toString().match(bound)?.input ? nextBound?.toString().match(bound)?.input : undefined;


        switch (true) {
            /*****CITIZENSHIP*******/
            //Foreign National
            case /\bForeign National\b/.test(priceAdjustmentsRow): {
                const FNRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                }
                adjustments.push(...compoundCLTVAdjustments("Foreign National", row, LTVs, ranges, FNRule));
                break;
            }
            //Asset Utilization
            case /\bAsset Utilization\b/.test(priceAdjustmentsRow): {
                const assetUtilizationRule: RulesLogic = {
                    "===": [{ "var": "scenario.documentationType" }, DocumentationType.asset_utilization]
                }
                adjustments.push(...compoundCLTVAdjustments("Asset Utilization", row, LTVs, ranges, assetUtilizationRule))
                break;
            }
            //Cash-Out
            case /^[\s]*Cash-Out[\s]*$/.test(priceAdjustmentsRow): {

                const cashOutRule: RulesLogic = {
                    "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out", row, LTVs, ranges, cashOutRule));
                break;
            }
            //Cash-Out | FICO >= 720
            case /\bCash-Out \| FICO (>=|≥) 720\b/.test(priceAdjustmentsRow): {
                const cashOut720Rule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { ">=": [{ "var": "scenario.representativeCreditScore" }, 720] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out | FICO ≥ 720", row, LTVs, ranges, cashOut720Rule));
                break;

            }
            //Cash-Out | FICO < 720
            case /\bCash-Out \| FICO < 720\b/.test(priceAdjustmentsRow): {
                const cashOut720Rule: RulesLogic = {
                    "and": [
                        { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out] },
                        { "<": [{ "var": "scenario.representativeCreditScore" }, 720] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("Cash-Out | FICO < 720", row, LTVs, ranges, cashOut720Rule));
                break;
            }
            //Second Home
            case /\bSecond Home\b/.test(priceAdjustmentsRow): {
                const secondHomeRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.second_home]
                }
                adjustments.push(...compoundCLTVAdjustments("Second Home", row, LTVs, ranges, secondHomeRule));
                break;
            }
            //Investor
            case /\bInvestor\b/.test(priceAdjustmentsRow): {
                const investorRule: RulesLogic = {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property]
                }
                adjustments.push(...compoundCLTVAdjustments("Investor", row, LTVs, ranges, investorRule));
                break;
            }
            //2-4 Units
            case /\b2\s*-\s*4\s+Units\b/.test(priceAdjustmentsRow): {
                const _2_to_4_unitsRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
                }
                adjustments.push(...compoundCLTVAdjustments("2-4 Units", row, LTVs, ranges, _2_to_4_unitsRule));
                break;
            }
            //Condo
            case /\bCondo\b/.test(priceAdjustmentsRow): {
                const condoRule: RulesLogic = {
                    "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType.condotel]]
                }
                adjustments.push(...compoundCLTVAdjustments("Condo", row, LTVs, ranges, condoRule));
                break;
            }
            //Non-Warrantable
            case /\bNon-Warrantable\b/.test(priceAdjustmentsRow): {
                const nonWarrantableRule: RulesLogic = {
                    "===": [{ "var": "scenario.nonWarrantable" }, true]
                }
                adjustments.push(...compoundCLTVAdjustments("Non-Warrantable", row, LTVs, ranges, nonWarrantableRule));
                break;
            }
            //DTI > 43
            case /\bDTI\s*>\s*43\b/.test(priceAdjustmentsRow): {
                const debtToIncomeRule: RulesLogic = {
                    ">": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                }
                adjustments.push(...compoundCLTVAdjustments("DTI > 43", row, LTVs, ranges, debtToIncomeRule));
                break;
            }
            //Escrow Waiver
            case /\bEscrow Waiver\b/.test(priceAdjustmentsRow): {
                const waiveEscrowsRule: RulesLogic = {
                    "===": [{ "var": "scenario.waiveEscrows" }, true]
                }
                adjustments.push(...compoundCLTVAdjustments("Escrow Waiver", row, LTVs, ranges, waiveEscrowsRule));
                break;
            }
            case /\bITIN\b/.test(priceAdjustmentsRow): {
                const ITINRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.itin]
                }
                adjustments.push(...compoundCLTVAdjustments("ITIN", row, LTVs, ranges, ITINRule));
                break;
            }
            //DSCR ≥ 1.25
            case /\bDSCR\s*(>=|≥)\s*1\.25\b/.test(priceAdjustmentsRow): {
                const DSCR125Rule: RulesLogic = {
                    ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.25]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR > 1.25", row, LTVs, ranges, DSCR125Rule));
                break;
            }
            //DSCR 1.15 - 1.24
            case /\bDSCR\s*1\.15\s*-\s*1\.24\b/.test(priceAdjustmentsRow): {
                const DSCR115_124Rule: RulesLogic = {
                    "and": [
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.15] },
                        { "<=": [{ "var": "scenario.debtServCoverageRatio" }, 1.24] },
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR 1.15 - 1.24", row, LTVs, ranges, DSCR115_124Rule));
                break;
            }
            //DSCR < 1.00
            case /\bDSCR\s*<\s*1\.00\b/.test(priceAdjustmentsRow): {
                const DSCR1Rule: RulesLogic = {
                    "<": [{ "var": "scenario.debtServCoverageRatio" }, 1]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR < 1", row, LTVs, ranges, DSCR1Rule));
                break;
            }
            //DSCR ≥ 1.40
            case /\bDSCR\s*(>=|≥)\s*1\.40\b/.test(priceAdjustmentsRow): {
                const DSCR140Rule: RulesLogic = {
                    ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.40]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR ≥ 1.40", row, LTVs, ranges, DSCR140Rule));
                break;
            }
            //DSCR < 1.40
            case /\bDSCR\s*<\s*1\.40\b/.test(priceAdjustmentsRow): {
                const DSCR140Rule: RulesLogic = {
                    "<": [{ "var": "scenario.debtServCoverageRatio" }, 1.40]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR < 1.40", row, LTVs, ranges, DSCR140Rule));
                break;
            }
            //Recent Event
            //HOUSING SEASONING EVENT 
            case /\bRecent Event\b/.test(priceAdjustmentsRow): {
                const housingEventSeasoningRule: RulesLogic = {
                    "or": [
                        {
                            ">": [
                                { "var": "scenario.latePaymentsX60" },
                                0
                            ]
                        },
                        {
                            ">": [
                                { "var": "scenario.latePaymentsX90" },
                                0
                            ]
                        },
                        {
                            "===": [
                                { "var": "scenario.latePaymentsX120" },
                                1
                            ]
                        },
                        {
                            "and": [
                                { ">": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                            ]
                        }
                    ]


                }
                adjustments.push(...compoundCLTVAdjustments("Housing Event Seasoning", row, LTVs, ranges, housingEventSeasoningRule))
                break;
            }
            //Interest Only // Interest-Only //InterestOnly
            case /\bInterest\s*\-*\s*Only\b/i.test(priceAdjustmentsRow): {
                const interestOnlyRule: RulesLogic = {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                }
                adjustments.push(...compoundCLTVAdjustments("Interest Only", row, LTVs, ranges, interestOnlyRule));
                break;

            }
            //LOAN AMOUNT ADJUSTMENTS
            case bound.test(priceAdjustmentsRow): {
                const loanAmountRule = rangeRuleLoanAmount(prevStr as string, boundStr as string, nextBoundStr as string, { "var": "scenario.baseLoanAmount" });
                adjustments.push(...compoundCLTVAdjustments("Loan Amount x CLTV", row, LTVs, ranges, loanAmountRule));
                break;
            }

            default: return;
        }
    })
    return adjustments;
}