import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsFicoxCLTV from "../sharedDeephavenAdjustments/adjustmentsFicoxCLTV"
import adjustmentsLock, { RowLock, headerLock } from "../sharedDeephavenAdjustments/adjustmentsLock";
import adjustmentsOthers, { RowOthers } from "../sharedDeephavenAdjustments/adjustmentsOther";
import { adjustmentsPrepayWithoutCompunding, headerPrepay } from "../sharedDeephavenAdjustments/adjustmentsPrepayWOCompounding";

const ltvs = [0, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90]
const ranges = ["0 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.90", "0.90 - 1.00"]


const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "K29:S47",
        header,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, ltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "N50:O51",
        header: headerLock,
        defval: "",
    })

    const prepayTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "I49:K55",
        header: headerPrepay,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsPrepayWithoutCompunding(prepayTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", "", ...ranges.map(range => range)];

    //full doc
    const arrayOfDocumentationType_fullDoc = [DocumentationType.full_doc, DocumentationType._12M_full_doc]
    const fullDoc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "K13:S18",
        header,
        defval: "",
    })

    //12M and 24M bank stmts
    const arrayOfDocumentationType_1224_bankStmts = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts]
    const _1224BankStmtsDoc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "K19:S24",
        header,
        defval: "",
    })


    //12M P&L 
    const arrayOfDocumentationType_PL = [DocumentationType._12M_p_and_l]
    const _1year_PL_Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "K19:S25",
        header,
        defval: "",
    })

    //1099
    const arrayOfDocumentationType_1099 = [DocumentationType._12M_1099, DocumentationType._24M_1099]

    const _1099Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "K19:S24",
        header,
        defval: "",
    })

    const _1099_2part_Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "K26:S26",
        header,
        defval: "",
    })

    return [
        ...adjustmentsFicoxCLTV(fullDoc, arrayOfDocumentationType_fullDoc, ltvs, ranges),
        ...adjustmentsFicoxCLTV(_1224BankStmtsDoc, arrayOfDocumentationType_1224_bankStmts, ltvs, ranges),
        ...adjustmentsFicoxCLTV(_1year_PL_Doc, arrayOfDocumentationType_PL, ltvs, ranges),
        ...adjustmentsFicoxCLTV(_1099Doc, arrayOfDocumentationType_1099, ltvs, ranges),
        ...adjustmentsFicoxCLTV(_1099_2part_Doc, arrayOfDocumentationType_1099, ltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}