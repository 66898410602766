import { Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position
    {
        name: "Lien Position Allowed = First Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Documentation Type
    {
        name: "Documentation Type Allowed = DSCR",
        rules: {
            "===": [{ "var": "scenario.documentationType" }, DocumentationType.dscr]
        }
    },
    //************** CITIZENSHIP ********//
    //Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien, FN",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien, Citizenship.foreign_national
                    ]
                ]
        }
    },

    //************** GENERAL REQUIREMENTS ********//
    //Minimum Credit Score = 700
    {
        name: "Minimum Credit Score = 700",
        rules: {
            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
        }
    },
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $400,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 400000]
        }
    },
    {
        name: "Maximum Loan Amount = $2,000,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 2000000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "===":
                [
                    { "var": "scenario.occupancy" },
                    Occupancy.investment_property
                ]
        }
    },
    //Max cash-in-hand = $ 1,000,000
    {
        name: "Max Cash-in-hand = $1,000,000",
        rules: {
            "<=": [{ "var": "scenario.cashOutAmount" }, 1000000]
        }
    },
    //Property Types Allowed 
    {
        name: "Property Types Allowed = Mixed Use 2-8 Units",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.mixed_use_2_to_8_units]]
        }
    },

    // ************ DSCR*************// 
    // DSCR >= 1
    {
        name: "Min DSCR = 1",
        rules: {
            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1]
        }
    },
    //HOUSING HISTORY RULES
    //Mortgage History - There are no latePayments of 30 accepted 
    {
        name: "Max Mortgage Lates within last 12 mos = 0x30x12,0x60x12,0x90x12,0x120x12",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //HOUSING EVENT SEASONING RESTRICTIONS
    //BK/FC/SS/DIL History MIN MONTHS = 36 of ANY EVENT 


    /* NOTE  
    //FC/SS/DIL = 36M
    //Bankruptcy Discharge- Ch7 && Ch11 = 36M 
    //Bankruptcy Dismissal - Ch7 && Ch11 = 36M 
    //Bankruptcy Filling Ch13 = 36M )
    */

    //Minimum Months Since BK/FC/SS/DIL = 36 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 36",
        rules: {
            "and": [
                {
                    "or": [
                        { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                        { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] }
                    ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                        ]
                },
            ],
        }
    },


    //*******  LOAN PURPOSE = PURCHASE && MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    //FOR FICO >= 700 
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 && Loan Purpose = Purchase 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        },

                    ],

                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && Loan Purpose = Purchase 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },

                    ],

                },
                true
            ],
        },
    },
    //******* (LOAN PURPOSE = R/T REFINANCE && CASH-OUT) = MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    // FOR FICO >= 700

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance 
    //ONLY FOR R/T SINCE IT'S DIFFERENT FOR CASH-OUT
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },

                    ],
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 700 &&  Loan Purpose = Cash-Out Refinance 
    //ONLY FOR CASH-OUT SINCE IT'S DIFFERENT FOR R/T
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out]

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ],
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ],
                },
                true
            ],

        },
    },
    //************** LIMITS TABLE ********//
    // Max LoanAmount FOR FICO >= 700  = 2,000,000 
    {
        name: "Max LoanAmount = 2,000,000",
        rules: {
            "<=":
                [
                    {
                        "var": "scenario.baseLoanAmount",

                    },
                    2000000
                ]
        },
    },
    /************** GUIDELINES RESTRICTIONS  */
    /* Guideline restrictions for Non Permanent Residents 2.3.1.3 NON-PERMANENT RESIDENT ALIEN (2/26/2024)
     o Standard or Alt Documentation limited to 24-months only. Maximum LTV/CLTV limited to 80%. 
     o DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.
     o Non-occupant co-borrowers are not allowed.
     o Gift funds are not allowed.*/

    {
        name: "Standard or Alt Documentation limited to 24-months only",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.citizenship"
                            },
                            Citizenship.non_permanent_resident_alien
                        ]
                },
                {
                    "in":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            [DocumentationType.full_doc, DocumentationType.asset_utilization, DocumentationType._24M_1099, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType.dscr]
                        ]
                },
                true
            ]
        },
    },
    {
        name: "Maximum LTV/CLTV limited to 80% for Non Permanent Residents",
        rules: {
            "if":
                [
                    {
                        "===":
                            [
                                {
                                    "var": "scenario.citizenship"
                                },
                                Citizenship.non_permanent_resident_alien
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]

                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    {
        name: "DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.citizenship"
                                        },
                                        Citizenship.non_permanent_resident_alien
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.dscr
                                    ]
                            }
                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        },
    },
    /** 
     * 2.3.4 FIRST TIME HOMEBUYERS (FTHB) (2/26/2024)
     * Primary residence only.
     • DTI may not exceed 45%. ( I am ignoring this one for now)
     • Minimum six (6) months of reserves.
     • 12-month rental history, reflecting 0x30, documented per Section 2.5.5 - Housing History.
     o First time homebuyers with less than 12-month rental history:
     ▪ DTI may not exceed 43%.
     ▪ LTV may not exceed 80%.
     ▪ Any available portion of a 12-month housing history must be paid as agreed.
 */
    {
        name: "First Time Home Buyer Primary residence only.",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                    },
                    true
                ]

        },
    },
    {
        name: "DTI FTHB = 43%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {
        name: "Max LTV/CLTV for FTHB = 80%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {

        name: "Max Mortgage Lates within last 12 mos for FTHB= 0x30,0x60,0x90,0x120",
        rules:
        {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]

                    },
                    true
                ]

        },
    }



]; // end of rules





