import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline, MaxPrice } from '../../../classes/product';
import { Amortization, LoanPurpose, Occupancy, Prepay } from "../../../classes/scenario";
import guidelines from "./guidelinesDSCR";
import parseAdjustments from "./adjustmentsDSCR";

type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}

const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const basePricingTableAllDocs = (XLSX.utils.sheet_to_json(sheet, {
        range: "A8:F36",
        header: ["coupon", Amortization._30_yr_fixed_arm_5_6, '', '', '', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[]);


    const withIO = basePricingTableAllDocs.map(row => {
        // Add IO amortizations
        row[Amortization._40_arm_5_6_IO] = row[Amortization._30_yr_fixed_arm_5_6];
        row[Amortization._40_yr_fixed_IO] = row[Amortization._30_yr_fixed];
        return row
    })
    return withIO.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: true,
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});
}

function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                },

            ],
            adjustments: []
        },
        [Amortization._40_yr_fixed_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_yr_fixed_IO}`,
                    rules: {
                        "in": [Amortization._40_yr_fixed_IO, { "var": "scenario.amortizations" }]
                    }
                },
                // Min Credit Score for interest only 
                {
                    name: "Min Credit Score for interest only = 680",
                    rules: {
                        ">=": [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                    },
                },
                // Max LTV/CLTV for interest only purchase and R/T = 75% 
                {
                    name: "Max LTV/CLTV for interest only purchase and R/T = 75%",
                    rules: {
                        "if": [
                            {
                                "in":
                                    [
                                        { "var": "scenario.loanPurpose" },
                                        [
                                            LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        "<=": [
                                            { "var": "scenario.LTV" },
                                            0.75
                                        ]
                                    },
                                    {
                                        "<=": [
                                            { "var": "scenario.CLTV" },
                                            0.75
                                        ]
                                    },
                                ]
                            },
                            true
                        ]
                    },

                },
                // Max LTV/CLTV for interest only cash out = 70%
                {
                    name: "Max LTV/CLTV for interest only cash out = 70%",
                    rules: {
                        "if": [
                            {
                                "===":
                                    [
                                        { "var": "scenario.loanPurpose" },
                                        LoanPurpose.refinance_cash_out
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        "<=": [
                                            { "var": "scenario.LTV" },
                                            0.70
                                        ]
                                    },
                                    {
                                        "<=": [
                                            { "var": "scenario.CLTV" },
                                            0.70
                                        ]
                                    },
                                ]
                            },
                            true
                        ]
                    },
                },
            ],
            adjustments: [],
        },
        [Amortization._30_yr_fixed_arm_5_6]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed_arm_5_6}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed_arm_5_6, { "var": "scenario.amortizations" }]
                    }
                },
            ],
            adjustments: [],
        },
        [Amortization._40_arm_5_6_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_arm_5_6_IO}`,
                    rules: {
                        "in": [Amortization._40_arm_5_6_IO, { "var": "scenario.amortizations" }]
                    }
                },
            ],
            adjustments: [],
        },
    }
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "J3:J4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });


    return `${effectiveDate[0]["effectiveDate"]} ${effectiveDate[1]["effectiveDate"]}`;
}

const parsePriceMaximumsPrepay = (coverSheet: XLSX.WorkSheet): MaxPrice[] => {
    const range = "H19:K24";
    const priceMaximums = XLSX.utils.sheet_to_json(coverSheet, {
        range,
        header: ["term", "", "LLPA", "maxPx"],
        rawNumbers: false,
    });

    function getPrepay(term: string): Prepay | undefined {
        switch (term) {
            case "No Penalty": return Prepay._noPrepay;
            case "No Prepay": return Prepay._noPrepay;
            case "12 Months": return Prepay._1Year;
            case "24 Months": return Prepay._2Year;
            case "36 Months": return Prepay._3Year;
            case "48 Months": return Prepay._4Year;
            case "60 Months": return Prepay._5Year;
        }
    }

    return priceMaximums.map((row: any) => ({
        rules: {
            "and": [
                {
                    "===": [{ "var": "scenario.prepay" }, getPrepay(row.term)],

                },
                {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                }

            ]

        },
        value: row.maxPx,
    }))
}


export default function parseSummitPrime(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const priceMaximums = parsePriceMaximumsPrepay(sheet);
    const program = "Summit DSCR"

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            priceMaximums,
            effectiveDate
        );
    });
}


///SUMMIT DSCR