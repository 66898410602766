import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline, MaxPrice } from '../../../classes/product';
import { Amortization } from "../../../classes/scenario";
import guidelines from "./guidelinesNQMF2ndLien";
import parseAdjustments from "./adjustmentsNQMF2ndLien";


// Using a mapped type to extend the RowData definition
type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}


//No ARM for Denali Second Lien 
//No 40 year fixed for Denali Second Lien
const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const range = "B7:C51";
    const basePricingTable = (XLSX.utils.sheet_to_json(sheet, {
        range,
        header: ["coupon", Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[]).map(row => {
        // Add IO amortizations
        row[Amortization._40_yr_fixed_IO] = row[Amortization._30_yr_fixed];
        return row
    })
    return basePricingTable.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: true,
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});
}

function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
    }
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "C4:C4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return effectiveDate[0]["effectiveDate"];
}


export default function parseNQMF2ndLien(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    // const priceMaximums = parsePriceMaximumsPrepay(sheet);
    const program = "Denali Stand Alone 2nd Lien"

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization], // Explicitly specify the type of basePricing
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            [],
            effectiveDate,
        );
    });
}