import { NumberInput, NumberInputField } from '@chakra-ui/react';
import { pointsAtom } from '../../services/scenarioService';
import { useAtom } from 'jotai';
import React from 'react';

const PointsFacet = () => {
  const [valuePoints, setValuePoints] = useAtom(pointsAtom);
  return (
    <NumberInput
      defaultValue={0}
      precision={3}
      step={0.3}
      onChange={(value) => setValuePoints(value as unknown as number)}
      value={valuePoints}
    >
      <NumberInputField color="black" bg="#fff" fontWeight="medium" />
    </NumberInput>
  );
};

export default PointsFacet;
