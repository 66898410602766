import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { adjustmentsLock, adjustmentsPrepay } from "../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import adjustmentsOthers, { RowOthers } from "../sharedVerusAdjustments/firstLien/adjustmentsOther";
import adjustmentsDSCRRatio from "../sharedVerusAdjustments/firstLien/adjustmentsDSCRRatio";

const cltvs = [0, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 1.00"]


export const parseDSCRAditionalAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const dscrAditionalAdjTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B43:I48",
        header: ["dscrAditionalAdjustments", '', ...ranges.map(range => range)],
        defval: "",
    })

    return adjustmentsDSCRRatio(dscrAditionalAdjTable, cltvs, ranges);
}

//prepay adjustments are inside the parseOtherAdjustments function for DSCR, DSCR Multi and DSCR FN 
const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B44:I80",
        header,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "adjustment"];
    type RowLock = { [key: typeof headerLock[number]]: string };
    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B80:C84",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseDSCRAditionalAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}