import { Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";
export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA,CO,  FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Second Position
    {
        name: "Lien Position Allowed = Second Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.second]
        }
    },
    //Citizensship Allowed = US Citizen, Permanent Resident Alien
    {
        name: "Citizenship Allowed = US Citizen, Permanent Resident Alien",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.us_citizen, Citizenship.permanent_resident_alien
                    ]
                ]
        }
    },
    // Only cash-out ref 
    {
        name: "Only Cash-Out Refinance",
        rules: {
            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
        }
    },
    //DocumentationType Allowed = Full Doc 2 Years
    {
        name: "DocumentationType Allowed = Full Doc 2 Years",
        rules: {
            "in":
                [
                    { "var": "scenario.documentationType" },
                    [
                        DocumentationType.full_doc
                    ]

                ]
        },
    },
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $ 150,000",
        rules: {
            ">=": [{ "var": "scenario.otherFinancing" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $500,000",
        rules: {
            "<=": [{ "var": "scenario.otherFinancing" }, 500000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Primary residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed (the guidelines are confusing but townhouse it's part of Attached so it has to be included)
    {
        name: "Property Types Allowed = Single Family, PUD, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo,  Townhouse, 2-4 Units",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
        }
    },
    //Max CLTV for 2-4 Units = 80% 
    {
        name: "Max CLTV for 2-4 Units = 80%",
        rules: {
            "if": [
                {
                    "in":
                        [
                            {
                                "var": "scenario.propertyType"
                            },
                            [
                                PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit
                            ]
                        ]
                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                true
            ]
        }
    },
    //Max CLTV for Warrantable Condo = 80% 
    {
        name: "Max CLTV for Warrantable Condo = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.propertyType"
                                        },
                                        [
                                            PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.nonWarrantable"
                                        },
                                        false
                                    ]
                            }

                        ]

                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                true
            ]
        }
    },
    //Max Cash-Out $500,000
    {
        name: "Max Cash-Out $500,000",
        rules: {
            "<=": [{ "var": "scenario.otherFinancing" }, 500000]
        }
    },
    //PROPERTY TYPES RULES 
    //Warrantable condo allowed
    {
        name: "Non-Warrantable Condo not eligible",
        rules: {
            "===": [{ "var": "scenario.nonWarrantable" }, false]

        }
    },
    //STATE RESTRICTIONS
    // Texas not eligible 
    {
        name: "Texas not eligible",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.state"
                            },
                            State.texas
                        ]
                },
                false,
                true
            ]
        },
    },
    // Min FICO for CT, IL, NJ, NY = 720 
    {
        name: "Minimum Credit Score x state = 720",
        rules: {
            "if": [
                {
                    "in":
                        [
                            {
                                "var": "scenario.state"
                            },
                            [
                                State.connecticut, State.illinois, State.new_jersey, State.new_york
                            ]
                        ]
                },
                {
                    ">=":

                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            720
                        ]
                },
                true
            ]
        }
    },
    // Max CLTV for CT, IL, NJ, NY = 80% 
    {
        name: "Max CLTV x state = 80%",
        rules: {
            "if": [
                {
                    "in":
                        [
                            {
                                "var": "scenario.state"
                            },
                            [
                                State.connecticut, State.illinois, State.new_jersey, State.new_york
                            ]
                        ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                true
            ]
        }
    },
    // MIN FICO SCORE
    {
        name: "Minimum Credit Score = 680",
        rules: {
            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
        }
    },
    //Mortage History 
    {
        name: "Mortgage History = 0x30,0x60,0x90,0x120",
        rules:
        {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        },
    },
    //Seasoning History
    //Minimum Months Since BK/FC/SS/DIL = 84 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 84",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 84] },
                        ],


                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 84] },
                        ],
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 84] },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 84] },
                        ]
                },
            ]

        }
    },
    //Max DTI for CLTV <= 80% = 50%
    {
        name: "Max DTI x scenario = 50%",
        rules: {
            "if": [
                {
                    "<=":
                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.debtToIncomeRatio"
                            },
                            50
                        ]
                },
                true
            ]
        }
    },
    //Max DTI for CLTV > 80% = 45%
    {
        name: "Max DTI x scenario = 45%",
        rules: {
            "if": [
                {
                    ">":
                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.debtToIncomeRatio"
                            },
                            45
                        ]
                },
                true
            ]
        }
    },
    //******* OCCUPANCY = PRIMARY RESIDENCE && STANDARD DOC */
    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.90
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.85
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 680 <= FICO < 700 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.85
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.85
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 680 <= FICO < 700 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //******* OCCUPANCY = SECONDARY RESIDENCE && STANDARD DOC */

    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 680 <= FICO < 700 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 680 <= FICO < 700 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //******* OCCUPANCY = INVESTMENT PROPERTY && STANDARD DOC */

    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType.full_doc, DocumentationType._12M_full_doc
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },
    //******* OCCUPANCY = PRIMARY RESIDENCE && ALT DOC */

    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.85
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 680 <= FICO < 700 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 680 <= FICO < 700 && Occupancy = Primary Residence
    {
        name: "Max CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //******* OCCUPANCY = SECONDARY RESIDENCE && ALT DOC */

    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 680 <= FICO < 700 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 680 <= FICO < 700 && Occupancy = Second Home
    {
        name: "Max CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.second_home

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.60
                    ]
                },
                true
            ],

        },
    },
    //******* OCCUPANCY = INVESTMENT PROPERTY && ALT DOC */

    //Max CLTV for otherFinancing <= 350,000 && FICO >= 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for otherFinancing <= 350,000 && 700 <= FICO < 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.60
                    ]
                },
                true
            ],

        },
    },

    //Max CLTV for 350,000 < otherFinancing <= 500,000 && FICO >= 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },
    //Max CLTV for 350,000 < otherFinancing <= 500,000 && 700 <= FICO < 740 && Occupancy = Investment Property
    {
        name: "Max CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        350000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                740
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.documentationType"
                                    },
                                    [
                                        DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                                    ]
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.60
                    ]
                },
                true
            ],

        },
    },
    // **************** LIMITS FOR ALL DOCS && INVESTMENT PROPERTY *****************
    // Min FICO = 700 
    {
        name: "Min Credit Score x investment = 700",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.investment_property

                                    ]
                            },
                        ]
                },
                {
                    ">=": [
                        {
                            "var": "scenario.representativeCreditScore"
                        },
                        700
                    ]
                },
                true
            ],

        },
    },
]; // end of rules