import { Stack, Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import InputRow from '../shared/InputRow';
import OptionsRow from '../shared/OptionsRow';
import CheckBoxRow from '../shared/CheckboxRow';
import { PropertyType, State } from '../../classes/scenario';
import { useAtom } from 'jotai';
import {
  propertyTypeAtom,
  ruralAtom,
  stateAtom,
  zipCodeAtom,
  nonWarrantableAtom,
} from '../../services/scenarioService';
import MapService from '../../services/azureMapsService';
import { BsHouseFill } from 'react-icons/bs';
import React from 'react';

const PropertyInformationStructureComponent = () => {
  const mapService = new MapService();

  const [propertyType, setPropertyType] = useAtom(propertyTypeAtom);
  const [state, setState] = useAtom(stateAtom);
  const [rural, setRural] = useAtom(ruralAtom);
  const [nonWarrantable, setNonWarrantable] = useAtom(nonWarrantableAtom);
  const [zipCode, setZipCode] = useAtom(zipCodeAtom);

  const [county, setCounty] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const handleOnZipCodeChange = async (zip: string) => {
    setZipCode(zip);

    const address = await mapService.getLocation(zip);
    if (address !== undefined && address !== null) {
      let countrySubdivision = address.countrySubdivision as string;
      let countrySecondarySubdivision = address.countrySecondarySubdivision as string;
      let postalName = address.postalName as string;
      setState(countrySubdivision);
      setCounty(countrySecondarySubdivision);
      setCity(postalName);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && zipCode) {
      handleOnZipCodeChange(zipCode);
    }

    return () => {
      isMounted = false;
    };
  }, [zipCode]);

  return (
    <Box layerStyle='base'
    >
      <Flex layerStyle='title'>
        <Box  > < BsHouseFill /> </Box>
        <Box ml='4'> Property</Box>
      </Flex >

      <Box bg="white" w="100%" p={2} color="white" />

      <Stack spacing={2} p={3}>
        <OptionsRow
          width="9em"
          inputLeftAddon={'Property Type'}
          options={Object.values(PropertyType)}
          value={propertyType}
          setValue={(val: PropertyType) => setPropertyType(val)}
          booleanRequired={false}
        />
        {propertyType === PropertyType.low_rise_condo ||
          propertyType === PropertyType.mid_rise_condo ||
          propertyType === PropertyType.high_rise_condo ||
          propertyType === PropertyType.detached_condo ||
          propertyType === PropertyType.condotel ? (
          <CheckBoxRow
            inputLeftAddon={'Non-Warrantable'}
            setChecked={(val: boolean) => setNonWarrantable(val)}
            isChecked={nonWarrantable}
            width={'10em'}
            booleanRequired={undefined}
          />
        ) : null}

        <CheckBoxRow
          inputLeftAddon={'Rural'}
          setChecked={(val: boolean) => setRural(val)}
          isChecked={rural}
          width={'10em'}
          booleanRequired={undefined} />
        <InputRow
          booleanRequired={true}
          width="10em"
          inputLeftAddon={'Zip'}
          handleOnChange={handleOnZipCodeChange}
          value={zipCode}
        />
        <InputRow
          width="10em"
          inputLeftAddon={'County'}
          handleOnChange={setCounty}
          value={county}
        />
        <InputRow
          width="10em"
          inputLeftAddon={'City'}
          handleOnChange={setCity}
          value={city}
        />
        <OptionsRow
          width="9em"
          inputLeftAddon={'State'}
          options={Object.values(State)}
          value={state}
          setValue={setState}
          booleanRequired={false}
        />
      </Stack>
    </Box >
  );
};
export default PropertyInformationStructureComponent;
