import React from 'react';

import { useMemo } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { useFetchSofrai } from '../../services/sofraiService';
import logo from '../../assets/blue_logo.png';
import { Citizenship, LienPosition, Occupancy } from '../../classes/scenario';


import Scenario from '../../classes/scenario';
import Product, { Result } from '../../classes/product';


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#112131",
    },
    textTitle: {
        width: 200,
        margin: 8,
        fontSize: 12,
        fontWeight: 800,
    },
    textDescription: {
        flex: 1,
        margin: 8,
        fontSize: 12,
    },
    logo: {
        marginVertical: 15,
        marginHorizontal: 100,
        height: 75,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",

    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",

    },
});

const PDFFile = ({ product, result, scenario }: { product: Product, result: Result, scenario: Scenario }) => {
    const { sofrai } = useFetchSofrai();
    const date = (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear();

    const data = useMemo(() => {
        return {
            Date: date,
            EffectiveDateSheet: product?.effectiveDate,
            Rate: result.cost?.coupon.toFixed(3),
            Points: result.cost?.costPoints,
            Payment: result.payment,
            LienPosition: scenario.lienPosition,
            Occupancy: scenario.occupancy,
            LoanPurpose: scenario.loanPurpose,
            PropertyValue: scenario.propertyValue,
            BaseLoanAmount: scenario.baseLoanAmount,
            LTV: `${(scenario.LTV * 100).toFixed(3)} %`,
            OtherFinancing: scenario.otherFinancing,
            CLTV: scenario.lienPosition === LienPosition.second ? `${(scenario.CLTV * 100).toFixed(3)} %` : '',
            PropertyType: scenario.propertyType,
            Rural: scenario.rural ? scenario.rural.toString() : '',
            Warrantable: scenario.nonWarrantable ? scenario.nonWarrantable.toString() : '',
            TwoOneBuyDown: scenario.twoOneBuydown ? scenario.twoOneBuydown.toString() : '',
            DelayedFinancing: scenario.delayedFinancing ? scenario.delayedFinancing.toString() : '',
            ZipCode: scenario.zipCode,
            WaiveEscrows: scenario.waiveEscrows ? scenario.waiveEscrows.toString() : '',
            Citizenship: scenario.citizenship,
            SelfEmployed: scenario.selfEmployed ? scenario.selfEmployed.toString() : '',
            FirstTimeHomeBuyer: scenario.firstTimeHomeBuyer ? scenario.firstTimeHomeBuyer.toString() : '',
            DocumentationType: scenario.documentationType,
            Prepay: scenario.prepay && scenario.occupancy === Occupancy.investment_property ? scenario.prepay.toString() : '',
            _2MonthsBankStatements: scenario._2MonthsBankStatements ? scenario._2MonthsBankStatements.toString() : '',
            DebtToIncomeRatio: scenario.debtToIncomeRatio,
            DebtServCoverageRatio: scenario.debtServCoverageRatio,
            CreditScore: scenario.citizenship !== Citizenship.foreign_national ? scenario.representativeCreditScore : null,
            LatePaymentsX30: scenario.latePaymentsX30,
            LatePaymentsX60: scenario.latePaymentsX60,
            LatePaymentsX90: scenario.latePaymentsX90,
            LatePaymentsX120: scenario.latePaymentsX120,
            FCSSDILMCOMonthsAgo: scenario.fc_ss_dil_mco__months_ago,
            BankruptcyType: scenario.bankruptcyType,
            BankruptcyDismisalMonthsAgo: scenario.termBankruptcyDismissal,
            BankruptcyDischargeMonthsAgo: scenario.termBankruptcyDischarge,
            BankruptcyFilingMonthsAgo: scenario.termBankruptcyFiling,
            State: scenario.state,
            LockDays: scenario.lockDays,
            ResidualIncomeLess1500: scenario.residualIncomeLess1500 ? scenario.residualIncomeLess1500.toString() : '',
            ResidualIncomeLess2500: scenario.residualIncomeLess2500 ? scenario.residualIncomeLess2500.toString() : '',
            NoVOR: scenario.noVOR ? scenario.noVOR.toString() : '',
            Sofr30AVG: product?.name?.includes("ARM") ? sofrai : '',
            ResetFrequency: product?.name?.includes("ARM") ? '6 months' : '',
            CapsARM: product?.name?.includes("ARM") ? "2/1/5" : '',
            ARMMargin: (product?.name?.includes("Everest") && product?.name?.includes("ARM")) ? "6.00 %" : (product?.name?.includes("Summit") && product?.name?.includes("ARM")) ? "5.00" : (product?.name?.includes("Denali") && product?.name?.includes("ARM")) ? "4.50" : '',

        }
    }, [product, result, scenario, sofrai]);

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.header}>Product: {product.name}</Text>
                {
                    Object.entries(data).map(([key, value]) => {
                        return (
                            <View style={styles.row} key={key}>
                                <Text style={styles.textTitle}>{key}:</Text>
                                <Text style={styles.textDescription}>{value}</Text>
                            </View>
                        )
                    })
                }
                <Text style={styles.header}>
                    NewPoint Mortgage Pricer is intended for licensed and real estate professions only and is not to be utilised by
                    potential borrowers or by the general public. The information does not take into consideration borrowers personal financial situation,
                    verified credit score, existing debt or other factors.
                    THIS IS NOT A COMMITMENT TO LEND.
                    Rate, terms and programs are subject to change without notice.
                </Text>
            </Page>
        </Document >

    )
}

export default PDFFile;