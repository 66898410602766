import { Tr, Td, useDisclosure, Box, Flex } from '@chakra-ui/react';
import { FcCalculator } from 'react-icons/fc';
import { VscFilePdf } from 'react-icons/vsc';
import { IconContext } from 'react-icons';
import ProductDetailModal from './ProductDetailModal';
import Scenario from '../../classes/scenario';
import Product, { Result } from '../../classes/product';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFFile from './PDFFile';
import { useFetchSofrai } from '../../services/sofraiService';
import React from 'react';

interface Props {
  product: Product;
  result: Result;
  scenario: Scenario;
}

const ResultRow = ({ product, result, scenario }: Props) => {

  const { loading } = useFetchSofrai();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Tr key={product.name} color="black">
      <Td>{product.name}</Td>
      <Td>{result.cost?.coupon.toFixed(3)}</Td>
      <Td>
        <IconContext.Provider value={{ className: 'shared-class', size: '30' }}>
          <>
            <FcCalculator onClick={onOpen} />
          </>
        </IconContext.Provider>
      </Td>
      <Td>{result.cost?.costPoints.toFixed(3)}</Td>
      <Td>$ {result.payment}</Td>
      <Td>{result.scenario.lockDays}</Td>

      <Td>
        <IconContext.Provider
          value={{ className: 'shared-class', size: '27', color: 'orange' }}
        >
          {!loading && (
            <PDFDownloadLink
              document={
                <PDFFile
                  product={product}
                  result={result}
                  scenario={scenario}
                />
              }
              fileName={`NewPoint-Scenario_${new Date().toISOString()}.pdf`}
            >
              <VscFilePdf />
            </PDFDownloadLink>
          )}
        </IconContext.Provider>
        <ProductDetailModal
          isOpen={isOpen}
          onClose={onClose}
          product={product}
          scenario={scenario}
        />
      </Td>
    </Tr>
  );
};

export default ResultRow;
