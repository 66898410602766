import { Select, InputGroup, InputLeftAddon, FormControl, FormLabel, } from '@chakra-ui/react'
import { TriangleDownIcon } from '@chakra-ui/icons'
import React from 'react';



const OptionsRow = ({ inputLeftAddon, options, value, setValue, booleanRequired }: { inputLeftAddon: string, options: string[], width: string, value: string, setValue: any, booleanRequired: boolean }) => {

    return (
        <FormControl isRequired={booleanRequired}>
            <FormLabel color="#0f1162" bg='none'>{inputLeftAddon}  </FormLabel>
            <InputGroup >
                <Select icon={<TriangleDownIcon />} color='#5d5c60ff' fontWeight={'bold'} value={value} onChange={(e) => setValue(e.target.value)} borderStartRadius='1'>
                    {
                        options.map((option, i) => {
                            return <option value={option} key={i}>{option}</option>
                        })
                    }

                </Select>
            </InputGroup>
        </FormControl>
    );
};

export default OptionsRow;

<option value='Primary Residency'>Primary Residency</option>