import {
  createIcon
} from "./chunk-QODHG6BI.mjs";
import {
  Icon,
  icon_default
} from "./chunk-DKFDJSXF.mjs";
export {
  Icon,
  createIcon,
  icon_default as default
};
