import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsLock, { RowLock, headerLock, } from "../sharedDeephavenAdjustments/adjustmentsLock";
import adjustmentsFicoxCLTV from "../sharedDeephavenAdjustments/adjustmentsFicoxCLTV";
import adjustmentsOthers, { RowOthers } from '../sharedDeephavenAdjustments/adjustmentsOther'
import { adjustmentsPrepayWithoutCompunding, headerPrepay } from "../sharedDeephavenAdjustments/adjustmentsPrepayWOCompounding";

const cltvs = [0, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80]
const ranges = ["0 - 0.55", "0.60 - 0.65", "0.65 - 0.7", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 1.00"]

const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];


    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "R21:Y38",
        header,
        defval: "",
    })
    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {

    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "T40:U42",
        header: headerLock,
        defval: "",
    })

    const prepayTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "O40:Q46",
        header: headerPrepay,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsPrepayWithoutCompunding(prepayTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", "", ...ranges.map(range => range)];

    //DSCR documentation Type 
    const arrayOfDSCR = [DocumentationType.dscr];
    const tableDocDSCR = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "R13:Y20",
        header,
        defval: "",
    })


    return [
        ...adjustmentsFicoxCLTV(tableDocDSCR, arrayOfDSCR, cltvs, ranges),
    ];
}



export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}