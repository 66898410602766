import {
  CSSPolyfill,
  CSSReset,
  css_reset_default
} from "./chunk-ZGCVOC2V.mjs";
export {
  CSSPolyfill,
  CSSReset,
  css_reset_default as default
};
