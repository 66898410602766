import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsFicoxCLTV from "../sharedVerusAdjustments/firstLien/adjustmentsFicoxCLTV"
import { adjustmentsLock, adjustmentsPrepay } from "../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import adjustmentsOthers, { RowOthers } from "../sharedVerusAdjustments/firstLien/adjustmentsOther";
import adjustmentsDSCRRatio from "../sharedVerusAdjustments/firstLien/adjustmentsDSCRRatio";

const cltvs = [0, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 1.00"]

export const parseDSCRAditionalAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const dscrAditionalAdjTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B50:J53",
        header: ["dscrAditionalAdjustments", '', ...ranges.map(range => range)],
        defval: "",
    })

    return adjustmentsDSCRRatio(dscrAditionalAdjTable, cltvs, ranges);
}

//prepay adjustments are inside the parseOtherAdjustments function for DSCR, DSCR Multi and DSCR FN 
const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B49:J87",
        header,
        defval: "",
    })
    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "adjustment"];

    type RowLock = { [key: typeof headerLock[number]]: string };
    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B89:C91",
        header: headerLock,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", "", ...ranges.map(range => range)];

    /* *************** DSCR DOC ********** */
    //DSCR doc 
    const arrayOfDocumentationTypeDSCRDoc = [DocumentationType.dscr]
    const DSCRDocMonths = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "B39:J47",
        header,
        defval: "",
    })




    return [
        ...adjustmentsFicoxCLTV(DSCRDocMonths, arrayOfDocumentationTypeDSCRDoc, cltvs, ranges),

    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseDSCRAditionalAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}