
import { Box, TableContainer, Table, Thead, Th, Tr, Tbody, } from '@chakra-ui/react'
import Product, { Result } from '../../classes/product';
import Scenario from '../../classes/scenario';
import ResultRow from './ResultRow'
import React from 'react';

interface Props {
    productNameGroup: string;
    qualifiedProducts: { product: Product, result: Result }[];
    scenario: Scenario
}

const ResultBoxQualified = ({ qualifiedProducts, productNameGroup, scenario }: Props) => {
    return (
        <Box bg='white' w='80em' p={4} color='white' borderWidth='1px' borderRadius='lg' margin={'1em'}>




            < Box p={5} color='#4789b2' fontWeight={'bolder'} fontSize={'2xl'}>
                Eligible {productNameGroup}
            </Box>


            <TableContainer>
                <Table >
                    <Thead>
                        <Tr>
                            <Th>Product</Th>
                            <Th>Rate</Th>
                            <Th>Details</Th>
                            <Th>Points</Th>
                            <Th>Payment</Th>
                            <Th>Lock Period</Th>
                            <Th>PDF</Th>
                        </Tr>
                    </Thead>
                    <Tbody >
                        {qualifiedProducts && qualifiedProducts.map(({ product, result }) => (
                            <ResultRow key={product.name} product={product} result={result} scenario={scenario} />
                        ))}
                    </Tbody >

                </Table >
            </TableContainer >

        </Box >
    );
};

export default ResultBoxQualified;

