import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { LockDays } from "../../classes/scenario";
import { useAtom } from "jotai";
import { lockAtom } from "../../services/scenarioService";
import React from "react";




const LockDaysFacet = () => {
    const [lockDays, setLockDays] = useAtom(lockAtom)
    return (
        <RadioGroup onChange={(value) => setLockDays(value as LockDays)} value={lockDays}>
            <Stack direction='column'>
                {
                    Object.values(LockDays).map((value, index) => {
                        return <Radio key={value} value={value} >{value}</Radio>
                    })
                }
            </Stack>
        </RadioGroup >
    );
};

export default LockDaysFacet;