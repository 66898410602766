import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline } from '../../../classes/product';
import { Amortization } from "../../../classes/scenario";
import guidelines from "./guidelines2ndLien";
import parseAdjustments from "./adjustments2ndLien";


type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}


const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const range = "B13:M42";
    const basePricingTable = XLSX.utils.sheet_to_json(sheet, {
        range,
        header: ["coupon", "", "", "", "10Yr Fixed", "", "", "", Amortization._30_yr_fixed, "", "", "",],
        defval: "",
    }) as RowData[]

    return basePricingTable.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: true,
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});
}

function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
    }
}


const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "Y4:Z4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return effectiveDate[0]["effectiveDate"];
}


export default function parseSecondLienDeephaven(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const program = "Everest Stand Alone 2nd Lien"

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            [],
            effectiveDate,
        );
    });
}