import { InputGroup, Button, ButtonGroup, FormControl, FormLabel, } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { LienPosition } from '../../classes/scenario'
import { lienPositionAtom } from '../../services/scenarioService'
import React from 'react'

export default function LienPositionRow() {
    const [lienPosition, setLienPosition] = useAtom(lienPositionAtom)

    return (
        <FormControl >
            <FormLabel color="#0f1162" bg='none'>Lien Position  </FormLabel>
            <InputGroup display={
                'flex'
            }>
                <ButtonGroup flex={1} display="flex" flexDir="row" isAttached variant='outline' colorScheme='blue'  >
                    {Object.entries(LienPosition).map(([key, value]) => {
                        return <Button key={key} isActive={lienPosition === value} onClick={(e) => setLienPosition(value)} flex={1} >{value}</Button>
                    })}

                </ButtonGroup>
            </InputGroup>
        </FormControl>
    );
};
