import { Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position
    {
        name: "Lien Position Allowed = First Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Documentation Type
    {
        name: "Documentation Type Allowed = DSCR",
        rules: {
            "===": [{ "var": "scenario.documentationType" }, DocumentationType.dscr]
        }
    },
    //Citizenship Allowed = Foreign National
    {
        name: "Citizenship Allowed = Foreign National",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.foreign_national
                    ]
                ]
        }
    },
    //************** GENERAL REQUIREMENTS ********//
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $1,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 1500000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "===":
                [
                    { "var": "scenario.occupancy" },
                    Occupancy.investment_property
                ]
        }
    },
    //Property Types Allowed (the guidelines are confusing but townhouse it's part of Attached so it has to be included)
    {
        name: "Property Types Allowed = Single Family, Low-Rise Condo, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, PUD Attached, Mid-Rise Condo, High-Rise Condo, Townhouse, Condotel",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },

    //MAX CASH ON HAND FOR LTV 
    /// if LTV/CLTV >= 50% then max cashOnHand = $ 300,000 
    {
        name: "Max Cash on Hand for LTV > 50% = $300,000",
        rules: {
            "if": [
                {
                    "or": [
                        {
                            ">": [

                                {
                                    "var": "scenario.LTV"
                                },
                                0.50
                            ]
                        },
                        {
                            ">": [

                                {
                                    "var": "scenario.CLTV"
                                },
                                0.50
                            ]
                        }
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        300000
                    ]
                },
                true
            ]
        },
    },
    /// if LTV/CLTV <= 50% then max cashOnHand = $ 500,000 
    {
        name: "Max Cash on Hand for LTV/CLTV <= 50% = $500,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "<=": [

                                {
                                    "var": "scenario.LTV"
                                },
                                0.50
                            ]
                        },
                        {
                            "<=": [

                                {
                                    "var": "scenario.CLTV"
                                },
                                0.50
                            ]
                        }
                    ]

                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        500000
                    ]
                },
                true
            ]
        },
    },

    //PROPERTY TYPES RULES 
    // Rural not eligible
    {
        name: "Rural not eligible",
        rules: {
            "===":
                [
                    {
                        "var": "scenario.rural"
                    },
                    false
                ]
        },
    },
    //Max LTV/CLTV for purchase Property Type 2-4 Units, Condominium 
    {
        name: "Max LTV/CLTV for purchase 2-4 Units, Condominium = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                                ]
                            },
                            {
                                "===": [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },

                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for refinance Property Type 2-4 Units, Condominium 
    {
        name: "Max LTV/CLTV for refinance 2-4 Units, Condominium = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                                ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]
                                ]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for purchase Property Type Condotel
    {
        name: "Max LTV/CLTV for purchase Condotel = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    PropertyType.condotel
                                ]
                            },
                            {
                                "===": [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },

                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for refinance Property Type Condotel
    {
        name: "Max LTV/CLTV for refinance Condotel = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    PropertyType.condotel
                                ]
                            },
                            {
                                "in": [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]
                                ]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ]
                },
                true
            ],
        },
    },
    //HOUSING HISTORY RULES
    //Mortgage History - There are no latePayments of 30 accepted 
    {
        name: "Max Mortgage Lates within last 12 mos = 0x30x12,0x60x12,0x90x12,0x120x12",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //HOUSING EVENT SEASONING RESTRICTIONS
    //BK/FC/SS/DIL History MIN MONTHS = 36 of ANY EVENT 


    /* NOTE  
    //FC/SS/DIL = 36M
    //Bankruptcy Discharge- Ch7 && Ch11 = 36M 
    //Bankruptcy Dismissal - Ch7 && Ch11 = 36M 
    //Bankruptcy Filling Ch13 = 36M )
    */

    //Minimum Months Since BK/FC/SS/DIL = 36 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 36",
        rules: {
            "and": [
                {
                    "or": [
                        { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                        { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] }
                    ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                        ]
                },
            ],
        }
    },
    //*******  LOAN PURPOSE = PURCHASE && MAX LTV/CLTV x LOAN AMOUNT && DSCR >=1 UPPER TABLE */
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Loan Purpose = Purchase && DSCR >= 1.0
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && Loan Purpose = Purchase && DSCR >= 1.0
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },

    //******* (LOAN PURPOSE = R/T REFINANCE && CASH-OUT) = MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 &&  Loan Purpose = Non Cash-Out Refinance && DSCR >= 1.0
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1.0
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },
    //*******  LOAN PURPOSE = PURCHASE && MAX LTV x LOAN AMOUNT && DSCR <1 UPPER TABLE */
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && Loan Purpose = Purchase && DSCR < 1.0
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && Loan Purpose = Purchase && DSCR < 1.0
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },

    //******* (LOAN PURPOSE = R/T REFINANCE && CASH-OUT) = MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 &&  Loan Purpose = R/T && Cash-Out Refinance && DSCR < 1.0
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1.0
                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        },

                    ]
                },
                true
            ],

        },
    },
    // LIMITS NON AVAILABLE FOR TABLE DSCR < 1 
    // Purchase only for 1,000,000 <loanAmount <= 1,500,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<": [
                                    {
                                        "var": "debServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    /** 
     * 2.3.4 FIRST TIME HOMEBUYERS (FTHB) (2/26/2024)
     * Primary residence only.
     • DTI may not exceed 45%. ( I am ignoring this one for now)
     • Minimum six (6) months of reserves.
     • 12-month rental history, reflecting 0x30, documented per Section 2.5.5 - Housing History.
     o First time homebuyers with less than 12-month rental history:
     ▪ DTI may not exceed 43%.
     ▪ LTV may not exceed 80%.
     ▪ Any available portion of a 12-month housing history must be paid as agreed.
 */
    {
        name: "First Time Home Buyer Primary residence only.",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                    },
                    true
                ]

        },
    },
    {
        name: "DTI FTHB = 43%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {
        name: "Max LTV/CLTV for FTHB = 80%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {

        name: "Max Mortgage Lates within last 12 mos for FTHB= 0x30,0x60,0x90,0x120",
        rules:
        {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]

                    },
                    true
                ]

        },
    }



]; // end of rules





