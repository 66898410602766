import * as XLSX from "xlsx";
import { Adjustment } from '../../../classes/product';
import { DocumentationType } from "../../../classes/scenario";
import adjustmentsFicoxCLTV from "../sharedVerusAdjustments/firstLien/adjustmentsFicoxCLTV"
import { adjustmentsLock, adjustmentsPrepayWithoutCompunding, headerPrepay } from "../sharedVerusAdjustments/adjustmentsLockAndPrepay";
import adjustmentsOthers, { RowOthers } from "../sharedVerusAdjustments/firstLien/adjustmentsOther";
import adjustmentsCLTV from "../sharedVerusAdjustments/firstLien/adjustmentsCLTV";

const cltvs = [0, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.9]
const ranges = ["0 - 0.50", "0.50 - 0.55", "0.55 - 0.60", "0.60 - 0.65", "0.65 - 0.70", "0.70 - 0.75", "0.75 - 0.80", "0.80 - 0.85", "0.85 - 0.90", "0.90 - 1.00"]


const parseOtherAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["priceAdjustments", "", ...ranges.map(range => range)];

    const priceAdjustmentsTable = XLSX.utils.sheet_to_json<RowOthers>(sheet, {
        range: "B57:L82",
        header,
        defval: "",
    })

    return adjustmentsOthers(priceAdjustmentsTable, cltvs, ranges)
}

const parseLockAndPrepayAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const headerLock = ["lockDays", "", "adjustment"];
    type RowLock = { [key: typeof headerLock[number]]: string };
    const lockDaysTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B91:D94",
        header: headerLock,
        defval: "",
    })

    const prepayTable = XLSX.utils.sheet_to_json<RowLock>(sheet, {
        range: "B85:D90",
        header: headerPrepay,
        defval: "",
    })

    return [
        ...adjustmentsLock(lockDaysTable),
        ...adjustmentsPrepayWithoutCompunding(prepayTable),
    ];
}


const parseFICOxLTVAdjustments = (sheet: XLSX.WorkSheet): Adjustment[] => {
    const header = ["fico", ...ranges.map(range => range)];

    /* *************** STANDARD DOC ********** */
    //full doc 24 Months, full doc 12 Months, WVOE, p&l 12 Months, p&l 24 Months = Standard Doc 
    const arrayOfDocumentationTypeStandardDoc = [DocumentationType.full_doc, DocumentationType._12M_full_doc, DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
    const standardDoc12_24Months = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C36:L43",
        header,
        defval: "",
    })

    //Extra Adjustments for standard doc 12 Months = Standard Doc 12 Months 
    const arrayOfDocumentationType_standardDoc12Months = [DocumentationType._12M_full_doc, DocumentationType._12M_p_and_l]
    const standarDoc12MonthsExtraAdj = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C44:L44",
        header,
        defval: "",
    })

    /* *************** ALT DOC ********** */
    //12M bank stmts, 24M bank stmts 
    //1099 12 Months, 1099 24 Months 
    const arrayOfDocumentationTypeAltDoc = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099]
    const altDoc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C45:L52",
        header,
        defval: "",
    })

    //Extra Adjustments 12M Bank Statements
    const arrayOfDocumentationType_12Months_Bk_Stm = [DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts]
    const _12MonthsExtraAdj_Bk_Stm_Doc = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C53:L53",
        header,
        defval: "",
    })

    //Extra Adjustments 12M P&L
    const arrayOfDocumentationType_12MonthsPNL = [DocumentationType._12M_p_and_l]
    const _12MonthsExtraAdj_PNL = XLSX.utils.sheet_to_json<object>(sheet, {
        range: "C54:L54",
        header,
        defval: "",
    })



    return [
        ...adjustmentsFicoxCLTV(standardDoc12_24Months, arrayOfDocumentationTypeStandardDoc, cltvs, ranges),
        ...adjustmentsFicoxCLTV(standarDoc12MonthsExtraAdj, arrayOfDocumentationType_standardDoc12Months, cltvs, ranges),
        ...adjustmentsFicoxCLTV(altDoc, arrayOfDocumentationTypeAltDoc, cltvs, ranges),
        ...adjustmentsCLTV(_12MonthsExtraAdj_Bk_Stm_Doc, arrayOfDocumentationType_12Months_Bk_Stm, cltvs, ranges),
        ...adjustmentsCLTV(_12MonthsExtraAdj_PNL, arrayOfDocumentationType_12MonthsPNL, cltvs, ranges),
    ];
}

export default function parseAdjustments(sheet: XLSX.WorkSheet): Adjustment[] {
    return [
        ...parseFICOxLTVAdjustments(sheet),
        ...parseLockAndPrepayAdjustments(sheet),
        ...parseOtherAdjustments(sheet),
    ]
}