import { Amortization, Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Self-Employed Bor = True for all documentationType allowed EXCEPT WVOE, Full Doc, 12M Full Doc, DSCR, Asset Utilization
    {
        name: "Self-Employed Bor = True",
        rules: {
            "if": [
                {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l
                        ]
                    ],

                },
                {
                    "===":
                        [
                            { "var": "scenario.selfEmployed" },
                            true
                        ]
                },
                true
            ]

        }
    },
    /********* INTEREST ONLY */
    //Max LTV/CLTV for interest only
    {
        name: "Max LTV for interest only = 80%",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }
                    ],
                },
                true
            ]

        }
    },
    //************** OTHER OVERLAYS ********//
    //Max LTV/CLTV for P&L Puchase = 80% 
    {
        name: "Max LTV/CLTV for P&L purchase = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in":
                                [
                                    {
                                        "var": "scenario.documentationType"
                                    }
                                    ,
                                    [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                                ],
                        },
                        {
                            "===":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    LoanPurpose.purchase
                                ]
                        },
                    ]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                        }
                    ],
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for P&L Refinance = 70%
    {
        name: "Max LTV/CLTV for P&L refinance = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in":
                                [
                                    { "var": "scenario.documentationType" }
                                    ,
                                    [DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                                ],
                        },
                        {
                            "===":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    LoanPurpose.refinance_cash_out
                                ]
                        },
                    ]


                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                        }
                    ],
                },
                true
            ]
        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $3,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 3500000]
        }
    },
    //Maximum cash-out if LTVs < 50% = $1,500,000
    {
        name: "Maximum Cash-Out if LTV/CLTV < 50% = $1,500,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "<": [{ "var": "scenario.LTV" }, 0.50]
                        },
                        {
                            "<": [{ "var": "scenario.CLTV" }, 0.50]
                        }
                    ]

                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 1500000]
                },
                true
            ]
        }
    },
    //Maximum cash-out if LTVs > 50% = $1,000,000
    {
        name: "Maximum Cash-Out if LTV/CLTV > 50% = $1,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">": [{ "var": "scenario.LTV" }, 0.50]
                        },
                        {
                            ">": [{ "var": "scenario.CLTV" }, 0.50]
                        }
                    ]

                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 1000000]
                },
                true
            ]
        }
    },
    //Min FICO for cash-out > 500,000 = 720 && LTV < 60%
    {
        name: "Min FICO for cash-out > 500,000 = 720 and LTV < 60% = $ 500,000",
        rules: {
            "if": [
                {
                    ">": [{ "var": "scenario.cashOutAmount" }, 500000]
                },
                {
                    "and": [
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 720]
                        },
                        {
                            "and": [
                                {
                                    "<": [{ "var": "scenario.LTV" }, 0.60]
                                },
                                {
                                    "<": [{ "var": "scenario.CLTV" }, 0.60]
                                }
                            ]
                        }
                    ]
                },
                true

            ]

        }
    },
    //Max LTV/CLTV for cash-out > 500,000 = 60%
    {
        name: "Max LTV/CLTV for cash-out > 500,000 = 60%",
        rules: {
            "if": [
                {
                    ">": [{ "var": "scenario.cashOutAmount" }, 500000]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        }
                    ]
                },
                true

            ]

        }
    },
    //Mortgage History
    {
        name: "Max Mortgage Lates within last 12 mos = 1x30,0x60,0x90,0x120",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //Minimum Months Since BK/FC/SS/DIL  = 48 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 48",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 48] },
                        ],


                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                            },
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                        ]
                },
            ]
        }
    },
    //Max Debt To Income Ratio = 50% 
    {
        name: "Max Debt To Income Ratio = 50% ",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },
    // ****LTV/CLTV MAX FOR PROPERTY TYPE****

    //Max LTV/CLTV for Property Type Condominium 
    //Mike Quintero: Please change this so that it will price at 90% not 89.99%.  There isn't a broker alive that will know to put the scenario in at 89.99%
    {
        name: "Max LTV/CLTV for Condominium = 89.99%",
        rules:
        {
            "if":
                [
                    {
                        "in": [
                            { "var": "scenario.propertyType" },
                            [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                        ]
                    },
                    {
                        "and": [
                            {
                                "<=": [{ "var": "scenario.LTV" }, 0.90]
                            },
                            {
                                "<=": [{ "var": "scenario.CLTV" }, 0.90]
                            }
                        ]
                    },
                    true
                ]
        }
    },

    //Max LTV/CLTV for Property Type Non-Warrantable Condo
    {
        name: "Max LTV/CLTV for Non-Warrantable Condominium = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                            ]
                        },
                        {
                            "===": [{ "var": "scenario.nonWarrantable" }, true]
                        }

                    ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type 2-4 Units 
    {
        name: "Max LTV/CLTV for 2-4 Units = 80%",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.propertyType"
                        },
                        [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Rural Property
    {
        name: "Max LTV/CLTV for rural property = 80%",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.rural"
                        },
                        true
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],
        }
    },

    //************** INCOME ********//
    // Documentation Type
    {
        name: "Doc Types Allowed = Full Doc, Asset Utilization, 12M Personal Bank Stmts, 24M Personal Bank Stmts, 12M 1099, 24M 1099, 12M P&L, 24M Business Bank Stmts, 12M Business Bank Stmts, 12M Full Doc",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.full_doc, DocumentationType._12M_full_doc, DocumentationType.asset_utilization, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_business_bank_stmts,]]
        }
    },
    // Only Purchase and Rate/Term (No cash-out refi) allowed for asset utilization 
    {
        name: "Only Purchase and No Cash-Out Refinance allowed for Asset Utilization",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            DocumentationType.asset_utilization
                        ]
                },
                {
                    "in":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]
                        ]
                },
                true
            ],
        }
    },
    // Max LTV/CLTV for asset utilization = 80%  
    {
        name: "Max LTV/CLTV for Asset Utilization = 80%",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            DocumentationType.asset_utilization
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        }
                    ]
                },
                true
            ],
        },
    },
    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse]]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien (w/ US Credit)",
        rules: {
            "in": [{ "var": "scenario.citizenship" }, [Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien]]
        }
    },

    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE AND LOAN PURPOSE UPPER TABLE (PRIMARY RESIDENCE)*/

    //PRIMARY RESIDENCE,PURCHASE && RATE TERM  && FULL DOC && ALT DOC && LOAN AMOUNT <=1500000

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    //Mike Quintero: Please change this so that it will price at 90% not 89.99%.  There isn't a broker alive that will know to put the scenario in at 89.99%
    {
        name: "Max LTV/CLTV x scenario = 89.99%",
        rules: {
            "if":
                [
                    {
                        "and":
                            [
                                {
                                    "<=":
                                        [
                                            {
                                                "var": "scenario.baseLoanAmount"
                                            },
                                            1500000
                                        ]
                                },
                                {
                                    ">=":
                                        [
                                            {
                                                "var": "scenario.representativeCreditScore"
                                            },
                                            740
                                        ]
                                },
                                {
                                    "!==":
                                        [
                                            {
                                                "var": "scenario.documentationType"
                                            },
                                            DocumentationType.asset_utilization
                                        ]
                                },
                                {
                                    "in":
                                        [
                                            {
                                                "var": "scenario.loanPurpose"
                                            },
                                            [
                                                LoanPurpose.purchase,
                                                LoanPurpose.refinance_no_cash_out
                                            ]
                                        ]
                                },
                                {
                                    "===":
                                        [
                                            {
                                                "var": "scenario.occupancy"
                                            },
                                            Occupancy.primary_residence

                                        ]
                                }
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.90
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.90
                                ]
                            }
                        ]
                    },
                    true
                ],

        },
    },

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && Documentation Type = Asset utilization Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules:
        {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [

                                        { "var": "scenario.baseLoanAmount" },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        [
                                            DocumentationType.asset_utilization
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  680 <= FICO < 740 && Documentation Type except Asset Utilization (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    740
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  680 <= FICO < 740 && Documentation Type = Asset Utilization Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    740
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        [
                                            DocumentationType.asset_utilization
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //PRIMARY RESIDENCE, (PURCHASE || RATE TERM)  && FULL DOC  && (1500000 < LOAN AMOUNT <=2000000)

    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 700 && Documentation Type = Full Doc && Alt Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (1,500,000 < baseLoanAmount <= 2,000,000) && (680<= FICO < 700)  && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && FICO >= 720) && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]

                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && 680 <= FICO < 720) && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //PRIMARY RESIDENCE, (PURCHASE || RATE TERM)  && FULL DOC  && (2,500,000 < LOAN AMOUNT <=3,000,000)

    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence,
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (2,500,000 < baseLoanAmount <= 3,000,000) && (700<= FICO < 720) && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for (3,000,000 < baseLoanAmount <= 3,500,000) && FICO >= 700) && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //*********************/
    //PRIMARY RESIDENCE, CASH-OUT && FULL DOC (NO Asset Utilization) && ALT DOC && LOAN AMOUNT <=1500000

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  680 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    740
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //PRIMARY RESIDENCE, (CASH-OUT) && FULL DOC && ALT DOC (NO Asset Utilization) && (1500000 < LOAN AMOUNT <=2000000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 700 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (1,500,000 < baseLoanAmount <= 2,000,000) && (680<= FICO < 700) && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && FICO >= 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]

                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && 680 <= FICO < 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //PRIMARY RESIDENCE, (CASH-OUT)  && FULL DOC  && ALT DOC w/o Asset Utilization (2,500,000 < LOAN AMOUNT <=3,000,000)

    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 720 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence,
                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (2,500,000 < baseLoanAmount <= 3,000,000) && (700<= FICO < 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE AND LOAN PURPOSE &&  (OCCUPANCY = SECOND HOME || INVESTMENT) UPPER TABLE */

    //SECOND HOME AND INVESTMENT
    // Note: Asset Utilization included for all the ones that are LTV <= 80 %, otherwise it has to be limited to 80 % 

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && All Docs && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,
                                        LoanPurpose.refinance_no_cash_out,
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  680 <= FICO < 740 && All Docs (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    740
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 680 && All Docs (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Second Home and Investment, (PURCHASE || RATE TERM)  && FULL DOC  && (1500000 < LOAN AMOUNT <=2000000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 700 && All Docs && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {

                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (1,500,000 < baseLoanAmount <= 2,000,000) && (680<= FICO < 700) && Documentation Type = All Doc && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && FICO >= 720) && All Docs && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]

                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && 680 <= FICO < 720) && All Docs && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Second Home and Investment, (PURCHASE || RATE TERM) && (2,500,000 < LOAN AMOUNT <=3,000,000)

    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (2,500,000 < baseLoanAmount <= 3,000,000) && (700<= FICO < 720) && All Docs (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //*********************/
    //Second Home and Investment, CASH-OUT && FULL DOC (NO Asset Utilization) && ALT DOC && LOAN AMOUNT <=1500000

    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && FICO >= 740 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization && (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  680 <= FICO < 740 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    740
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 &&  660 <= FICO < 680 && Documentation Type = Full Doc && Alt Doc (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    660
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.primary_residence]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Second Home and Investment, (CASH-OUT)  && FULL DOC && ALT DOC (NO Asset Utilization) && (1500000 < LOAN AMOUNT <=2000000)
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 700 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization && (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (1,500,000 < baseLoanAmount <= 2,000,000) && (680<= FICO < 700) && Documentation Type = Full Doc && (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.primary_residence]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && FICO >= 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {

                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]

                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Max LTV/CLTV for (2,000,000 < baseLoanAmount <= 2,500,000) && 680 <= FICO < 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    680
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //Second Home and Investment, (CASH-OUT)  && FULL DOC  && ALT DOC w/o Asset Utilization (2,500,000 < LOAN AMOUNT <=3,000,000)

    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 720 && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for (2,500,000 < baseLoanAmount <= 3,000,000) && (700<= FICO < 720) && Documentation Type = Full Doc && Alt Doc w/o Asset Utilization (Loan Purpose = Cash-Out Refi) && Occupancy = Second Home and Investment
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                "and":
                                    [
                                        {
                                            ">=":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    700
                                                ]
                                        },
                                        {
                                            "<":
                                                [
                                                    {
                                                        "var": "scenario.representativeCreditScore"
                                                    },
                                                    720
                                                ],
                                        }
                                    ],
                            },
                            {
                                "!==":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.asset_utilization
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },


    //************** LIMITS TABLE  ********//
    // Min FICO for baseLoanAmount <= 1,500,000 
    {
        name: "Min Credit Score x scenario = 660",
        rules: {
            "if": [
                {
                    "<=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"

                            },
                            1500000
                        ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            660
                        ]
                },
                true]
        },
    },
    // Min FICO for 1,500,000 <= baseLoanAmount <= 2,000,000 
    {
        name: "Min Credit Score x scenario = 680",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        2000000
                                    ]
                            }
                        ],


                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                true
            ]
        },
    },
    // Min FICO for 2,000,000 <= baseLoanAmount <= 2,500,000 
    {
        name: "Min Credit Score x scenario = 680",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        2500000
                                    ]
                            }
                        ],


                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                true
            ]
        },
    },
    // Min FICO for 2,500,000 <= baseLoanAmount <= 3,000,000 
    {
        name: "Min Credit Score x scenario = 700",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        3000000
                                    ]
                            }
                        ],


                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]
        },
    },
    // Min FICO for 3,000,000 <= baseLoanAmount <= 3,500,000 
    {
        name: "Min Credit Score x scenario = 700",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        3000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"

                                        },
                                        3500000
                                    ]
                            }
                        ],


                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]
        },
    },
    // Max loanAmount for cash out refi
    {
        name: "Max loanAmount for cash out refi = 3,000,000",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.loanPurpose"
                            },
                            LoanPurpose.refinance_cash_out
                        ]
                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"

                            },
                            3000000
                        ]
                },
                true
            ]
        },
    }
]

