import { WorkBook } from "xlsx";
import Product from "../../classes/product";

import parseExpandedPrime from "./expandedPrime/parseExpandedPrime";
import parseNonPrime from "./nonPrime/parseNonPrime";
import parseDSCR from "./DSCR/parseDSCR";
import parseDSCRForeignNational from "./DSCRForeignNational/parseDSCRForeignNational";


export default function parseDeephaven(workbook: WorkBook): Product[] {
    const expandedPrimeSheet = workbook.Sheets["Expanded Prime"];
    const nonPrimeSheet = workbook.Sheets["Non-Prime"];
    const DSCRSheet = workbook.Sheets["DSCR"];


    return [
        ...parseExpandedPrime(expandedPrimeSheet),
        ...parseNonPrime(nonPrimeSheet),
        ...parseDSCR(DSCRSheet),
        ...parseDSCRForeignNational(DSCRSheet),
    ]
}