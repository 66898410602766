import { RulesLogic } from 'json-logic-js'
import { Adjustment } from '../../../classes/product';

export const rangeRuleFicoxCltv = (range: string, value: RulesLogic): RulesLogic<{}> => {
    const withoutWhitespace = range.replace(/\s/g, '')
    const withPlus = withoutWhitespace.split('+');

    if (withPlus.length > 2) throw new Error(`Range not recognized: ${range}`);
    else if (withPlus.length == 2) {
        return {
            ">=": [value, Number(withPlus[0])],
        }
    }

    const [min, max] = withPlus[0].split('-');

    return {
        "and": [
            { ">=": [value, Number(min)] },
            { "<=": [value, Number(max)] }
        ]
    }
}


export const rangeRuleLoanAmount = (boundStr: string, value: RulesLogic): RulesLogic<{}> => {
    const bound = boundStr.replace(/[^0-9.]/g, '');

    if (boundStr.includes("<=") || boundStr.includes("<")) {
        return {
            "<=": [value, Number(bound)],
        };
    } else if (boundStr.includes("-")) {
        const [lowerBound, upperBound] = boundStr.split("-").map((str) => str.replace(/[^0-9.]/g, ''));
        return {
            "and": [
                { ">=": [value, Number(lowerBound)] },
                { "<=": [value, Number(upperBound)] }
            ]
        };
    } else {
        throw new Error(`Range not recognized: ${boundStr}`);
    }
};


export const compoundLTVAdjustments = (name: string, row: { [key: string]: any }, ltvs: number[], ranges: string[], rule?: RulesLogic<{}>): Adjustment[] => ltvs.reduce<Adjustment[]>((acc, ltv, i, ltvs) => {
    const value = row[ranges[i]];
    if (value === "N/A" || value === "NA") return acc;
    const ltvRule: RulesLogic<{}> = i === ltvs.length - 1 ? { ">=": [{ "var": "scenario.LTV" }, ltv] } : { "and": [{ ">": [{ "var": "scenario.LTV" }, ltv] }, { "<=": [{ "var": "scenario.LTV" }, ltvs[i + 1]] }] }
    const rules: RulesLogic<{}> = rule ? { "and": [rule, ltvRule] } : ltvRule;
    return [...acc, { name, rules, value }]
}, [])

export const compoundCLTVAdjustments = (name: string, row: { [key: string]: any }, cltvs: number[], ranges: string[], rule?: RulesLogic<{}>): Adjustment[] => cltvs.reduce<Adjustment[]>((acc, cltv, i, cltvs) => {
    const value = row[ranges[i]];
    if (value === "N/A" || value === "NA") return acc;
    const cltvRule: RulesLogic<{}> = i === cltvs.length - 1 ? { ">=": [{ "var": "scenario.CLTV" }, cltv] } : { "and": [{ ">": [{ "var": "scenario.CLTV" }, cltv] }, { "<=": [{ "var": "scenario.CLTV" }, cltvs[i + 1]] }] }
    const rules: RulesLogic<{}> = rule ? { "and": [rule, cltvRule] } : cltvRule;
    return [...acc, { name, rules, value }]
}, [])

