import { Amortization, BankruptcyType, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse]]
        }
    },
    /********* INTEREST ONLY */
    //Max LTV for interest only
    {
        name: "Max LTV for interest only = 75%",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "<=":
                                [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                        }
                    ],
                },
                true
            ]

        }
    },
    // Min DSCR for interest only = 1.00
    {
        name: "Min DSCR for interest only = 1.00",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ]
                },
                true
            ]

        },
    },
    //******** INVESTOR EXPERIENCE */
    // First Time Home Buyer Allowed = No
    {
        name: "First Time Home Buyer Allowed = No",
        rules: {
            "===": [{ "var": "scenario.firstTimeHomeBuyer" }, false]
        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    //Maximum loan amount 
    //Maximum loan Amount for Foreign National = $1,500,000
    {
        name: "Maximum Loan Amount = $ 1,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 1500000]
        }
    },
    //Maximum cash-out 
    {
        name: "Maximum Cash-Out = $500,000",
        rules: {
            "<=": [{ "var": "scenario.cashOutAmount" }, 500000]
        }
    },
    // //Mortgage History
    {
        name: "Mortgage History = 0x30x12",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //Minimum Months Since BK/FC/SS/DIL  = 36 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 36",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                        ],


                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                            },
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                        ]
                },
            ]
        }
    },
    // ****LTV/CLTV MAX FOR PROPERTY TYPE****
    //Max LTV/CLTV for rural property and purchase = 75%
    {
        name: "Max LTV/CLTV for Rural Property = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.purchase
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.rural"
                                },
                                true
                            ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ],
        }
    },
    //Max LTV/CLTV for rural property and cash-out = 70%
    {
        name: "Max LTV/CLTV for Rural Property = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.refinance_cash_out
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.rural"
                                },
                                true
                            ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ],
        }
    },
    //Max LTV/CLTV for Non-Warrantable Property
    {
        name: "Max LTV/CLTV for Non-Warrantable property = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.nonWarrantable"
                                },
                                true
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],
        }
    },
    //************** INCOME ********//
    // Documentation Type
    {
        name: "Doc Types Allowed = DSCR",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.dscr]]
        }
    },

    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]]
        }
    },
    // Min DSCR for Foreign National = 1
    {
        name: "Min DSCR for Foreign National = 1",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.citizenship"
                            },
                            Citizenship.foreign_national
                        ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.debtServCoverageRatio"
                            },
                            1
                        ]
                },
                true
            ]
        },
    },
    //Citizenships Allowed = Foreign National 
    {
        name: "Citizenships Allowed = Foreign National",
        rules: {
            "in": [
                { "var": "scenario.citizenship" },
                [
                    Citizenship.foreign_national
                ],
            ]
        }
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT && DSCR UPPER TABLE*/
    //LOAN PURPOSE = (PURCHASE || RATE TERM)  && LOAN AMOUNT <= 1,500,000 
    //Max LTV/CLTV for baseLoan <= 1,500,000 (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        },
                    ]
                },
                true
            ],

        },
    },
    //LOAN PURPOSE = (CASH-OUT)  && LOAN AMOUNT <= 1,500,000 
    //Max LTV/CLTV for baseLoanAmount <= 1,500,000 && (Loan Purpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        },
                    ]
                },
                true
            ],

        },
    },
]

