import { Adjustment } from '../../../classes/product';
import { rangeRuleFicoxLtv } from '../utilsDeephaven2ndLien/ruleUtils';
import { RulesLogic } from 'json-logic-js'
import { DocumentationType } from "../../../classes/scenario";

export default function adjustmentsFicoxCLTV(rows: object[], docTypes: DocumentationType[], cltvs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];


    rows.forEach((row: any) => {
        const docTypeRule: RulesLogic = {
            "in": [{ "var": "scenario.documentationType" }, docTypes]
        }
        const ficoRule = rangeRuleFicoxLtv(row.fico, { "var": "scenario.representativeCreditScore" });
        cltvs.forEach((cltv, i, cltvs) => {
            let FICO = row.fico;
            let lowerlimitCLTV = cltv * 100;
            let upperLimitCLTV = cltvs[i + 1] * 100;
            let name = '';

            if (upperLimitCLTV === undefined || Number.isNaN(upperLimitCLTV)) {
                name = `FICO  ${FICO}:
            IF CLTV >  ${lowerlimitCLTV.toFixed(3)}%`
            } else {
                name = `FICO  ${FICO}: IF CLTV >  ${lowerlimitCLTV.toFixed(3)}% AND CLTV <= ${upperLimitCLTV.toFixed(3)}% `
            }


            const value = row[ranges[i]];
            if (value === "N/A") return;
            const cltvRule: RulesLogic<{}> = i === cltvs.length - 1 ? { ">=": [{ "var": "scenario.CLTV" }, cltv] } : { "and": [{ ">": [{ "var": "scenario.CLTV" }, cltv] }, { "<=": [{ "var": "scenario.CLTV" }, cltvs[i + 1]] }] }
            const rules: RulesLogic<{}> = {
                "and": [
                    docTypeRule,
                    ficoRule,
                    cltvRule,
                ]
            }
            adjustments.push({ name, rules, value })
        })
    })
    return adjustments
}

