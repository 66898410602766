import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline, MaxPrice } from '../../../classes/product';
import { Amortization, Occupancy, Prepay } from "../../../classes/scenario";
import guidelines from "./guidelinesExpandedPrime";
import parseAdjustments from "./adjustmentsExpandedPrime";

type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}

const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const range = "B13:G46";
    const basePricingTable = (XLSX.utils.sheet_to_json(sheet, {
        range,
        header: ["coupon", "", Amortization._30_yr_fixed_arm_5_6, "", Amortization._30_yr_fixed, ""],
        defval: "",
    }) as RowData[]).map(row => {
        // Add IO amortizations
        row[Amortization._40_arm_5_6_IO] = row[Amortization._30_yr_fixed_arm_5_6];
        row[Amortization._40_yr_fixed_IO] = row[Amortization._30_yr_fixed];
        return row
    })
    return basePricingTable.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: true,
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});
}

function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
        [Amortization._40_yr_fixed_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_yr_fixed_IO}`,
                    rules: {
                        "in": [Amortization._40_yr_fixed_IO, { "var": "scenario.amortizations" }]
                    }
                },
            ],
            adjustments: [],
        },
        [Amortization._30_yr_fixed_arm_5_6]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed_arm_5_6}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed_arm_5_6, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: [],
        },
        [Amortization._40_arm_5_6_IO]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._40_arm_5_6_IO}`,
                    rules: {
                        "in": [Amortization._40_arm_5_6_IO, { "var": "scenario.amortizations" }]
                    }
                },
            ],
            adjustments: [],
        },
    }
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "Q4:S4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return effectiveDate[0]["effectiveDate"];
}

const parsePriceMaximumsPrepay = (coverSheet: XLSX.WorkSheet): MaxPrice[] => {
    const range = "I49:K55";
    const priceMaximums = XLSX.utils.sheet_to_json(coverSheet, {
        range,
        header: ["term", "LLPA", "maxPx"],
        rawNumbers: false,
    });

    function getPrepay(term: string): Prepay | undefined {
        switch (term) {
            case "No Prepay": return Prepay._noPrepay;
            case "1 Year": return Prepay._1Year;
            case "2 Year": return Prepay._2Year;
            case "3 Year": return Prepay._3Year;
            case "4 Year": return Prepay._4Year;
            case "5 Year": return Prepay._5Year;
        }
    }

    return priceMaximums.map((row: any) => ({
        rules: {
            "and": [
                {
                    "===": [{ "var": "scenario.prepay" }, getPrepay(row.term)],

                },
                {
                    "===": [{ "var": "scenario.occupancy" }, Occupancy.investment_property],
                }

            ]

        },
        value: row.maxPx,
    }))
}

export default function parseExpandedPrime(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const priceMaximums = parsePriceMaximumsPrepay(sheet);
    const program = "Everest Prime"

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            priceMaximums,
            effectiveDate,
        );
    });
}