import MapsSearch, { SearchAddressResultOutput } from "@azure-rest/maps-search";
import { AzureKeyCredential } from "@azure/core-auth";

type AddressResultOutput = {
    results: Array<AdressObject>;
}

type AdressObject = {
    address: AddressOutput;
};

type AddressOutput = {
    country: string;
    countryCode: string;
    countryCodeISO3: string;
    countrySecondarySubdivision: string;
    countrySubdivision: string;
    countrySubdivisionCode: string;
    countrySubdivisionName: string;
    freeformAddress: string;
    municipality: string;
    postalCode: string;
    postalName: string;
};

export default class AzureMapsService {
    credential = new AzureKeyCredential(process.env.AZURE_MAPS_API_KEY as string);
    client = MapsSearch(this.credential);
    async getLocation(zip: string) {
        if (!zip) return null;
        try {
            const response = await this.client.path("/search/address/{format}", "json").get({
                queryParameters: {
                    query: zip,
                    countrySet: ["US"],
                    entityType: "PostalCodeArea",
                }
            });
            const body = response.body as AddressResultOutput;
            if (body.results.length === 0) return null;
            const topResult = body.results[0]
            return topResult.address;
        } catch (response) {
            // if (response.status !== "200") throw new Error('Error getting location');
            return
        }
    }
}

//GET https://{geography}.atlas.microsoft.com/search/address/{format}?api-version=1.0&query={query}//
//https://atlas.microsoft.com/search/address/json?api-version=1.0&query=89413&subscription-key={MAP_AZURE_KEY}&countrySet=US&entityType=PostalCodeArea