import { Guideline } from "../../../classes/product";
import { Amortization, Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

const guidelines: Guideline[] = [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    //Documentation Types Allowed = DSCR 
    {
        name: "Documentation Types Allowed = DSCR",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.dscr]]
        }
    },
    //Max Debt To Income Ratio = 50% 
    {
        name: "Max Debt To Income Ratio = 50% ",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {

            "in": [{ "var": "scenario.occupancy" }, [Occupancy.investment_property]]
        },
    },

    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Condotel, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },
    //Rural Properties Allowed = False
    {
        name: "Rural Properties Not Allowed",
        rules: {
            "===": [{ "var": "scenario.rural" }, false]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien (w/ US Credit), Foreign National",
        rules: {
            "in": [{ "var": "scenario.citizenship" }, [Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien, Citizenship.foreign_national]]
        }
    },
    /************* AMORTIZATION 30 YR */
    //Min Credit Score when DSCR >=1 = 640 
    {
        name: "Min Credit Score when DSCR >= 1 = 640",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed]]
                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                        }
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            640
                        ]
                },
                true
            ]
        },

    },
    //Min Credit Score when 0.75 <= DSCR <1 = 700
    {
        name: "Min Credit Score when 0.75 <= DSCR < 1 = 700",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed]]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                        }
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                true
            ]
        },

    },
    //Min Credit Score when DSCR < 0.75 = 700
    {
        name: "Min Credit Score when DSCR < 0.75 = 700",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed]]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                        }
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]
        },

    },
    /************ INTEREST ONLY AMORTIZATIONS */
    //Min Credit Score when DSCR >=1 = 640 
    {
        name: "Min Credit Score when DSCR >= 1 = 640",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                        }

                    ]

                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            640
                        ]
                },
                true
            ]
        },

    },
    //Min Credit Score when 0.75 <= DSCR <1 = 700
    {
        name: "Min Credit Score when 0.75 <= DSCR < 1 = 700",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                        }
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]
        },

    },
    //IO not allowed on No Ratio loans
    {
        name: "IO not allowed on No Ratio loans",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                        }
                    ]
                },
                false,
                true
            ]
        },

    },
    {
        name: "Non 40Yr IO for Foreign National",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national],
                },
                true
            ]

        },

    },
    //40Yr IO Fixed = Max 75% LTV 
    {
        name: "40Yr IO Fixed = Max 75% LTV",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75], },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75], }
                    ]

                },
                true
            ]


        },
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            "if":
                [
                    {
                        "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { ">=": [{ "var": "scenario.baseLoanAmount" }, 150000] },
                    true
                ]
        }
    },
    {
        name: "Maximum Loan Amount = $3,000,000",
        rules: {
            "if":
                [
                    {
                        "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 3000000] },
                    true
                ]

        }
    },
    //********* PROPERTY TYPE RESTRICTIONS */
    //*******1-4 Unit CLTV Limits*********//
    //"Max LTV/CLTV 1-4 unit = 85%", purchase and FICO >= 720
    {
        name: "Max LTV/CLTV 1-4 Unit = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 720]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.85],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.85]
                        }
                    ]
                },
                true
            ]
        },
    },
    //Max CLTV 3-4 Unit Ref and DSCR >= 1 = 75%
    {
        name: "Max CLTV 3-4 Unit Ref and DSCR >= 1 = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType._3_unit, PropertyType._4_unit]]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1]
                        },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        },
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        },
    },
    //Max CLTV 3-4 Unit Ref and DSCR < 1 = 70%
    {
        name: "Max CLTV 3-4 Unit Ref and DSCR < 1 = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType._3_unit, PropertyType._4_unit]]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "<": [{ "var": "scenario.debtServCoverageRatio" }, 1]
                        },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        },
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        },
    },
    //*******Condos LTV/CLTV Limits*********//
    //Condos - Max LTV/CLTV for Condos Purchase and R/T with FICO >= 720 = 80% 
    {
        name: "Max LTV/CLTV Condos Purchase and R/T with FICO >= 720 = 80% ",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                ">=": [{ "var": "scenario.representativeCreditScore" }, 720]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.80],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.80]
                        },
                    ]
                },
                true
            ]
        }
    },
    //Condos - Max LTV/CLTV for Condos Purchase and R/T with FICO < 720 = 75% 
    {
        name: "Max LTV/CLTV Condos Purchase and R/T with FICO < 720 = 75% ",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                "<": [{ "var": "scenario.representativeCreditScore" }, 720]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condos - Max LTV/CLTV for Condos Cash-Out with FICO >= 720 = 75% 
    {
        name: "Max LTV/CLTV Condos Cash-Out with FICO >= 720 = 75% ",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                ">=": [{ "var": "scenario.representativeCreditScore" }, 720]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Condos - Max LTV/CLTV for Condos Cash-Out with FICO < 720 = 70% 
    {
        name: "Max LTV/CLTV Condos Cash-Out with FICO < 720 = 70% ",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                "<": [{ "var": "scenario.representativeCreditScore" }, 720]
                            }

                        ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    //"Max Cash-Out in condos FL = 75%",
    {
        name: "Max LTV/CLTV for condos - Cash-Out FL = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "scenario.propertyType" }, [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo]]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        },
                        {
                            "===": [{ "var": "scenario.state" }, State.florida]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        },
    },
    //*******Cash-Out *********//
    // Max cashOutAmount for LTV/CLTV >= 60% or DSCR >= 0.75 = $500,000
    {
        name: "Max cashOutAmount for LTV/CLTV >= 60% or DSCR >= 0.75 = $500,000",
        rules: {
            "if": [
                {
                    "or": [
                        {
                            ">=": [{ "var": "scenario.LTV" }, 0.60]
                        },
                        {
                            ">=": [{ "var": "scenario.CLTV" }, 0.60]
                        },
                        {
                            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75]
                        },
                    ]
                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 500000]
                },
                true
            ]
        }
    },
    //*******Condotel *********//
    //Max LTV/CLTV for Condotel w/ Fico >= 700 - Purchase and R/T = 70%
    {
        name: "Max LTV/CLTV for Condotel & Credit Score - Purchase and R/T = 70%",
        rules: {
            "if": [
                {
                    "and": [

                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Condotel w/  680 <= Fico < 700 - Purchase and R/T = 65%
    {
        name: "Max LTV/CLTV for Condotel & Credit Score - Purchase and R/T = 65%",
        rules: {
            "if": [
                {
                    "and": [

                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                        },
                        {
                            "<": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Condotel w/ Fico >= 700 - Cash-Out = 65%
    {
        name: "Max LTV/CLTV for Condotel & Credit Score - Cash-Out = 65%",
        rules: {
            "if": [
                {
                    "and": [

                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.refinance_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {

                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.65],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.65]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Condotel w/  680 <= Fico < 700 - Cash-Out = 60%
    {
        name: "Max LTV/CLTV for Condotel & Credit Score - Cash-Out = 60%",
        rules: {
            "if": [
                {
                    "and": [

                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                        },
                        {
                            "<": [{ "var": "scenario.representativeCreditScore" }, 700]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [LoanPurpose.refinance_cash_out],
                                ]

                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60]
                        },
                    ],
                },
                true
            ]
        }
    },
    //Max Cash-Out for Condotel = $250,000
    {
        name: "Max Cash-Out for Condotel = $250,000",
        rules: {
            "if":
                [
                    {
                        "and": [
                            {
                                "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                    },
                    {
                        "<=": [{ "var": "scenario.cashOutAmount" }, 250000]
                    },
                    true
                ]
        }
    },
    //Max Loan Amount for Condotel = $1,500,000
    {
        name: "Max Loan Amount for Condotel = $1,500,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.propertyType" }, PropertyType.condotel]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {
                    "<=": [{ "var": "scenario.baseLoanAmount" }, 1500000]
                },
                true
            ]
        },
    },
    //*******Mortgage History *********//
    //Max Mortgage Lates within last 12 mos = 1x30,0x60,0x90,0x120
    {
        name: "Max Mortgage Lates within last 12 mos = 1x30,0x60,0x90,0x120",
        rules: {
            "if": [
                {
                    "and": [
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] },
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.latePaymentsX30" }, 1]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                        }
                    ]

                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Mortgage Lates 1x30 = 60%
    {
        name: "Max LTV/CLTV for Mortgage Lates 1x30 = 60%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===": [{ "var": "scenario.latePaymentsX30" }, 1]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.60],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.60],
                        }
                    ]
                },
                true
            ]

        },
    },
    // 1x30 not permitted on No Ratio 
    {
        name: "1x30 not permitted on No Ratio",
        rules: {
            "if": [
                {
                    "and": [
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] },
                    ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                        { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                    ]

                },
                true
            ]

        },
    },
    //******** Housing History  */
    // Max LTV/CLTV for 24 <= BK/FC/SS/DIL < 36 mos Purchase = 75% 
    {
        name: "Max LTV/CLTV for Housing Scenario = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "or": [
                                {

                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                            }

                                        ],
                                },
                                {
                                    "and":
                                        [
                                            {
                                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                            },
                                            {
                                                "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                            }

                                        ]

                                },
                                {

                                    "and":
                                        [
                                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                            { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] }

                                        ]
                                },
                            ]
                        },
                        {
                            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase]
                        },
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        }
                    ],
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.75],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.75]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for 24 <= BK/FC/SS/DIL < 36 mos R/T & Cash-Out = 70% 
    {
        name: "Max LTV/CLTV for Housing Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {

                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    }

                                                ],
                                        },
                                        {
                                            "and":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    }

                                                ]

                                        },
                                        {

                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] }

                                                ]
                                        },
                                    ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75]
                            }
                        ],
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.LTV" }, 0.70],
                        },
                        {
                            "<=": [{ "var": "scenario.CLTV" }, 0.70]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Min 1 Yr discharged Ch 13
    {
        name: "Min 1 Yr discharged Ch 13",
        rules: {
            "if": [
                {
                    "and": [
                        { "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national] },
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] },
                    ]

                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 12] },
                        ]
                },
                true
            ]
        }
    },
    //*********** Housing History NO RATIO  */
    //No Ratio: Requires 3+ years from housing event"
    {
        name: "No Ratio: Requires 3+ years from housing event",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                        },
                        {
                            "<": [{ "var": "scenario.debtServCoverageRatio" }, 0.75]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                    },
                                ],
                        },
                        {

                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                    },
                                ]

                        },
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                ]
                        },
                    ]
                },
                true
            ]
        }
    },
    //******* MAX LTV FOR FICO AND DSCR AND LOAN AMOUNT AND LOAN PURPOSE UPPER TABLE*/

    //*********** DSCR >=1 PURCHASE AND R/T */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR >=1 : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                "!": [
                                    {
                                        "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]]
                                    }
                                ]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {

                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV for 2,000,000 < baseLoanAmount <= 2,500,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.LTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR >=1 PURCHASE: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase ) 
    //ONLY THIS ONE INCLUDE PROPERTY TYPE SINCE THERE IS MORE LTV FOR 1-4 Unit 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            },
                            {
                                "in": [{ "var": "scenario.propertyType" }, [PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
                            }
                        ]
                },
                {

                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1 R/T: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.80] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.80] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //700 <= FICO < 720 && PURCHASE && RATE TERM  && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
    //*********** DSCR >=1 CASH-OUT */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR >=1 : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && CASH-OUT && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi ) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=": [
                                            {
                                                "var": "scenario.debtServCoverageRatio"
                                            },
                                            1
                                        ]
                                    },
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {

                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //700 <= FICO < 720 && CASH-OUT && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 680 <= FICO < 700 && CASH-OUT && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 660 <= FICO < 680 && CASH-OUT && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
    // 640 <= FICO < 660 && CASH-OUT && LOAN AMOUNT 

    //DSCR >=1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 50%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.50] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.50] }
                    ]
                },
                true
            ],

        },
    },

    //*********** DSCR <1 PURCHASE AND R/T */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR <1 : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR < 1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {

                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //700 <= FICO < 720 && PURCHASE && RATE TERM  && LOAN AMOUNT 

    //DSCR <1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTVfor 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR >=1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {

                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //680 <= FICO < 700 && PURCHASE && RATE TERM  && LOAN AMOUNT 
    //DSCR <1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //*********** DSCR <1 CASH-OUT */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR <1 : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR < 1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi ) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },

    //700 <= FICO < 720 && CASH-OUT && LOAN AMOUNT 

    //DSCR <1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //680 <= FICO < 700 && PURCHASE && RATE TERM  && LOAN AMOUNT 
    //DSCR <1: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //*********** LIMITS DSCR >=1 CASH-OUT */
    //DSCR <1: 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max Loan Amount x scenario = $ 1,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ],

        },
    },
    //DSCR <1: 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max Loan Amount x scenario = $ 1,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and":
                                    [
                                        {
                                            ">=": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                        },
                                        {
                                            "<": [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]
                                        }

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ],

        },
    },
    //*********** DSCR NO RATIO PURCHASE AND R/T */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR NO RATIO : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR NO RATIO: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //700 <= FICO < 720 && PURCHASE && RATE TERM  && LOAN AMOUNT 

    //DSCR NO RATIO: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //*********** DSCR NO RATIO CASH-OUT */

    //FICO >= 740 && PURCHASE && RATE TERM && LOAN AMOUNT 
    //DSCR NO RATIO : Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && FICO >= 740  && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    // 720 <= FICO < 740 && PURCHASE && RATE TERM && LOAN AMOUNT 

    //DSCR NO RATIO: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi ) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 720 <= FICO < 740 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //700 <= FICO < 720 && PURCHASE && RATE TERM  && LOAN AMOUNT 

    //DSCR NO RATIO: Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //DSCR NO RATIO: Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    }
                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "!==": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },

    /***********FOREIGN NATIONAL GENERAL REQUIREMENTS */
    // Occupancy FOREIGN NATIONAL 
    {
        name: "Occupancy Allowed FN = Investment Property",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { "in": [{ "var": "scenario.occupancy" }, [Occupancy.investment_property]] },
                    true
                ]

        }
    },
    //Minimum and maximum loan amount FOREIGN NATIONAL 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { ">=": [{ "var": "scenario.baseLoanAmount" }, 150000] },
                    true
                ]
        }
    },
    {
        name: "Maximum Loan Amount = $3,000,000",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]

                    },
                    { "<=": [{ "var": "scenario.baseLoanAmount" }, 3000000] },
                    true
                ]

        }
    },
    //Mortgage History FOREIGN NATIONAL
    {
        name: "Max Mortgage Lates = 0x30,0x60,0x90,0x120",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                {
                    "and": [
                        {
                            "<=": [{ "var": "scenario.latePaymentsX30" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX60" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX90" }, 0]
                        },
                        {
                            "<=": [{ "var": "scenario.latePaymentsX120" }, 0]
                        }
                    ]

                },
                true
            ]
        }
    },
    //Housing History FOREIGN NATIONAL BK/FC/SS/Dil/Mod > 36, 
    {
        name: "Housing History BK/FC/SS/Dil/Mod > 36",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                {
                    "and": [
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                    },
                                ]
                        },
                        {

                            "or":
                                [
                                    {
                                        "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                                    },
                                    {
                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                    },
                                ]

                        },
                        {
                            "or":
                                [
                                    { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                ]
                        },
                    ]

                },
                true
            ]
        },
    },
    {
        name: "Minimum DSCR for FN = 1.00",
        rules: {
            "if": [
                {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                },
                {
                    ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1]
                },
                true
            ]
        }
        ,
    },
    //Rural properties non permitted 
    {
        name: "Rural Properties Not Allowed",
        rules: {
            "if":
                [
                    {
                        "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                    },
                    {
                        "===": [{ "var": "scenario.rural" }, false]
                    },
                    true
                ]
        },
    },
    //Maximum cash-out if LTV > 60% FOREIGN NATIONAL
    {
        name: "Maximum Cash-Out for LTV/CLTV > 50% = $750,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or": [
                                    { ">": [{ "var": "scenario.LTV" }, 0.50] },
                                    { ">": [{ "var": "scenario.CLTV" }, 0.50] }
                                ]

                            },
                            {

                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ],
                },
                {
                    "<=": [{ "var": "scenario.cashOutAmount" }, 750000]
                },
                true

            ]
        }
    },
    //Condos and 2-4 Units Purchase & R/T  - Max 70% LTV/CLTV 
    {
        name: "Max LTV/CLTV (Condos and 2-4 Units) for Purchase & R/T = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_no_cash_out, LoanPurpose.purchase]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ]
        }
    },
    //Condos and 2-4 Units Cash Out - Max 65% LTV/CLTV  
    {
        name: "Max LTV/CLTV (Condos and 2-4 Units) for Cash-Out Refi = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.detached_condo, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ]
        }
    },
    //Condotel Purchase and R/T - Max 65% LTV/CLTV 
    {
        name: "Max LTV/CLTV Condotel for Purchase & R/T = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_no_cash_out, LoanPurpose.purchase]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ]
        }
    },
    //Condotel Cash-Out - Max 60% LTV/CLTV 
    {
        name: "Max LTV/CLTV Condotel for Cash-Out Refi = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out]]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]

                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ]
        }
    },
    //Max Loan Amount Condotel = $1,500,000
    {
        name: "Max Loan Amount Condotel = $1,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ]

        }
    },
    //Max Cash-Out Amount Condotel = $250,000
    {
        name: "Max Cash-Out Amount Condotel = $250,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [
                                    {
                                        "var": "scenario.propertyType"
                                    },
                                    [PropertyType.condotel]
                                ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        250000
                    ]
                },
                true
            ]

        }
    },
    /********** FOREIGN NATIONAL RULES INVESTMENT*/
    //************* DSCR >= 1  */
    //***** PURCHASE */
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
    //************* DSCR >= 1  */
    //***** R/T */
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && (LoanPurpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
    //************* DSCR >= 1  */
    //***** CASH-OUT */
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 1: Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && (LoanPurpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 50%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.50] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.50] }
                    ]
                },
                true
            ],

        },
    },
    //************* DSCR >= 0.75 < 1.00  */
    //***** PURCHASE */
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = Purchase) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //************* DSCR >= 0.75 < 1.00  */
    //***** R/T */
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV for   2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = R/T) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //************* DSCR >= 0.75 < 1.00  */
    //***** CASH-OUT */
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV/CLTV for baseLoanAmount <= 2,000,000 && (LoanPurpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.60] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.60] }
                    ]
                },
                true
            ],

        },
    },
    //FOREIGN NATIONAL and DSCR >= 0.75 < 1.00: Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && (LoanPurpose = Cash-Out) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                0.75
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.debtServCoverageRatio"
                                                },
                                                1
                                            ]

                                    }
                                ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ]
                                    ]
                            },
                            {
                                "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                            }

                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.55] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.55] }
                    ]
                },
                true
            ],

        },
    },
]

export default guidelines; 