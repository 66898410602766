import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'jotai';
import { Route, Redirect, Switch } from 'wouter';
import Admin from './components/screens/Admin';
import ScenarioScreen from './components/screens/ScenarioScreen';
import ResultsScreen from './components/screens/ResultsScreen';
import theme from './theme';
import React from 'react';

function App() {
  return (
    <Provider>
      <ChakraProvider theme={theme}>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/" component={ScenarioScreen} />
          <Route path="/results" component={ResultsScreen} />
          <Redirect to="/" />
        </Switch>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
