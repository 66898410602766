import { Amortization, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";
import zipCodes from "../zipCodes.json";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position
    {
        name: "Lien Position Allowed = First Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    //Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                    ]
                ]
        }
    },
    //DocumentationType Allowed = Full Doc, 12 and 24 Bank Stmts, 12 and 24 P&L, WVOE, 12/24 1099, Asset Utilization 
    {
        name: "DocumentationType Allowed = Full Doc, 12 and 24 Bank Stmts, 12 and 24 P&L, WVOE, 12/24 1099, Asset Utilization",
        rules: {
            "in":
                [
                    { "var": "scenario.documentationType" },
                    [
                        DocumentationType._12M_full_doc, DocumentationType.full_doc, DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.asset_utilization, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l
                    ]

                ]
        },
    },
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $4,000,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 4000000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed (the guidelines are confusing but townhouse it's part of Attached so it has to be included)
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo,  Low-Rise Condo, Modular, Mid-Rise Condo, High-Rise Condo, Condotel, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },
    //PROPERTY TYPES RULES 
    //Max LTV/CLTV for Property Type 2-4 Units, Condominium
    {
        name: "Max LTV/CLTV for 2-4 Units, Condominium = 85%",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.propertyType"
                        },
                        [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type Condotel
    {
        name: "Max LTV/CLTV for Condotel = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.condotel]
                            ]
                        },
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Rural not eligible
    {
        name: "Rural not eligible",
        rules: {
            "===":
                [
                    {
                        "var": "scenario.rural"
                    },
                    false
                ]
        },
    },
    /********* INTEREST ONLY */
    // Max LTV/CLTV for interest only = 90% 
    {
        name: "Max LTV/CLTV for interest only = 90%",
        rules: {
            "if":
                [
                    {
                        "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.90
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.90
                                ]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    //HOUSING HISTORY RULES
    //Mortgage History
    {
        name: "Max Mortgage Lates within last 12 mos = 1x30,0x60,0x90,0x120",
        rules: {
            "and":
                [
                    { "<=": [{ "var": "scenario.latePaymentsX30" }, 1] },
                    { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                    { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                    { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                ]
        },
    },
    //BANKRUPTCY, FC,SS,DIL,MCO History
    //Minimum Months Since FC/SS/DIL/MCO = 48 
    //Min Mos Since Bankruptcy Discharge - Ch7 && Ch11 = 48 
    //Min Mos Since Bankruptcy Dismissal - Ch7 && Ch11 = 48 
    //Min Mos Since Bankruptcy Filling Ch13 = 48 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 48",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 48] }
                        ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 48]
                            },
                        ]
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 48]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 48] },
                        ]
                },
            ],
        }
    },
    //OCCUPANCY RESTRICTTIONS 2ND HOME RULES
    //Max LTV/CLTV for Purchase 2nd Home
    {
        name: "Max LTV/CLTV for Purchase 2nd Home = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.purchase]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.second_home
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Rate/Term 2nd Home
    {
        name: "Max LTV/CLTV for Rate/Term 2nd Home = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_no_cash_out]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.second_home
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Cash-Out 2nd Home
    {
        name: "Max LTV/CLTV for Cash-Out 2nd Home = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_cash_out]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.documentationType"
                                },
                                [DocumentationType.full_doc, DocumentationType._12M_full_doc]
                            ]

                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.second_home
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max Loan Amount for 2nd Home = $2,500,000
    {
        name: "Max Loan Amount for 2nd Home = $2,500,000",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.occupancy"
                        },
                        Occupancy.second_home
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2500000
                    ]
                },
                true
            ]
        }
    },
    //OCCUPANCY RESTRICTTIONS INVESTMENT RULES

    //Max LTV/CLTV for Purchase Investment Property :)
    {
        name: "Max LTV/CLTV for Purchase Investment Property = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.purchase]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.investment_property
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Rate/Term Investment Property
    {
        name: "Max LTV/CLTV for Rate/Term Investment Property = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_no_cash_out]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.investment_property
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Cash-Out Investment Property
    {
        name: "Max LTV/CLTV for Cash-Out Investment Property = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_cash_out]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.occupancy"
                                },
                                Occupancy.investment_property
                            ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max Loan Amount for Investment Property = $2,500,000
    {
        name: "Max Loan Amount for Investment Property = $2,500,000",
        rules: {
            "if": [
                {
                    "===": [
                        {
                            "var": "scenario.occupancy"
                        },
                        Occupancy.investment_property
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2500000
                    ]
                },
                true
            ]
        }
    },


    // ****STATE RESTRICTIONS**** 
    /* State Name State CBSA Name CBSA Code
Connecticut CT 
Idaho ID 
Illinois IL 
Louisiana LA
New Jersey NJ 
New York NY 
 
Phoenix-Mesa-Scottsdale, AZ 38060
Sacramento-Roseville, CA 40900
San Francisco-Oakland-Hayward, CA 41860
San Jose-Sunnyvale-Santa Clara, CA 41940
Santa Cruz-Watsonville, CA 42100
Boulder, CO 14500
Breckenridge, CO 14720
Sevierville, TN 42940
Austin-Round Rock, TX 12420 */
    //If either or both of the following apply: 1) the appraisal report identifies the property as a declining market; 2) the subject property is in a state or CBSA in the table at the end of this document, the maximum LTV/CLTV is limited to 85% for purchases and 80% for all refinances and the maximum loan amount is limited to $2MM.
    // Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000
    {
        name: "Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000",
        rules: {
            "if": [
                {
                    "or": [
                        {
                            "in": [
                                {
                                    "var": "scenario.state"
                                },
                                [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]
                            ],
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.zipCode"
                                },
                                [
                                    zipCodes
                                ]
                            ]
                        }

                    ]

                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2000000
                    ]
                },
                true
            ]

        },
    },
    // MORE STATE RESTRICTIONS FOR STATE AND CBSA CODES
    // Max LTV/CLTV for CT, ID, IL, LA, NJ, NY and others CBSA code = 85% 
    {
        name: "Max LTV/CLTV for purchase and state restrictions = 85%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]

                        }
                    ]

                },
                true
            ],
        },
    },
    // for refinances
    {
        name: "Max LTV/CLTV for ref and state restrictions = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]

                        }
                    ]

                },
                true
            ],
        },
    },
    //**WVOE Written Verification of Employment and P&L only *************/
    // Max LTV/CLTV for WVOE and P&L for Purchase= 80%
    {
        name: "Max LTV/CLTV for WVOE and P&L for Purchase = 80%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.documentationType"
                                },
                                [DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                            ]
                        },
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]

                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for WVOE and P&L for R/T = 75%
    {
        name: "Max LTV/CLTV for WVOE and P&L for R/T = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.documentationType"
                                },
                                [DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                            ]
                        },
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.refinance_no_cash_out
                                ]

                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    // Max LTV/CLTV for WVOE and P&L for Cash-Out = 70%
    {
        name: "Max LTV/CLTV for WVOE and P&L for Cash-Out = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.documentationType"
                                },
                                [DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                            ]
                        },
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.refinance_cash_out
                                ]

                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ]
        }
    },
    //Min Credit Score for WVOE and P&L = 680 
    {
        name: "Min Credit Score for WVOE and P&L = 680",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.documentationType"
                        },
                        [DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                    ]
                },
                {
                    ">=": [
                        {
                            "var": "scenario.representativeCreditScore"
                        },
                        680
                    ]
                },
                true
            ]
        }
    },
    //Max Loan Amount for WVOE and P&L = $2,500,000
    {
        name: "Max Loan Amount for WVOE and P&L = $2,500,000",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.documentationType"
                        },
                        [DocumentationType.wvoe, DocumentationType._12M_p_and_l, DocumentationType._24M_p_and_l]
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2500000
                    ]
                },
                true
            ]
        }
    },
    //*************** UNDERWRITING REQUIREMENTS **************/
    //Max DTI = 50%
    {
        name: "Max DTI = 50%",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },

    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = PURCHASE) = MAX LTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE  UPPER TABLE */

    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.90
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.90
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.90
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.90
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,500,000 < baseLoanAmount <= 4,000,000 &&  FICO >= 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                4000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 700 <= FICO < 720
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.90
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.90
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.90
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.90
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 700 <= FICO < 720 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],
        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 90%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.90
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.90
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700  && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700  && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700  && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Loan Purpose = Purchase && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = R/T REFINANCE) = MAX LTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE  UPPER TABLE */

    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ],
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,500,000 < baseLoanAmount <= 4,000,000 &&  FICO >= 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                4000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //FOR FICO 700 <= FICO < 720
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 3,000,000 < baseLoanAmount <= 3,500,000 && 700 <= FICO < 720 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.85
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.85
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700  && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700  && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700  && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,
                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Loan Purpose = Non Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_no_cash_out,
                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******* (FOR OCCUPANCY = PRIMARY RESIDENCE AND LOAN PURPOSE = CASH-OUT REFINANCE) = MAX LTV FOR FICO AND LOAN AMOUNT AND DOCUMENTATION TYPE  UPPER TABLE */

    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 700 <= FICO < 720
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 700 <= FICO < 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 700 <= FICO < 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 700 <= FICO < 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 700 <= FICO < 720 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700  && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 680 <= FICO < 700  && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 680 <= FICO < 700  && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 680  && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,

                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 680 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,
                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 680 && Loan Purpose = Cash-Out Refinance && Occupancy = Primary Residence
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },

                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.refinance_cash_out,
                                    ]

                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //************** LIMITS TABLE PRIMARY RESIDENCE ********//

    //Minimum credit score = 660  
    {
        name: "Minimum credit score = 660",
        rules: {
            ">=": [{ "var": "scenario.representativeCreditScore" }, 660]
        }
    },
    // Max LoanAmount for Cash-Out Refinance Primary Residence = 3,000,000 (FULL AND ALT DOC)
    // This is true if FICO >= 680 ( there is another rule if FICO < 680)
    {
        name: "Max LoanAmount for Cash-Out Refinance Primary Residence = 3,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.refinance_cash_out
                                ]
                        },
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.occupancy"
                                    },
                                    Occupancy.primary_residence
                                ]
                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    680
                                ]
                        }
                    ]

                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3000000
                        ]
                },
                true
            ],

        },
    },
    // Max baseLoanAmount for Fico < 680 = 2,500,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 2,500,000",
        rules: {
            "if": [
                {
                    "<":

                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            680
                        ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            2500000
                        ]
                },
                true
            ],

        }
    },
    // Max baseLoanAmount for 680 <= Fico < 700 = 3,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 3,000,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]

                            }
                        ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3000000
                        ]
                },
                true

            ]
        }

    },
    // Max baseLoanAmount for 700 <= Fico < 720 = 3,500,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 3,500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":

                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]

                            }
                        ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3500000
                        ]
                },
                true

            ]
        }

    },
    // Max baseLoanAmount for Fico >= 720 = 4,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x representative Credit Score scenario = 4,000,000",
        rules: {
            "if": [
                {

                    ">=":

                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            720
                        ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            4000000
                        ]
                },
                true

            ]
        }

    },
    /************** GUIDELINES RESTRICTIONS  */
    /* Guideline restrictions for Non Permanent Residents 2.3.1.3 NON-PERMANENT RESIDENT ALIEN (2/26/2024)
     o Standard or Alt Documentation limited to 24-months only. Maximum LTV/CLTV limited to 80%. 
     o DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.
     o Non-occupant co-borrowers are not allowed.
     o Gift funds are not allowed.*/

    {
        name: "Standard or Alt Documentation limited to 24-months only",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.citizenship"
                            },
                            Citizenship.non_permanent_resident_alien
                        ]
                },
                {
                    "in":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            [DocumentationType.full_doc, DocumentationType.asset_utilization, DocumentationType._24M_1099, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType.dscr]
                        ]
                },
                true
            ]
        },
    },
    {
        name: "Maximum LTV/CLTV limited to 80% for Non Permanent Residents",
        rules: {
            "if":
                [
                    {
                        "===":
                            [
                                {
                                    "var": "scenario.citizenship"
                                },
                                Citizenship.non_permanent_resident_alien
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]

                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    {
        name: "DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.citizenship"
                                        },
                                        Citizenship.non_permanent_resident_alien
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.dscr
                                    ]
                            }
                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        },
    },
    /** 
     * 2.3.4 FIRST TIME HOMEBUYERS (FTHB) (2/26/2024)
     * Primary residence only.
     • DTI may not exceed 45%. ( I am ignoring this one for now)
     • Minimum six (6) months of reserves.
     • 12-month rental history, reflecting 0x30, documented per Section 2.5.5 - Housing History.
     o First time homebuyers with less than 12-month rental history:
     ▪ DTI may not exceed 43%.
     ▪ LTV may not exceed 80%.
     ▪ Any available portion of a 12-month housing history must be paid as agreed.
 */
    {
        name: "First Time Home Buyer Primary residence only.",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                    },
                    true
                ]

        },
    },
    {
        name: "DTI FTHB = 43%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {
        name: "Max LTV/CLTV for FTHB = 80%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {

        name: "Max Mortgage Lates within last 12 mos for FTHB= 0x30,0x60,0x90,0x120",
        rules:
        {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]

                    },
                    true
                ]

        },
    }



]; // end of rules





