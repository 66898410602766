
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

const sofrai30AVGAtom = atom<number>(0);

async function getSOFR(): Promise<number> {
    const response = await fetch('https://markets.newyorkfed.org/api/rates/all/latest.json');
    const json = await response.json();
    const SOFRObject = json.refRates?.find((object: { type: string; }) => object?.type === 'SOFRAI');
    const SOFR30AVG = SOFRObject.average30day;
    return SOFR30AVG;
}

export const useFetchSofrai = () => {
    const [sofrai, setSofrai] = useAtom(sofrai30AVGAtom);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState(null);

    useEffect(() => {
        getSOFR()
            .then((sofrai) => {
                setSofrai(sofrai);
                setloading(false);
            })
            .catch((error) => {
                seterror(error);
                setloading(false);
            });
    }, []);

    return { sofrai, loading, error };
};