
import React from 'react';
import {
    Box,
    Text,
} from '@chakra-ui/react';

interface Props {
    name: string;
    children: React.ReactNode;
}

const FacetContainer: React.FC<Props> = (props) => {
    return (
        <Box paddingTop={8}>
            <Text marginBottom={4} fontSize={"sm"} fontWeight={"semibold"}>{props.name.toUpperCase()}</Text>
            {props.children}
        </Box>
    )
}

export default FacetContainer;