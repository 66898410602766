import {
  InputGroup,
  InputLeftAddon,
  Flex,
  Box,
  NumberInput,
  NumberInputField,
  FormLabel,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import {
  latePaymentsX120Atom,
  latePaymentsX30Atom,
  latePaymentsX60Atom,
  latePaymentsX90Atom,
} from '../../services/scenarioService';
import React from 'react';

const MortgageRow = () => {
  const [mortageLatePaymentsX30, setMortageLatePaymentsX30] =
    useAtom(latePaymentsX30Atom);
  const [mortageLatePaymentsX60, setMortageLatePaymentsX60] =
    useAtom(latePaymentsX60Atom);
  const [mortageLatePaymentsX90, setMortageLatePaymentsX90] =
    useAtom(latePaymentsX90Atom);
  const [mortageLatePaymentsX120, setMortageLatePaymentsX120] =
    useAtom(latePaymentsX120Atom);

  const handleMortgageLate30 = (value: string) => {
    setMortageLatePaymentsX30(Number(value));
  };
  const handleMortgageLate60 = (value: string) => {
    setMortageLatePaymentsX60(Number(value));
  };
  const handleMortgageLate90 = (value: string) => {
    setMortageLatePaymentsX90(Number(value));
  };
  const handleMortgageLate120 = (value: string) => {
    setMortageLatePaymentsX120(Number(value));
  };

  return (
    <Box>
      <FormLabel color="#0f1162" bg='none'> Mortgage Row </FormLabel>
      <Flex>
        <Box display={'flex'}>
          <InputGroup>
            <InputLeftAddon
              children={'x30'}
              width="3rem"
              fontSize={'1xs'}
              fontWeight={'extrabold'}
              borderRadius={'none'}
              color='#053742ff'
              bg='none'
              border='none'
            />
            <NumberInput
              color="black"
              onChange={handleMortgageLate30}
              value={mortageLatePaymentsX30}
            >
              <NumberInputField color="black" padding={1} />
            </NumberInput>
          </InputGroup>
        </Box>
        <Box display={'flex'} w="40%">
          <InputGroup>
            <InputLeftAddon
              children="x60"
              width="3rem"
              fontSize={'1xs'}
              fontWeight={'extrabold'}
              color='#053742ff'
              bg='none'
              border='none'
            />

            <NumberInput
              color="black"
              onChange={handleMortgageLate60}
              value={mortageLatePaymentsX60}
            >
              <NumberInputField color="black" padding={1} />
            </NumberInput>
          </InputGroup>
        </Box>
        <Box display={'flex'} w="40%">
          <InputGroup>
            <InputLeftAddon
              children="x90"
              width="3rem"
              fontSize={'1xs'}
              fontWeight={'extrabold'}
              color='#053742ff'
              bg='none'
              border='none'
            />
            <NumberInput
              color="black"
              onChange={handleMortgageLate90}
              value={mortageLatePaymentsX90}
            >
              <NumberInputField color="black" padding={1} />
            </NumberInput>
          </InputGroup>
        </Box>
        <Box display={'flex'} w="40%">
          <InputGroup>
            <InputLeftAddon
              children="x120"
              width="3.5rem"
              fontSize={'1xs'}
              fontWeight={'extrabold'}
              color='#053742ff'
              bg='none'
              border='none'
            />
            <NumberInput
              color="black"
              onChange={handleMortgageLate120}
              value={mortageLatePaymentsX120}
            >
              <NumberInputField
                color="black"
                padding={1}
              />
            </NumberInput>
          </InputGroup>
        </Box>
      </Flex>
    </Box>
  );
};

export default MortgageRow;
