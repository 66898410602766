import { Adjustment } from '../../../../classes/product';
import { Citizenship } from '../../../../classes/scenario';
import { compoundCLTVAdjustments } from '../../utilsSummit/ruleUtils';
import { RulesLogic } from 'json-logic-js'

export type RowOthers = { [key: string]: string | number };

export default function adjustmentsDSCRRatio(dscrAditionalAdjTable: RowOthers[], LTVs: number[], ranges: string[]): Adjustment[] {
    const adjustments: Adjustment[] = [];

    dscrAditionalAdjTable.forEach((row) => {
        switch (row.dscrAditionalAdjustments) {
            case "Foreign Credit": {
                const foreingCreditRule: RulesLogic = {
                    "===": [{ "var": "scenario.citizenship" }, Citizenship.foreign_national]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR FN", row, LTVs, ranges, foreingCreditRule))
                break;
            }
            case ">=1.25": {
                const debtServCoverageRatioRule: RulesLogic = {
                    ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.25]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR >=1.25", row, LTVs, ranges, debtServCoverageRatioRule))
                break;
            }
            case "1.00-1.24": {
                const debtServCoverageRatio_1_25_Rule: RulesLogic = {
                    "and": [
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1] },
                        { "<=": [{ "var": "scenario.debtServCoverageRatio" }, 1.24] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR 1.00-1.24", row, LTVs, ranges, debtServCoverageRatio_1_25_Rule))
                break;
            }
            case ".75-.99": {
                const debtServCoverageRatio_0_75_0_99_Rule: RulesLogic = {
                    "and": [
                        { ">=": [{ "var": "scenario.debtServCoverageRatio" }, 0.75] },
                        { "<=": [{ "var": "scenario.debtServCoverageRatio" }, 0.99] }
                    ]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR .75-.99", row, LTVs, ranges, debtServCoverageRatio_0_75_0_99_Rule))
                break;
            }
            case "<.75": {
                const debtServCoverageRatio75Rule: RulesLogic = {
                    "<": [{ "var": "scenario.debtServCoverageRatio" }, 0.75]
                }
                adjustments.push(...compoundCLTVAdjustments("DSCR <.75", row, LTVs, ranges, debtServCoverageRatio75Rule))
                break;
            }

            default: return;
        }
    })
    return adjustments;
}