import { Amortization, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";
import zipCodes from "../zipCodes.json"

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position
    {
        name: "Lien Position Allowed = First Lien",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },
    // Documentation Type
    {
        name: "Documentation Type Allowed = DSCR",
        rules: {
            "===": [{ "var": "scenario.documentationType" }, DocumentationType.dscr]
        }
    },
    //Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenship Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in":
                [
                    { "var": "scenario.citizenship" },
                    [
                        Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                    ]
                ]
        }
    },
    /******* AMORTIZATIONS */
    //Minimum credit score for DSCR >= 1 = 640 (it should not be IO)
    {
        name: "Minimum credit score for scenario = 660",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                { "var": "product.amortization" },
                                [Amortization._15_yr_fixed, Amortization._30_yr_fixed, Amortization._40_yr_fixed, Amortization._30_yr_fixed_arm_5_6]
                            ]

                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]

                        }
                    ]

                },
                {
                    ">=":
                        [
                            { "var": "scenario.representativeCreditScore" },
                            640
                        ]
                },
                true
            ]

        }
    },
    //Minimum credit score for DSCR < 1 = 660  
    {
        name: "Minimum credit score for scenario = 660",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                { "var": "product.amortization" },
                                [Amortization._15_yr_fixed, Amortization._30_yr_fixed, Amortization._40_yr_fixed, Amortization._30_yr_fixed_arm_5_6]
                            ]

                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        }
                    ]

                },
                {
                    ">=":
                        [
                            { "var": "scenario.representativeCreditScore" },
                            660
                        ]
                },
                true
            ]

        }
    },
    /******** INTEREST ONLY */
    // Min Credit Score for interest only 
    {
        name: "Min Credit Score for interest only = 680",
        rules: {
            "if": [
                {
                    "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                },
                {
                    ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
                },
                true
            ]
        },
    },
    // Max LTV/CLTV for interest only purchase and R/T = 75% for IO 
    {
        name: "Max LTV/CLTV for interest only purchase and R/T = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                        },
                        {
                            "in":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    [
                                        LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out
                                    ]
                                ]
                        }
                    ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                { "var": "scenario.LTV" },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                { "var": "scenario.CLTV" },
                                0.75
                            ]
                        },
                    ]
                },
                true
            ]
        },

    },
    // Max LTV/CLTV for interest only cash out = 70%
    {
        name: "Max LTV/CLTV for interest only cash out = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                        },
                        {
                            "===":
                                [
                                    { "var": "scenario.loanPurpose" },
                                    LoanPurpose.refinance_cash_out
                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                { "var": "scenario.LTV" },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                { "var": "scenario.CLTV" },
                                0.70
                            ]
                        },
                    ]
                },
                true
            ]
        },
    },
    //******** INVESTOR EXPERIENCE */
    // First Time Home Buyer Allowed = No
    {
        name: "First Time Home Buyer Allowed = No",
        rules: {
            "===": [{ "var": "scenario.firstTimeHomeBuyer" }, false]
        }
    },
    //************** GENERAL REQUIREMENTS ********//
    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $150,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $3,500,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 3500000]
        }
    },
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "===":
                [
                    { "var": "scenario.occupancy" },
                    Occupancy.investment_property
                ]
        }
    },
    //Property Types Allowed (the guidelines are confusing but townhouse it's part of Attached so it has to be included)
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo,  Low-Rise Condo, Modular, Mid-Rise Condo, High-Rise Condo, Condotel, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.condotel, PropertyType.townhouse]]
        }
    },
    //PROPERTY TYPES RULES 
    // Rural not eligible
    {
        name: "Rural not eligible",
        rules: {
            "===":
                [
                    {
                        "var": "scenario.rural"
                    },
                    false
                ]
        },
    },
    //Max LTV/CLTV for Property Type 2-4 Units, Condominium - purchase
    {
        name: "Max LTV/CLTV for 2-4 Units, Condominium = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.purchase
                            ]
                        }
                    ]

                },
                {
                    "and":
                        [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                            }
                        ]

                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type 2-4 Units, Condominium - refinance
    {
        name: "Max LTV/CLTV for 2-4 Units, Condominium Ref = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]
                            ]
                        }
                    ]

                },
                {
                    "and":
                        [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.70
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.70
                                ]
                            }
                        ]

                },
                true
            ]
        }
    },
    //Max LTV/CLTV for Property Type Condotel
    {
        name: "Max LTV/CLTV for purchase and ref Condotel = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.condotel]
                            ]
                        },
                        {
                            "===": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                LoanPurpose.purchase
                            ]
                        }
                    ]
                },
                {
                    "and":
                        [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.75
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.75
                                ]
                            }
                        ]

                },
                true
            ]
        }
    },
    //Max LTV/CLTV for refinance Property Type Condotel (in the guidelines just says "Refinance", according to Mike that is cash-out and non cash-out refinance)
    {
        name: "Max LTV/CLTV for refinance Condotel = 65%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "scenario.propertyType"
                                },
                                [PropertyType.condotel]
                            ]
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.loanPurpose"
                                },
                                [
                                    LoanPurpose.refinance_cash_out,
                                    LoanPurpose.refinance_no_cash_out
                                ]
                            ]
                        }
                    ]
                },
                {
                    "and":
                        [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.65
                                ]
                            }
                        ]

                },
                true
            ]
        }
    },
    //Max Loan Amount for Property Type Condotel
    {
        name: "Max Loan Amount for Condotel = $ 1,500,000",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.propertyType"
                        },
                        [PropertyType.condotel]
                    ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        1500000
                    ]
                },
                true
            ]
        }
    },
    //MAX CASH ON HAND FOR LTV 
    /// if LTV >= 0.65 then max cashOnHand = $ 500,000 
    //Total equity withdrawn cannot exceed these limit (Not applicable to Delayed Financing transactions)
    {
        name: "Max Cash on Hand for LTV >= 65% = $500,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=": [

                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                            },
                            {
                                "===": [
                                    {
                                        "var": "scenario.delayedFinancing"
                                    },
                                    false
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        500000
                    ]
                },
                true
            ]
        },
    },
    /// if LTV < 0.65 then max cashOnHand = $ 1,000,000 
    {
        name: "Max Cash on Hand for LTV < 65% = $1,000,000",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<": [

                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.65
                                ]
                            },
                            {
                                "===": [
                                    {
                                        "var": "scenario.delayedFinancing"
                                    },
                                    false
                                ]
                            }
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.cashOutAmount"
                        },
                        1000000
                    ]
                },
                true
            ]
        },
    },
    //HOUSING HISTORY RULES
    //Mortgage History - There are no latePayments of 60 accepted 
    {
        name: "Max Mortgage Lates within last 12 mos = 0x60,0x90,0x120",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]

        }
    },
    //HOUSING HISTORY RESTRICTTIONS
    // No restrictions fo 0x30, 1x30 
    // Instead for 2x30, 3x30, 4x30 and so on  Max LTV = 70 % (this is basically 0x60x12 rule) purchase 
    {
        name: "Max LTV/CLTV for scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            { ">": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] },
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.70] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.70] }
                    ]
                },
                true
            ]

        }
    },
    //2x30, 3x30, 4x30 and so on  Max LTV/CLTV = 65 % (this is basically 0x60x12 rule) refinance
    {
        name: "Max LTV/CLTV for scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            { ">": [{ "var": "scenario.latePaymentsX30" }, 1] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] },
                        ]
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.65] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.65] }
                    ]
                },
                true
            ]

        }
    },
    //HOUSING EVENT SEASONING RESTRICTIONS
    //BK/FC/SS/DIL History
    //Minimum Months Since BK/FC/SS/DIL = 24 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 24",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                        ],


                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                            },
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                        ]
                },
            ]

        }
    },
    // MAX LTV FOR HOUSING EVENT SEASONING RESTRICTIONS ( NO RESTRICTIONS FOR >= 36 MONTHS)
    //****  24 months <= BK < 36 since FC/SS/DIL/MCO BK7/BK11/BK13 for purchase ****
    {
        name: "Max LTV/CLTV for housing event scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "or":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.purchase] }
                        ],
                },
                {
                    "and": [
                        { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                        { "<=": [{ "var": "scenario.CLTV" }, 0.75] },
                    ]
                },
                true

            ],
        },
    },
    // REFINANCE HOSING EVENT SEASONING RESTRICTIONS 
    //****  24 months <= BK < 36 since FC/SS/DIL/MCO BK7/BK11/BK13 for refinance ****
    {
        name: "Max LTV/CLTV for housing event scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "or":
                                    [
                                        {
                                            "and":
                                                [
                                                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 24] },
                                                    { "<": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 36] },
                                                ],


                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDischarge" }, 36]
                                                    },
                                                ],
                                        },
                                        {
                                            "or":
                                                [
                                                    {
                                                        ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 24]
                                                    },
                                                    {
                                                        "<": [{ "var": "scenario.termBankruptcyDismissal" }, 36]
                                                    },
                                                ]
                                        },
                                        {
                                            "or":
                                                [
                                                    { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 24] },
                                                    { "<": [{ "var": "scenario.termBankruptcyFiling" }, 36] },
                                                ]
                                        },
                                    ],
                            },
                            { "in": [{ "var": "scenario.loanPurpose" }, [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]] }
                        ],
                },
                { "<=": [{ "var": "scenario.LTV" }, 0.75] },
                true

            ],
        },
    },
    // ****STATE RESTRICTIONS****
    /* State Name State CBSA Name CBSA Code
Connecticut CT 
Idaho ID 
Illinois IL 
Louisiana LA
New Jersey NJ 
New York NY 
 
Phoenix-Mesa-Scottsdale, AZ 38060
Sacramento-Roseville, CA 40900
San Francisco-Oakland-Hayward, CA 41860
San Jose-Sunnyvale-Santa Clara, CA 41940
Santa Cruz-Watsonville, CA 42100
Boulder, CO 14500
Breckenridge, CO 14720
Sevierville, TN 42940
Austin-Round Rock, TX 12420 */
    // Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000
    {
        name: "Max Loan Amount for CT, ID, IL, LA, NJ, NY and others CBSA code = $2,000,000",
        rules: {
            "if": [
                {
                    "or": [
                        {
                            "in": [
                                {
                                    "var": "scenario.state"
                                },
                                [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]
                            ],
                        },
                        {
                            "in": [
                                {
                                    "var": "scenario.zipCode"
                                },
                                [
                                    zipCodes
                                ]
                            ]
                        }

                    ]

                },
                {
                    "<=": [
                        {
                            "var": "scenario.baseLoanAmount"
                        },
                        2000000
                    ]
                },
                true
            ]

        },
    },
    // MORE STATE RESTRICTIONS FOR STATE AND CBSA CODES
    // Max LTV/CLTV for purchase and state restrictions = 75% 
    {
        name: "Max LTV/CLTV for purchase and state restrictions = 75%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "===":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    LoanPurpose.purchase
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]

                        }
                    ]

                },
                true
            ],
        },
    },
    // Max LTV/CLTV for refinance and state restrictions = 70% 
    {
        name: "Max LTV/CLTV for ref and state restrictions = 70%",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            "in":
                                [
                                    {
                                        "var": "scenario.loanPurpose"
                                    },
                                    [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]
                                ]
                        },
                        {
                            "or":
                                [
                                    {
                                        "in": [
                                            { "var": "scenario.state" },
                                            [State.connecticut, State.idaho, State.illinois, State.louisiana, State.new_jersey, State.new_york]

                                        ]
                                    },
                                    {
                                        "in": [
                                            { "var": "scenario.zipCode" },
                                            zipCodes
                                        ]
                                    }

                                ]
                        }
                    ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]

                        }
                    ]
                },
                true
            ],
        },
    },
    //*******  LOAN PURPOSE = PURCHASE  && DSCR >=1 && MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    //FOR FICO >= 700 && DSCR >= 1 
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 && Loan Purpose = Purchase && DSCR >= 1 
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.80
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.80
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,500,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV for 2,500,000 < baseLoanAmount <= 3,000,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 640 <= FICO < 660 && DSCR >= 1 
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660  &&  Loan Purpose = Purchase && DSCR >= 1 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 && 640 <= FICO < 660 &&  Loan Purpose = Purchase && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]

                        }
                    ]
                },
                true
            ],

        },
    },


    //******* (LOAN PURPOSE = R/T REFINANCE && CASH-OUT) = MAX LTV FOR FICO AND LOAN AMOUNT AND DSCR >= 1  UPPER TABLE */
    // FOR FICO >= 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 &&  Loan Purpose = Non Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV for 3,000,000 < baseLoanAmount <= 3,500,000 &&  FICO >= 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 700 &&  Loan Purpose = R/T && DSCR >= 1
    //FOR R/T IN THIS CASE SINCE IT'S DIFFERENT FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 700 &&  Loan Purpose = R/T && DSCR >= 1
    //FOR CASH-OUT IN THIS CASE SINCE IT'S DIFFERENT FROM R/T
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 660 <= FICO < 700 &&  Loan Purpose = Non Cash-Out && Cash-Out && Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 && 660 <= FICO < 700 &&  Loan Purpose = Non Cash-Out && Cash-Out Refinance && DSCR >= 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out, LoanPurpose.refinance_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 640 <= FICO < 660
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 640 <= FICO < 660 &&  Loan Purpose = R/T && DSCR >= 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 640 <= FICO < 660 &&  Loan Purpose = R/T && DSCR >= 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                640
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    // LIMITS NON AVAILABLE FOR TABLE DSCR >=1 
    // Purchase only and R/T for FICO >= 700 and  3,000,000 <loanAmount <= 3,500,000 && (NON CASH-OUT REF ALLOWED) 
    {
        name: "Purchase and R/T only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3500000
                                    ]
                            },
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "in": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]
                    ]
                },
                true
            ]
        },
    },
    // Purchase only for 660 <= FICO < 700 and  2,500,000 <loanAmount <= 3,000,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    // Purchase and R/T only for 640 <= FICO < 660 and  loanAmount <= 1,500,000 (NON CASH-OUT REF ALLOWED)
    {
        name: "Purchase and R/T only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "in": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]
                    ]
                },
                true
            ]
        },
    },
    // Purchase only for 640 <= FICO < 660 and  1,500,000 <loanAmount <= 3,000,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        640
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1500000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                ">=": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    //*******  LOAN PURPOSE = PURCHASE  && DSCR < 1 && MAX LTV FOR FICO AND LOAN AMOUNT UPPER TABLE */
    // FOR PURCHASE && DSCR < 1 
    //FOR FICO >= 700 
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 && Loan Purpose = Purchase && DSCR < 1 
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 2,500,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,500,000 < baseLoanAmount <= 3,000,000 &&  FICO >= 700 && Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 2,000,000 < baseLoanAmount <= 3,000,000 && 660 <= FICO < 700 &&  Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                3000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 660 <= FICO < 680
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 660 <= FICO < 680 &&  Loan Purpose = Purchase && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                660
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    }
                                ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        LoanPurpose.purchase,

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    // FOR R/T AND CASH-OUT && DSCR < 1 
    //FOR FICO >= 700 
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && FICO >= 700 && Loan Purpose = R/T and Cash-Out && DSCR < 1 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 &&  FICO >= 700 && Loan Purpose = R/T and Cash-Out && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 &&  FICO >= 700 && Loan Purpose = R/T and Cash-Out && DSCR < 1
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_cash_out, LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //FOR FICO 680 <= FICO < 700
    //Max LTV/CLTV for baseLoanAmount <= 1,000,000 && 680 <= FICO < 700 &&  Loan Purpose = R/T && DSCR < 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //Max LTV/CLTV for 1,000,000 < baseLoanAmount <= 1,500,000 && 680 <= FICO < 700 &&  Loan Purpose = R/T && DSCR < 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1000000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for 1,500,000 < baseLoanAmount <= 2,000,000 && 660 <= FICO < 700 &&  Loan Purpose = R/T && DSCR < 1
    //ONLY FOR R/T IN THIS CASE SINCE IT'S N/A FOR CASH OUT
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "and": [
                                    {
                                        ">":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                1500000
                                            ]
                                    },
                                    {
                                        "<=":
                                            [
                                                {
                                                    "var": "scenario.baseLoanAmount"
                                                },
                                                2000000
                                            ]
                                    },


                                ]

                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ]
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ]
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [LoanPurpose.refinance_no_cash_out]

                                    ]

                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.debtServCoverageRatio"
                                        },
                                        1

                                    ]
                            }
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    // LIMITS NON AVAILABLE FOR TABLE DSCR < 1 
    // Purchase only for FICO >= 700 and  2,000,000 <loanAmount <= 3,000,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [

                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    // Purchase and R/T only for 680 <= FICO < 700 and loanAmount <= 2,000,000 (NON CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase and R/T only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "in": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        [LoanPurpose.purchase, LoanPurpose.refinance_no_cash_out]
                    ]
                },
                true
            ]
        },
    },

    // Purchase only for 680 <= FICO < 700 and  2,000,000 <loanAmount <= 3,000,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                ">":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        3000000
                                    ]
                            },
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    // Purchase only for 660 <= FICO < 680 and  loanAmount <= 1,000,000 (NON R/T and CASH-OUT FOR THIS FICO AND AMOUNT)
    {
        name: "Purchase only for scenario",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        660
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        1000000
                                    ]
                            },
                            {
                                "<": [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                            }

                        ]
                },
                {
                    "===": [
                        {
                            "var": "scenario.loanPurpose"
                        },
                        LoanPurpose.purchase
                    ]
                },
                true
            ]
        },
    },
    //************** LIMITS TABLE PRIMARY RESIDENCE ********//

    // Max LoanAmount FOR FICO >= 700 && DSCR >= 1 = 3,500,000 (FULL AND ALT DOC) 
    {
        name: "Max LoanAmount for credit score = 3,500,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    700
                                ]
                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        }
                    ]

                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3500000
                        ]
                },
                true
            ],

        },
    },

    // Max baseLoanAmount for 640 <= Fico < 700 && DSCR >= 1 = 3,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 3,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    640
                                ]
                        },
                        {
                            "<":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    700
                                ]

                        },
                        {
                            ">=":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        }
                    ]
                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3000000
                        ]
                },
                true
            ],

        }
    },
    // Max baseLoanAmount for 680 >= Fico && DSCR < 1 = 3,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 3,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    680
                                ]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        }
                    ]

                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            3000000
                        ]
                },
                true
            ],

        }
    },
    // Max baseLoanAmount for 660 <= Fico < 700 && DSCR < 1 = 1,000,000 (FULL AND ALT DOC)
    {
        name: "Max Loan Amount x scenario = 1,000,000",
        rules: {
            "if": [
                {
                    "and": [
                        {
                            ">=":

                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    660
                                ]
                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.representativeCreditScore"
                                    },
                                    700
                                ]

                        },
                        {
                            "<":
                                [
                                    {
                                        "var": "scenario.debtServCoverageRatio"
                                    },
                                    1
                                ]
                        },
                    ]

                },
                {
                    "<=":

                        [
                            {
                                "var": "scenario.baseLoanAmount"
                            },
                            1000000
                        ]
                },
                true
            ],

        }
    },
    /************** GUIDELINES RESTRICTIONS  */
    /* Guideline restrictions for Non Permanent Residents 2.3.1.3 NON-PERMANENT RESIDENT ALIEN (2/26/2024)
     o Standard or Alt Documentation limited to 24-months only. Maximum LTV/CLTV limited to 80%. 
     o DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.
     o Non-occupant co-borrowers are not allowed.
     o Gift funds are not allowed.*/

    {
        name: "Standard or Alt Documentation limited to 24-months only",
        rules: {
            "if": [
                {
                    "===":
                        [
                            {
                                "var": "scenario.citizenship"
                            },
                            Citizenship.non_permanent_resident_alien
                        ]
                },
                {
                    "in":
                        [
                            {
                                "var": "scenario.documentationType"
                            },
                            [DocumentationType.full_doc, DocumentationType.asset_utilization, DocumentationType._24M_1099, DocumentationType._24M_business_bank_stmts, DocumentationType._24M_p_and_l, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType.dscr]
                        ]
                },
                true
            ]
        },
    },
    {
        name: "Maximum LTV/CLTV limited to 80% for Non Permanent Residents",
        rules: {
            "if":
                [
                    {
                        "===":
                            [
                                {
                                    "var": "scenario.citizenship"
                                },
                                Citizenship.non_permanent_resident_alien
                            ]
                    },
                    {
                        "and": [
                            {
                                "<=": [
                                    {
                                        "var": "scenario.LTV"
                                    },
                                    0.80
                                ]

                            },
                            {
                                "<=": [
                                    {
                                        "var": "scenario.CLTV"
                                    },
                                    0.80
                                ]
                            }
                        ]
                    },
                    true
                ]
        },
    },
    {
        name: "DSCR Documentation (Investment Only): Maximum LTV/CLTV 75%.",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.citizenship"
                                        },
                                        Citizenship.non_permanent_resident_alien
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.documentationType"
                                        },
                                        DocumentationType.dscr
                                    ]
                            }
                        ]

                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]

                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ]
        },
    },
    /** 
     * 2.3.4 FIRST TIME HOMEBUYERS (FTHB) (2/26/2024)
     * Primary residence only.
     • DTI may not exceed 45%. ( I am ignoring this one for now)
     • Minimum six (6) months of reserves.
     • 12-month rental history, reflecting 0x30, documented per Section 2.5.5 - Housing History.
     o First time homebuyers with less than 12-month rental history:
     ▪ DTI may not exceed 43%.
     ▪ LTV may not exceed 80%.
     ▪ Any available portion of a 12-month housing history must be paid as agreed.
 */
    {
        name: "First Time Home Buyer Primary residence only.",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "===": [{ "var": "scenario.occupancy" }, Occupancy.primary_residence]
                    },
                    true
                ]

        },
    },
    {
        name: "DTI FTHB = 43%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {
        name: "Max LTV/CLTV for FTHB = 80%",
        rules: {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "<=": [{ "var": "scenario.debtToIncomeRatio" }, 43]
                    },
                    true
                ]

        },
    },
    {

        name: "Max Mortgage Lates within last 12 mos for FTHB= 0x30,0x60,0x90,0x120",
        rules:
        {
            "if":
                [
                    {

                        "===": [{ "var": "scenario.firstTimeHomeBuyer" }, true]
                    },
                    {
                        "and": [
                            { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                            { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
                        ]

                    },
                    true
                ]

        },
    }



]; // end of rules





