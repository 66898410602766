import { BankruptcyType, Citizenship, DocumentationType, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = Second",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.second]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in": [
                { "var": "scenario.citizenship" },
                [
                    Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                ],
            ]
        }
    },
    // Only cash-out ref 
    {
        name: "Only Cash-Out Refinance",
        rules: {
            "===": [{ "var": "scenario.loanPurpose" }, LoanPurpose.refinance_cash_out]
        }
    },
    // State Limits => TX Cashout on Primary Residence Limited to 80% CLTV
    {
        name: "TX Cashout on Primary Residence Limited to 80% CLTV",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "===":
                                    [
                                        { "var": "scenario.state" },
                                        State.texas
                                    ]
                            },
                            {
                                "===":
                                    [
                                        { "var": "scenario.loanPurpose" },
                                        LoanPurpose.refinance_cash_out
                                    ]
                            },
                            {
                                "===":
                                    [
                                        { "var": "scenario.occupancy" },
                                        Occupancy.primary_residence
                                    ]
                            }
                        ]

                },
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        { "var": "scenario.LTV" },
                                        0.80
                                    ]
                            },
                            {
                                "<=":
                                    [
                                        { "var": "scenario.CLTV" },
                                        0.80
                                    ]

                            }
                        ]

                },

                true
            ]
        }
    },
    // Documentation Type Allowed = Full Doc, 12M Full Doc, 12 Psnal or Business Bank Stmts
    {
        name: "Documentation Type Allowed = Full Doc, 12M Full Doc, 12 Psnal or Business Bank Stmts",
        rules: {
            "in": [
                { "var": "scenario.documentationType" },
                [
                    DocumentationType.full_doc, DocumentationType._12M_full_doc, DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts
                ]
            ]
        }

    },
    // Self-Employed Bor = True for all alternative documentationType allowed 
    {
        name: "Self-Employed Bor = True",
        rules: {
            "if": [
                {
                    "in": [
                        { "var": "scenario.documentationType" },
                        [
                            DocumentationType._12M_personal_bank_stmts, DocumentationType._12M_business_bank_stmts
                        ]
                    ],

                },
                {
                    "===":
                        [
                            { "var": "scenario.selfEmployed" },
                            true
                        ]
                },
                true
            ]

        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum and maximum loan amount 
    {
        name: "Minimum Loan Amount = $ 150,000",
        rules: {
            ">=": [{ "var": "scenario.otherFinancing" }, 150000]
        }
    },
    {
        name: "Maximum Loan Amount = $ 500,000",
        rules: {
            "<=": [{ "var": "scenario.otherFinancing" }, 500000]
        }
    },
    //Maximum cash-out (other financing) amount
    {
        name: "Maximum Cash-Out (other financing) amount = $ 500,000",
        rules: {
            "<=": [{ "var": "scenario.otherFinancing" }, 500000]
        }
    },
    //******Mortgage History***********
    {
        name: "Max Mortgage Lates = 0x30x24",
        rules: {
            "and": [
                { "===": [{ "var": "scenario.latePaymentsX30" }, 0], },
                { "===": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "===": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "===": [{ "var": "scenario.latePaymentsX120" }, 0] },
            ]


        }
    },
    //******Seasoning Events***********
    //Min months fc_ss_dil_mco = 84
    {
        name: "Min months from fc, ss, dil, mco = 84",
        rules: {
            "or":
                [
                    { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                    { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 84] }
                ]
        }
    },
    //Min Mos Since Bankruptcy Discharge - Ch7 && Ch11 = 84
    {
        name: "Min Mos Since Bankruptcy Discharge - Ch7 and Ch11 = 84",
        rules: {
            "if": [
                {
                    "in":
                        [
                            { "var": "scenario.bankruptcyType" },
                            [BankruptcyType.chapter_7, BankruptcyType.chapter_11]
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 84]
                            },
                        ]
                },
                true
            ]

        }
    },
    //Min Mos Since Bankruptcy Dismisal - Ch7 && Ch11 = 84 
    {
        name: "Min Mos Since Bankruptcy Dismisal- Ch7 and Ch11 = 84",
        rules: {
            "if": [
                {
                    "in":
                        [
                            { "var": "scenario.bankruptcyType" },
                            [BankruptcyType.chapter_7, BankruptcyType.chapter_11]
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 84]
                            },
                        ]
                },
                true
            ]

        }
    },
    //Min Mos Since Bankruptcy Filling Ch13 = 84 
    {
        name: "Min Mos Since Bankruptcy Filing - Ch13 = 84",
        rules: {
            "if": [
                {
                    "in":
                        [
                            { "var": "scenario.bankruptcyType" },
                            [BankruptcyType.chapter_13]
                        ],
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 84] },
                        ]
                },
                true
            ]
        },
    },
    //Max Debt To Income Ratio = 50% 
    {
        name: "Max Debt To Income Ratio = 50% ",
        rules: {
            "<=": [{ "var": "scenario.debtToIncomeRatio" }, 50]
        }
    },
    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Primary Residence, Second Home, Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.primary_residence, Occupancy.second_home, Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = Single Family, PUD, 2 Family, 3 Family, 4 Family, Detached Condo, Low-Rise Condo, Mid-Rise Condo, High-Rise Condo, Townhouse",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.single_family, PropertyType.pud, PropertyType._2_unit, PropertyType._3_unit, PropertyType._4_unit, PropertyType.detached_condo, PropertyType.low_rise_condo, PropertyType.mid_rise_condo, PropertyType.high_rise_condo, PropertyType.townhouse]]
        }
    },
    // Min FICO for Bank Stmts = 700
    {
        name: "Min FICO for Bank Stmts = 700",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.documentationType"
                        },
                        [
                            DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts
                        ]
                    ]
                },
                {
                    ">=":
                        [
                            {
                                "var": "scenario.representativeCreditScore"
                            },
                            700
                        ]
                },
                true
            ]

        }
    },
    // Max CLTV for Bank Stmts = 80%
    {
        name: "Max CLTV for Bank Stmts = 80%",
        rules: {
            "if": [
                {
                    "in": [
                        {
                            "var": "scenario.documentationType"
                        },
                        [
                            DocumentationType._12M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts
                        ]
                    ]
                },
                {
                    "<=":
                        [
                            {
                                "var": "scenario.CLTV"
                            },
                            0.80
                        ]
                },
                true
            ]

        }
    },

    //******* MAX CLTV FOR FICO AND LOAN AMOUNT AND LOAN PURPOSE UPPER TABLE (PRIMARY RESIDENCE)*/

    //PRIMARY RESIDENCE, RATE TERM and CASH-OUT && FULL DOC  && LOAN AMOUNT <= 500,000

    // Max CLTV for otherFinancing <= 500,000 && FICO >= 740 && (Loan Purpose = Rate and Term || Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 85%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out,
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.85
                    ]
                },
                true
            ],

        },
    },

    // Max CLTV for otherFinancing <= 500,000 && FICO [720,740) && (Loan Purpose = Rate and Term || Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out,
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    // Max CLTV for otherFinancing <= 500,000 && FICO [700,720) && (Loan Purpose = Rate and Term) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 80%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.80
                    ]
                },
                true
            ],

        },
    },
    // Max CLTV for otherFinancing <= 500,000 && FICO [700,720) && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    // Max CLTV for otherFinancing <= 500,000 && FICO [680,700) && (Loan Purpose = Rate and Term) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.75
                    ]
                },
                true
            ],

        },
    },
    // Max CLTV for otherFinancing <= 500,000 && FICO [680,700) && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                            {
                                "===":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        Occupancy.primary_residence
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },

    //SECONDARY RESIDENCE and INVESTMENT, RATE TERM and CASH-OUT && LOAN AMOUNT <= 500,000

    // Max CLTV for otherFinancing <= 500,000 && FICO >= 740 && (Loan Purpose = Rate and Term || Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },

                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out,
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },

    // Max LTV for otherFinancing <= 500,000 && FICO [720,740) && (Loan Purpose = Rate and Term || Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        740
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out,
                                            LoanPurpose.refinance_cash_out,
                                        ]
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [
                                            Occupancy.second_home, Occupancy.investment_property
                                        ]
                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    // Max LTV for otherFinancing <= 500,000 && FICO [700,720) && (Loan Purpose = Rate and Term) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.70
                    ]
                },
                true
            ],

        },
    },
    // Max CLTV for otherFinancing <= 500,000 && FICO [700,720) && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },
    // Max LTV for otherFinancing <= 500,000 && FICO [680,700) && (Loan Purpose = Rate and Term) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.65
                    ]
                },
                true
            ],

        },
    },
    // Max LTV for otherFinancing <= 500,000 && FICO [680,700) && (Loan Purpose = Cash-Out Refi) && Occupancy = Primary Residence
    {
        name: "Max CLTV x Scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.otherFinancing"
                                        },
                                        500000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        680
                                    ]
                            },
                            {
                                "<":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        700
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.occupancy"
                                        },
                                        [Occupancy.second_home, Occupancy.investment_property]

                                    ]
                            },
                        ]
                },
                {
                    "<=": [
                        {
                            "var": "scenario.CLTV"
                        },
                        0.60
                    ]
                },
                true
            ],

        },
    },
    //************** LIMITS TABLE  ********//
    // Min FICO = 680 
    {
        name: "Min FICO = 680",
        rules: {
            ">=": [{ "var": "scenario.representativeCreditScore" }, 680]
        }
    },

]

