import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, BasePriceTable, Adjustment, Guideline } from '../../../classes/product';
import { Amortization, DocumentationType } from "../../../classes/scenario";
import guidelines from "./guidelinesSummitStandAlone2ndLien";
import parseAdjustments from "./adjustmentsSummitStandAlone2ndLien";
import { RulesLogic } from "json-logic-js";

type RowData = {
    coupon: string;
    rules: RulesLogic<{}>;
} & {
    [key in Amortization]?: string;
}

const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const basePricingTableStdDocs = (XLSX.utils.sheet_to_json(sheet, {
        range: "A8:F54",
        header: ["coupon", '10 YR FIX', '15 YR FIX', '20 YR FIX', '25 YR FIX', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[])

    const basePricingTableAltDocs = (XLSX.utils.sheet_to_json(sheet, {
        range: "H8:M54",
        header: ["coupon", '10 YR FIX', '15 YR FIX', '20 YR FIX', '25 YR FIX', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[])



    const basePriceTableReducedStd = basePricingTableStdDocs.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {

        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) return acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules:
                {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_full_doc, DocumentationType.full_doc]]
                },
            })

        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});

    const basePriceTableReducedAlt = basePricingTableAltDocs.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values(Amortization).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) return acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules:
                {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType._12M_business_bank_stmts, DocumentationType._24M_business_bank_stmts, DocumentationType._12M_personal_bank_stmts, DocumentationType._24M_personal_bank_stmts, DocumentationType.wvoe, DocumentationType._12M_1099, DocumentationType._24M_1099,]]
                },
            })

        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});

    const combinedPriceTable = { ...basePriceTableReducedStd };

    Object.keys(basePriceTableReducedAlt).forEach(amortization => {
        if (combinedPriceTable[amortization as Amortization]) {
            combinedPriceTable[amortization as Amortization] = combinedPriceTable[amortization as Amortization].concat(basePriceTableReducedAlt[amortization as Amortization]);
        } else {
            combinedPriceTable[amortization as Amortization] = basePriceTableReducedAlt[amortization as Amortization];
        }
    });
    return combinedPriceTable;
}

function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
    }
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "J3:J4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return `${effectiveDate[0]["effectiveDate"]} ${effectiveDate[1]["effectiveDate"]}`;
}

export default function parseSummitStandAlone2ndLien(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const program = "Summit Stand Alone 2nd Lien"

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            [],
            effectiveDate,
        );
    });
}


///SUMMIT STAND ALONE SECOND LIEN (CES)