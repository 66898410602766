import * as XLSX from "xlsx";
import Product, { BaseAdjustedProduct, Adjustment, Guideline, BasePriceTable } from '../../../classes/product';
import { Amortization, DocumentationType } from "../../../classes/scenario";
import guidelines from "./guidelinesSummitHeloc";
import parseAdjustments from "./adjustmentsSummitHeloc";

type RowData = {
    coupon: string;
} & {
    [key in Amortization]?: string;
}

const parseBasePricing = (sheet: XLSX.WorkSheet): { [key in Amortization]: BasePriceTable } => {
    const basePricingTableStdDocs = XLSX.utils.sheet_to_json(sheet, {
        range: "A8:E54",
        header: ["coupon", '15 YR FIX', '20 YR FIX', '25 YR FIX', Amortization._30_yr_fixed],
        defval: "",
    }) as RowData[];

    const basePriceTableStdWithRules = basePricingTableStdDocs.reduce<{ [key in Amortization]: BasePriceTable }>((acc, row) => {
        Object.values([Amortization._30_yr_fixed]).forEach(amortization => {
            if (row[amortization] === undefined) return
            if (acc[amortization] === undefined) acc[amortization] = [];
            acc[amortization].push({
                coupon: Number(row["coupon"]),
                price: Number(row[amortization]),
                rules: {
                    "in": [{ "var": "scenario.documentationType" }, [DocumentationType.full_doc]]
                }
            })
        })
        return acc
    }, <{ [key in Amortization]: BasePriceTable }>{});
    return {
        ...basePriceTableStdWithRules,
    }
}


function getProductData(sheet: XLSX.WorkSheet): { [key: string]: { guidelines: Guideline[], adjustments: Adjustment[] } } {
    return {
        [Amortization._30_yr_fixed]: {
            guidelines: [
                {
                    name: `Amortization = ${Amortization._30_yr_fixed}`,
                    rules: {
                        "in": [Amortization._30_yr_fixed, { "var": "scenario.amortizations" }]
                    }
                }
            ],
            adjustments: []
        },
    }
}

const parsePrimeRate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "J5:J5";
    const primeRate = XLSX.utils.sheet_to_json<{ primeRate: string }>(coverSheet, {
        range,
        header: ["primeRate"],
        rawNumbers: false,
    });

    return `${primeRate[0]["primeRate"]}`;
}

const parseEffectiveDate = (coverSheet: XLSX.WorkSheet): string => {
    const range = "J3:J4";
    const effectiveDate = XLSX.utils.sheet_to_json<{ effectiveDate: string }>(coverSheet, {
        range,
        header: ["effectiveDate"],
        rawNumbers: false,
    });

    return `${effectiveDate[0]["effectiveDate"]} ${effectiveDate[1]["effectiveDate"]}`;
}

export default function parseSummitHeloc(sheet: XLSX.WorkSheet): Product[] {
    const effectiveDate = parseEffectiveDate(sheet);
    const basePricing = parseBasePricing(sheet);
    const adjustments = parseAdjustments(sheet);
    const primeRate = parsePrimeRate(sheet);
    const program = "Summit Heloc";

    const productData = getProductData(sheet);

    return Object.entries(productData).map(([amortization, data]) => {
        return new BaseAdjustedProduct(
            `${program} ${amortization}`,
            program,
            amortization as Amortization,
            basePricing[amortization as Amortization],
            [...guidelines, ...data.guidelines],
            [...adjustments, ...data.adjustments],
            [],
            effectiveDate,
            primeRate,
        );
    });
}


///SUMMIT STAND ALONE SECOND LIEN (CES)