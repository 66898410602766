import { Amortization, BankruptcyType, Citizenship, DocumentationType, LatePaymentDays, LienPosition, LoanPurpose, Occupancy, PropertyType, State } from "../../../classes/scenario";

export default [
    //************** GENERAL ********//
    //States allowed 
    {
        name: "Property States Allowed = AZ, CA, CO, FL, GA, IL, MD, NV, NJ, PA, TN, TX, VA",
        rules: {
            "in": [{ "var": "scenario.state" }, [State.arizona, State.california, State.colorado, State.florida, State.georgia, State.illinois, State.maryland, State.nevada, State.new_jersey, State.pennsylvania, State.tennessee, State.texas, State.virginia]]
        }
    },
    // Lien Position = First 
    {
        name: "Lien Position Allowed = First",
        rules: {
            "===": [{ "var": "scenario.lienPosition" }, LienPosition.first]
        }
    },

    //************ REDUCE 5% LTV FOR INTEREST ONLY */
    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT && DSCR UPPER TABLE*/

    //LOAN PURPOSE = (PURCHASE || RATE TERM)  && LOAN AMOUNT <= 2,000,000 and INTEREST ONLY 

    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && FICO >= 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },


                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },


                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },


                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //LOAN PURPOSE = (CASH-OUT)  && LOAN AMOUNT <= 2,000,000 && INTEREST ONLY
    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && FICO >= 720 && (Loan Purpose = Cash-Out Refi)
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //IO && Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 55%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "in": [{ "var": "product.amortization" }, [Amortization._30_yr_fixed_IO, Amortization._30_yr_fixed_arm_5_6_IO, Amortization._40_yr_fixed_IO, Amortization._40_arm_5_6_IO]]
                            },
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },
                            ,
                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.55
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.55
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //******** INVESTOR EXPERIENCE */
    // First Time Home Buyer Allowed = No
    {
        name: "First Time Home Buyer Allowed = No",
        rules: {
            "===": [{ "var": "scenario.firstTimeHomeBuyer" }, false]
        }
    },
    //************** PROGRAM REQUIREMENTS ********//
    // ****LIMITS****

    //Minimum loan amount 
    {
        name: "Minimum Loan Amount = $350,000",
        rules: {
            ">=": [{ "var": "scenario.baseLoanAmount" }, 350000]
        }
    },
    //Maximum loan amount 
    {
        name: "Maximum Loan Amount = $2,000,000",
        rules: {
            "<=": [{ "var": "scenario.baseLoanAmount" }, 2000000]
        }
    },
    //Maximum cash-out 
    {
        name: "Maximum Cash-Out = $500,000",
        rules: {
            "<=": [{ "var": "scenario.cashOutAmount" }, 500000]
        }
    },
    // //Mortgage History
    {
        name: "Max Mortgage Lates within last 12 mos = 0x30,0x60,0x90,0x120",
        rules: {
            "and": [
                { "<=": [{ "var": "scenario.latePaymentsX30" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX60" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX90" }, 0] },
                { "<=": [{ "var": "scenario.latePaymentsX120" }, 0] }
            ]
        }
    },
    //Minimum Months Since BK/FC/SS/DIL  = 84 
    {
        name: "Min Mos Since BK/FC/SS/DIL = 84",
        rules: {
            "and": [
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 0] },
                            { ">=": [{ "var": "scenario.fc_ss_dil_mco__months_ago" }, 84] },
                        ],


                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDischarge" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDischarge" }, 84]
                            },
                        ],
                },
                {
                    "or":
                        [
                            {
                                "===": [{ "var": "scenario.termBankruptcyDismissal" }, 0]
                            },
                            {
                                ">=": [{ "var": "scenario.termBankruptcyDismissal" }, 84]
                            },
                        ]
                },
                {
                    "or":
                        [
                            { "===": [{ "var": "scenario.termBankruptcyFiling" }, 0] },
                            { ">=": [{ "var": "scenario.termBankruptcyFiling" }, 84] },
                        ]
                },
            ]
        }
    },
    //************** INCOME ********//
    // Documentation Type
    {
        name: "Doc Types Allowed = DSCR",
        rules: {
            "in": [{ "var": "scenario.documentationType" }, [DocumentationType.dscr]]
        }
    },

    //************** OTHER BOTTOM ********//
    // Occupancy
    {
        name: "Occupancy Allowed = Investment Property",
        rules: {
            "in": [{ "var": "scenario.occupancy" }, [Occupancy.investment_property]]
        }
    },
    //Property Types Allowed
    {
        name: "Property Types Allowed = 5-9 Unit Residential Properties",
        rules: {
            "in": [{ "var": "scenario.propertyType" }, [PropertyType.residential_units]]
        }
    },
    //Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien
    {
        name: "Citizenships Allowed = US Citizen, Permanent Resident Alien, Non-Permanent Resident Alien",
        rules: {
            "in": [
                { "var": "scenario.citizenship" },
                [
                    Citizenship.us_citizen, Citizenship.permanent_resident_alien, Citizenship.non_permanent_resident_alien
                ],
            ]
        }
    },
    //Min DSCR = 1.25
    {
        name: "Min DSCR = 1.25",
        rules:
        {
            ">=": [{ "var": "scenario.debtServCoverageRatio" }, 1.25]
        }
    },
    //Rural not eligible 
    {
        name: "Rural Not Eligible",
        rules: {
            "===": [{ "var": "scenario.rural" }, false]
        }
    },
    //******* MAX LTV/CLTV FOR FICO AND LOAN AMOUNT && DSCR UPPER TABLE*/

    //LOAN PURPOSE = (PURCHASE || RATE TERM)  && LOAN AMOUNT <= 2,000,000 

    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && FICO >= 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi)
    {
        name: "Max LTV/CLTV x scenario = 75%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.75
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.75
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Purchase || Rate and Term that is No Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.purchase,
                                            LoanPurpose.refinance_no_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //LOAN PURPOSE = (CASH-OUT)  && LOAN AMOUNT <= 2,000,000 
    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && FICO >= 720 && (Loan Purpose = Cash-Out Refi)
    {
        name: "Max LTV/CLTV x scenario = 70%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                ">=":
                                    [
                                        {
                                            "var": "scenario.representativeCreditScore"
                                        },
                                        720
                                    ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.70
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.70
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 700 <= FICO < 720 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 65%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                720
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.65
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.65
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },
    //Max LTV/CLTV for baseLoanAmount <= 2,000,000 && 680 <= FICO < 700 && (Loan Purpose = Cash-Out Refi) 
    {
        name: "Max LTV/CLTV x scenario = 60%",
        rules: {
            "if": [
                {
                    "and":
                        [
                            {
                                "<=":
                                    [
                                        {
                                            "var": "scenario.baseLoanAmount"
                                        },
                                        2000000
                                    ]
                            },
                            {
                                "and": [
                                    {
                                        ">=":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                680
                                            ],

                                    },
                                    {
                                        "<":
                                            [
                                                {
                                                    "var": "scenario.representativeCreditScore"
                                                },
                                                700
                                            ],
                                    }
                                ]
                            },
                            {
                                "in":
                                    [
                                        {
                                            "var": "scenario.loanPurpose"
                                        },
                                        [
                                            LoanPurpose.refinance_cash_out
                                        ],
                                    ]
                            },

                        ]
                },
                {
                    "and": [
                        {
                            "<=": [
                                {
                                    "var": "scenario.LTV"
                                },
                                0.60
                            ]
                        },
                        {
                            "<=": [
                                {
                                    "var": "scenario.CLTV"
                                },
                                0.60
                            ]
                        }
                    ]
                },
                true
            ],

        },
    },

    //**************LIMITS TABLE UPPERTABLE */
    // Min Credit Score 
    {
        name: "Min Credit Score = 680",
        rules:
        {
            ">=":
                [
                    {
                        "var": "scenario.representativeCreditScore"
                    },
                    680
                ]
        },
    },
]

