import { WorkBook, WorkSheet } from "xlsx";
import Product from "../../classes/product";

import parseDeephavenMulti from "./DSCR_5_9_Units/parseDSCR_5_9_Units";



export default function parseDeephavenMultiUnit(workbook: WorkBook): Product[] {

    const DSCRMultiSheet = workbook.Sheets["DSCR Multi"];

    return [
        ...parseDeephavenMulti(DSCRMultiSheet),
    ]
}