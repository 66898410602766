import React from 'react';

import { minPaymentAtom, maxPaymentAtom } from '../../services/scenarioService';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react'
import { productsAtom } from '../../services/productService'
import { scenarioAtom } from '../../services/scenarioService'
import {
    Box,
    Stack,
    Input,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
} from "@chakra-ui/react"

const PaymentSlider = () => {
    const [minPayment, setMinPayment] = useAtom(minPaymentAtom)
    const [maxPayment, setMaxPayment] = useAtom(maxPaymentAtom)

    const products = useAtomValue(productsAtom);
    const scenario = useAtomValue(scenarioAtom);

    const min = useMemo(() => {
        return Math.min(...products.filter((product) => product.qualify(scenario)).map((product) => product.getMonthlyPayment(scenario)))
    }, [products, scenario])

    const max = useMemo(() => {
        return Math.max(...products.filter((product) => product.qualify(scenario)).map((product) => product.getMonthlyPayment(scenario)))
    }, [products, scenario])

    const handleSetMinPayment = (value: number) => {
        if (value === min) setMinPayment(undefined)
        else setMinPayment(value)
    }

    const handleSetMaxPayment = (value: number) => {
        if (value === max) setMaxPayment(undefined)
        else setMaxPayment(value)
    }

    const handleSliderChange = ([min, max]: number[]) => {
        handleSetMinPayment(min)
        handleSetMaxPayment(max)
    }

    return (<Box>
        <RangeSlider aria-label={['min', 'max']} min={min} max={max} value={[minPayment || min, maxPayment || max]} onChange={handleSliderChange}>
            <RangeSliderTrack>
                <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
        </RangeSlider>
        <Stack direction='row'>
            <Input type='number' value={minPayment || min} onChange={(event) => handleSetMinPayment(Number(event.target.value))} />
            <Input type='number' value={maxPayment || max} onChange={(event) => handleSetMaxPayment(Number(event.target.value))} />
        </Stack>
    </Box>

    )

}

export default PaymentSlider