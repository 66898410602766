import React from 'react';

import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VisuallyHidden,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  Center,
} from '@chakra-ui/react';
import parseSummit from '../../parsers/verus/parseVerus';
import parseDeephaven from '../../parsers/deephaven/parseDeephaven';
import { productServiceAtom } from '../../services/productService';
import Product from '../../classes/product';
import { useAtomValue } from 'jotai';
import parseDeephavenSecondLien from '../../parsers/deephaven2ndLien/parseDeephaven2ndLien';
import parseNQMF from '../../parsers/nqmf/parseNQMF';
import parseDeephavenMultiUnit from '../../parsers/deephavenMulti/parseDeephavenMulti';


const Admin = () => {
  const [authed, setAuthed] = useState(false);
  const productService = useAtomValue(productServiceAtom);
  const [investor, setInvestor] = useState<string>();
  const toast = useToast();

  useEffect(() => {
    productService.authedCredential
      .authenticate([])
      .then(() => setAuthed(true))
      .catch((e) =>
        toast({
          title: 'Error Uploading Products',
          description: e.message,
          status: 'error',
        })
      );
  }, []);

  const handleFileUpload = (e: any) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = async (event) => {
      const data = event?.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      let products: Product[] = [];
      if (investor === 'Deephaven') {
        products = parseDeephaven(workbook);
      } else if (investor === 'Deephaven Second Lien') {
        products = parseDeephavenSecondLien(workbook);
      } else if (investor === 'Deephaven Multi Unit') {
        products = parseDeephavenMultiUnit(workbook);
      } else if (investor === 'Verus') {
        products = parseSummit(workbook);
      } else if (investor === 'NQMF') {
        products = parseNQMF(workbook);
      } else {
        return;
      }

      try {
        await productService.saveProducts(products);
        toast({ title: 'Products Uploaded Successfully', status: 'success' });
      } catch (e) {
        toast({
          title: 'Error Uploading Products',
          description: (e as Error).message,
          status: 'error',
        });
      }
    };
  };

  if (!authed)
    return (
      <div>
        <Center h="100%">
          <CircularProgress>
            <CircularProgressLabel>Loading</CircularProgressLabel>
          </CircularProgress>
        </Center>
      </div>
    );

  return (
    <Box p="1rem">
      <FormControl marginBottom="20px">
        <FormLabel>Investor</FormLabel>
        <Select
          placeholder="Select an option"
          variant="filled"
          value={investor}
          onChange={(e) => setInvestor(e.target.value)}
        >
          <option value="Deephaven">Deephaven</option>
          <option value="Deephaven Second Lien">Deephaven Second Lien</option>
          <option value="Deephaven Multi Unit">Deephaven 5-9 Unit</option>
          <option value="Verus">Verus</option>
          <option value="NQMF">NQMF</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Choose File</FormLabel>
        <Box
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="gray.300"
          p="6"
          rounded="md"
          _hover={{ borderColor: 'gray.400' }}
          display="flex"
          alignItems="center"
        >
          <VisuallyHidden>
            <Input
              id="file-upload"
              type="file"
              accept=".xlsx, .xls"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileUpload({ target: { files: Array.from(e.target.files || []) } })}
            />
          </VisuallyHidden>
          <Button
            colorScheme="teal"
            size="sm"
            as="label"
            htmlFor="file-upload"
            mr="2"
          >
            Browse
          </Button>
          <span>{investor}</span>
        </Box>
      </FormControl>
    </Box>
  );
};

export default Admin;
